export const requestUrl = "https://admin.reicth.com";
export const srpPath = "https://admin.reicth.com";
export const psurveyPath = "https://admin.reicth.com";

// export const requestUrl = "https://admin.powermap.live";
// export const srpPath = "https://admin.powermap.live";
// export const psurveyPath = "https://admin.powermap.live";

export const URL_SERVER = srpPath;

export const imageURL = psurveyPath;

export const recaptchaKey = "6LfiqSIaAAAAALsQMD4TjeB2avy9-BmSwZGf6DhX";

const session = localStorage.getItem("session storage");
export const checkSession = session === null ? false : true;

// map api
// export const heremap_API_key = "4jd6f0fj_g_ZcmzhZAH3sVpHTO5AJBYQAInKFQjRgss";
export const heremap_API_key = "JV7MDgWAOuzAXCWKYSVYFrpT-AnaTWMbwVPb9788HIA";
export const heremap_appID = "W336uytfRUG9kKud1fnj";
export const heremap_appCode = "eYy0bLdL7xizsFDZkBfJGQ";

// get prov
export const API_sendevent = requestUrl + "/api/sendevent";

export const API_login = requestUrl + "/api/login";
export const API_logout = requestUrl + "/api/logout";

export const API_admin_acclog = requestUrl + "/api/acclog";
export const API_srp_acclog = requestUrl + "/api/srp_acclog";
export const API_psurvey_acclog = requestUrl + "/api/psurvey_acclog";

export const API_log = requestUrl + "/api/all_log";
// export const API_srp_log = requestUrl + "/api/srp_log";
// export const API_psurvey_log = requestUrl + "/api/psurvey_log";

export const API_list_contactus = requestUrl + "/api/list_contactus";
export const API_faqs = requestUrl + "/api/faqs";
export const API_updatefaq = requestUrl + "/api/updatefaq";
export const API_delfaq = requestUrl + "/api/delfaq";
export const API_addfaq = requestUrl + "/api/addfaq";

export const API_qcpass = requestUrl + "/api/qcpass";
export const API_qcreject = requestUrl + "/api/qcreject";

export const API_deluser = requestUrl + "/api/deluser";
export const API_list_member = requestUrl + "/api/list_member";

export const API_list_regorg = requestUrl + "/api/list_regorg";

export const API_regtime_acclog = requestUrl + "/api/regtime_acclog";

export const API_downloadzip = requestUrl + "/api/downloadzip";

export const API_exportpsurvey = requestUrl + "/api/exportpsurvey";

export const API_list_zone = requestUrl + "/api/list_zone";

export const API_adminsrpproject = requestUrl + "/api/adminsrpproject";

export const API_approvedpsurvey = requestUrl + "/api/approvedpsurvey";

export const API_approvedsrp = requestUrl + "/api/approvedsrp";

export const API_importhouseeditcsv = requestUrl + "/api/importhouseeditcsv";

export const API_deletehousetemp = requestUrl + "/api/deletehousetemp";

export const API_importhousetemp = requestUrl + "/api/importhousetemp";

export const API_deletevillatemp = requestUrl + "/api/deletevillatemp";

export const API_importvillatemp = requestUrl + "/api/importvillatemp";

export const API_deletecondotemp = requestUrl + "/api/deletecondotemp";

export const API_importcondotemp = requestUrl + "/api/importcondotemp";

export const API_setregtime = requestUrl + "/api/setregtime";

export const API_bbox_cwt = requestUrl + "/api/bbox_cwt";

export const API_bbox_address = requestUrl + "/api/bbox_cwt";

export const API_updatehousetemp = requestUrl + "/api/updatehousetemp";

export const API_updatevillatemp = requestUrl + "/api/updatevillatemp";

export const API_updatecondotemp = requestUrl + "/api/updatecondotemp";

export const API_showcondotemp = requestUrl + "/api/showcondotemp";

export const API_showhousetemp = requestUrl + "/api/showhousetemp";

export const API_showvillatemp = psurveyPath + "/api/showvillatemp";

export const API_adminpsurveyproject = psurveyPath + "/api/adminpsurveyproject";

export const API_psurveyexp_reject = psurveyPath + "/api/psurveyexp_reject";

export const API_listsrpbyorg = requestUrl + "/api/listsrpbyorg";

export const API_importhousecsv = requestUrl + "/api/importhousecsv";

export const API_importcondocsv = requestUrl + "/api/importcondocsv";

export const API_importvillacsv = requestUrl + "/api/importvillacsv";

export const API_importreicsrpcsv = requestUrl + "/api/importreicsrpcsv";

export const API_register_srp = requestUrl + "/api/register_srp";

export const API_register_psurvey = requestUrl + "/api/register_psurvey";

export const API_register_admin = requestUrl + "/api/register_admin";

export const API_register = requestUrl + "/api/register";

export const API_psurveyexp_file = requestUrl + "/api/psurveyexp_file";
export const API_psurveyexp_image = requestUrl + "/api/psurveyexp_image";

export const API_adminchangepasswd = requestUrl + "/api/adminchangepasswd";
export const API_update_readonly = requestUrl + "/api/update_readonly";
export const API_update_active = requestUrl + "/api/update_active";

export const API_update_admin = requestUrl + "/api/update_admin";
export const API_approveuser = requestUrl + "/api/approveuser";

export const API_get_prov = requestUrl + "/api/getprov";
export const API_get_amphoe = requestUrl + "/api/getamp";
export const API_get_tambon = requestUrl + "/api/gettam";

export const API_getRegTime = requestUrl + "/api/showregtime";

export const API_addOrg = requestUrl + "/api/addorg";
export const API_get_project_id = requestUrl + "/api/gensrpid";
export const API_address_code = requestUrl + "/api/point_tambon";

export const API_get_cate_house = requestUrl + "/api/cat_house";
export const API_get_cate_villa = requestUrl + "/api/cat_villa";

export const API_submit_file = psurveyPath + "/api/addfile";
export const API_add_image = psurveyPath + "/api/addimg";
export const API_addex_image = psurveyPath + "/api/addexhouseimg";
export const API_gen_secretkey = requestUrl + "/api/genskey";

export const API_countsrpaccount = requestUrl + "/api/sum_usersrp";
export const API_countpsurveyaccount = requestUrl + "/api/sum_userps";
export const API_countsrp = requestUrl + "/api/countsrp";
export const API_draftsrp = requestUrl + "/api/draftsrp";
export const API_countpsurvey = requestUrl + "/api/countpsurvey";
export const API_draftpsurvey = requestUrl + "/api/draftpsurvey";

export const API_contact_us = requestUrl + "/api/srpcontact";
// export const API_faq = requestUrl + "/api/faq";
// export const API_profile = requestUrl + "/api/profile";
export const API_updateprofile = requestUrl + "/api/updateprofile";
export const API_easypassword = requestUrl + "/api/easytoguesspassword";
export const API_getold_resetpasswd = requestUrl + "/api/oldresetpassword";

export const API_get_org = requestUrl + "/api/searchorg";
export const API_get_reg_org = requestUrl + "/api/searchregorg";
export const API_get_guild = requestUrl + "/api/listguild";

// Psurvey API
export const API_search_oldproject = psurveyPath + "/api/searcholdprojid";
export const API_showdetail_projectid_household =
  requestUrl + "/api/phousedetail";
export const API_showdetail_projectid_villa = requestUrl + "/api/pvilladetail";
export const API_showdetail_projectid_condo = requestUrl + "/api/pcondodetail";
export const API_listall_psurvey = requestUrl + "/api/listpsurvey";
// export const API_save_household = requestUrl + "/api/savephousehold";
export const API_save_household_fromP = psurveyPath + "/api/savephousehold";
// export const API_submit_household = requestUrl + "/api/addphousehold";

// export const API_save_household = requestUrl + "/api/savephousehold";
// export const API_save_condo = requestUrl + "/api/savepcondo";
// export const API_save_villa = requestUrl + "/api/savepvilla";

export const API_save_household = requestUrl + "/api/addphousehold";
export const API_save_condo = requestUrl + "/api/addpcondo";
export const API_save_villa = requestUrl + "/api/addpvilla";

export const API_submit_household = requestUrl + "/api/addphousehold";
export const API_submit_condo = requestUrl + "/api/addpcondo";
export const API_submit_villa = requestUrl + "/api/addpvilla";

export const API_genprojid = requestUrl + "/api/genprojid";
export const API_getprice = requestUrl + "/api/getpsurveyprice";
export const API_getpsurveyprice = requestUrl + "/api/getpsurveyprice";
export const API_delpsurvey = requestUrl + "/api/delpsurvey";
export const API_exptoken = requestUrl + "/api/exptoken";

export const API_report = requestUrl + "reportsrp";
export const API_absortrate = requestUrl + "report_catabsorp";
export const API_reportMarket = requestUrl + "list_cwtcomp";
export const API_reportAllComp = requestUrl + "report_allcomp";

export const API_image = requestUrl + "/api/psurveyimage";
export const API_file = requestUrl + "/api/psurveyfile";

export const API_exp_CSVPSURVEY = requestUrl + "/api/psurveyexp_csv";
export const API_exp_PDFPSURVEY = requestUrl + "/api/psurveyexp_pdf";
export const API_checkrefcode = psurveyPath + "/api/chkrefcode";

export const API_compressedpdf = psurveyPath + "/api/compressedpdf";

export const API_list_org = psurveyPath + "/api/list_org";

export const API_request = srpPath + "/api/";

// export const API_report = API_request + "reportsrp";
// export const API_absortrate = API_request + "report_catabsorp";
// export const API_reportMarket = API_request + "list_cwtcomp";
// export const API_reportAllComp = API_request + "report_allcomp";
export const API_getorg = API_request + "getorg";

export const API_noti = API_request + "listsrpnoti";
// export const API_gen_secretkey = API_request + "genskey";
// export const API_login = API_request + "login";
// export const API_contact_us = API_request + "srpcontact";
// export const API_FAQ = API_request + "faqs";
// export const API_logout = API_request + "logout";
// export const API_register = API_request + "register";
export const API_newpassword = API_request + "reset/";
// export const API_easypassword = API_request + "easytoguesspassword";
// export const API_get_guild = API_request + "listguild";
export const API_oldpassword = API_request + "oldpassword";
// export const API_getold_resetpasswd = API_request + "oldresetpassword";
export const API_change_passwd = API_request + "changepassword";

export const API_sendresetpassword = API_request + "sendresetpassword";
// assets categories
// export const API_category_house = API_request + "cat_house";
// export const API_category_condo = API_request + "cat_condo";
// export const API_category_villa = API_request + "cat_villa";

// get province
// export const API_get_prov = API_request + "getprov";
// export const API_get_amphoe = API_request + "getamp";
// export const API_get_tambon = API_request + "gettam";

// get asset type
// export const API_get_cate_house = API_request + "cat_house";
export const API_get_cate_condo = API_request + "cat_condo";
// export const API_address_code = API_request + "point_tambon";
// export const API_bbox_address = API_request + "bbox_cwt";

// Project
// export const API_get_project_id = API_request + "gensrpid";
export const API_view_all_project = API_request + "listsrp";
export const API_get_project_status = API_request + "listpstatus";
export const API_addSRP = API_request + "addsrp";
export const API_saveSRP = API_request + "savesrp";
export const API_editSRP = API_request + "editsrp";
export const API_get_project_detail = API_request + "srpdetail";
export const API_deleteProject = API_request + "delsrp";
// export const API_add_image = API_request + "addimg";
export const API_add_file = API_request + "addfile";
export const API_get_price = API_request + "getprice";

// ORG
// export const API_addOrg = API_request + "addorg";
export const API_searchOrg = API_request + "searchorg";
export const API_profile = API_request + "profile";
// export const API_updateprofile = API_request + "updateprofile";
// CSV
export const API_uploadCSV = API_request + "importsrpcsv";

// report
export const API_report_sell = API_request + "report_sell";
export const API_report_sell_graph = API_request + "report_sellg";
export const API_getProv_report = API_request + "list_cwtcomp";

// 1. จำนวนโครงการทั้งหมด
export const API_report_numofproj = API_request + "report_numofproj_admin";

// 2. หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall = API_request + "report_leftsellall";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer =
  API_request + "report_sellaccumtranfer";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj = API_request + "report_unitproj";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew = API_request + "report_sellnew";

// 6. โอนสะสม
export const API_report_transfer_acc = API_request + "report_transfer";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject = API_request + "report_numofprojnew";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc = API_request + "report_sellaccum";

// 9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog = API_request + "report_transferbacklog";

// 10. หน่วยเปิดขายใหม่
export const API_report_open_new_sell = API_request + "report_opennew";

// 11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell = API_request + "report_leftsell";

export const API_report_sell_all = API_request + "report_sell_all";
export const API_report_sell_graph_all = API_request + "report_sellg_all";
// 1. จำนวนโครงการทั้งหมด
export const API_report_numofproj_all = API_request + "report_numofproj_all";

// 2. หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall_all =
  API_request + "report_leftsellall_all";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer_all =
  API_request + "report_sellaccumtranfer_all";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj_all = API_request + "report_unitproj_all";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew_all = API_request + "report_sellnew_all";

// 6. โอนสะสม
export const API_report_transfer_acc_all = API_request + "report_transfer_all";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject_all =
  API_request + "report_numofprojnew_all";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc_all = API_request + "report_sellaccum_all";

// 9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog_all =
  API_request + "report_transferbacklog_all";

// 10. หน่วยเปิดขายใหม่
export const API_report_open_new_sell_all = API_request + "report_opennew_all";

// 11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell_all = API_request + "report_leftsell_all";
export const API_absortrate_all = API_request + "report_catabsorp_all";
export const API_report_sell_cp = API_request + "report_sell_cp";
export const API_report_sell_graph_cp = API_request + "report_sellg_cp";
// 1. จำนวนโครงการทั้งหมด
export const API_report_numofproj_cp = API_request + "report_numofproj_cp";

// 2. หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall_cp = API_request + "report_leftsellall_cp";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer_cp =
  API_request + "report_sellaccumtranfer_cp";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj_cp = API_request + "report_unitproj_cp";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew_cp = API_request + "report_sellnew_cp";

// 6. โอนสะสม
export const API_report_transfer_acc_cp = API_request + "report_transfer_cp";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject_cp = API_request + "report_numofprojnew_cp";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc_cp = API_request + "report_sellaccum_cp";

// 9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog_cp = API_request + "report_transferbacklog_cp";

// 10. หน่วยเปิดขายใหม่
export const API_report_open_new_sell_cp = API_request + "report_opennew_cp";

// 11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell_cp = API_request + "report_leftsell_cp";
export const API_absortrate_cp = API_request + "report_catabsorp_cp";
export const API_exp_CSVSRP = API_request + "exp_csv";
