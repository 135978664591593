import numeral from "numeral";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import GridItem from "components/Grid/GridItem.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import setApproved from "actions/setApproved.js";
import setModalType from "actions/setModalType.js";
import setProjectIDHome from "actions/setProjectIDHome.js";
import setProjectStatus from "actions/setProjectStatus.js";
import loading_datatable from "actions/survey/loading_datatable.js";
import oldproject_data from "actions/survey/oldproject_data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import setLatlon from "actions/survey/setLatlon.js";
import setProjectid from "actions/survey/setProjectId.js";
import surveyType from "actions/survey/setSurveyType.js";
import toggleSurvey from "actions/survey/toggleSurvey.js";
import GHBankLoading from "assets/img/reicLoading.gif";
import PrintIcon from "@material-ui/icons/Print";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import NavPills from "components/NavPills/NavPills.js";
import { API_exp_CSVSRP, API_get_prov } from "configure/configServer.js";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import { Pie } from "react-chartjs-2";
import "chart.piecelabel.js";
import "chartjs-plugin-datalabels";
import FileDrop from "react-file-drop";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import ReactTable from "react-table";
// import selectTableHOC from "react-table/lib/hoc/selectTable";
import { Card, CardBody, Col, Row, Progress } from "reactstrap";
import EditProjectHome from "views/Project/Home/edit/EditProject_home.js";
import WizardCondo from "views/PSurvey/wizard_condo.js";
import WizardHouse from "views/PSurvey/wizard_house.js";
import WizardVilla from "views/PSurvey/wizard_villa.js";
import switchIntl from "../../configure/switchIntl.js";
import "./GHproject.css";

import psurveyPrintTemplate from "./psurveyPrintTemplate.txt";
import { htmlTablePage } from "./psurveyPrintTemplate.js";
import leafletImage from "leaflet-image";
import * as L from "leaflet";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from "axios";
import _ from "lodash";
import HouseImport from "./Table/HouseImport";
import HouseImportView from "./View/HouseImportView";
import HouseErrorView from "./View/HouseErrorView";
import VillaImport from "./Table/VillaImport";
import VillaImportView from "./View/VillaImportView";
import VillaErrorView from "./View/VillaErrorView";
import CondoImport from "./Table/CondoImport";
import CondoImportView from "./View/CondoImportView";
import CondoErrorView from "./View/CondoErrorView";

// const SelectTable = selectTableHOC(ReactTable);

const attributeName = [
  "nameth",
  "projectid",
  "refcode",
  "nameeng",
  "typeowner",
  "forelder",
  "typearea",
  "rai",
  "ngan",
  "sqwa",
  "address",
  "moo",
  "soi",
  "road",
  "subdistrict",
  "district",
  "province",
  "postalcode",
  "datefirstsell",
  "projectstatus",
  "dateconstruct",
  "datefinish",
  "minprice",
  "maxprice",
  "averageprice",
  "constructapprove",
  "envapprove",
  "registered",
  "saleimprove",
  "projectprice",
  "tel_saleoffice",
  "projectwebsite",
  "note",
  "explorers",
  "explorerdate",
  "presaleproject",
  "presalephase",
  "presaleamoutbuilding",
  "userid",
  "c_name",
  "c_address",
  "c_floor",
  "c_namebuilding",
  "c_soi",
  "c_road",
  "c_subdistrict",
  "c_district",
  "c_province",
  "t_value",
  "d_value",
  "p_value",
  "c_tel",
  "c_fax",
  "c_instm",
  "lat",
  "lon",
  "periodtext",
  "Refcode2",
  "Facilitiesvalue",
];

const attributeImageName = [
  "frontprojectplaceimg",
  "saleofficeimg",
  "exhouseimg",
  "currentconstructimg",
  "brochuremapimg",
  "googlemapimg",
  "projectplanimg",
  "filesurvey",
  "filebrochure",
  "filequotation",
];
const eye = <FontAwesomeIcon icon={faEye} />;
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// var url_heremap =
//   "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png?app_id=" +
//   config.heremap_appID +
//   "&app_code=" +
//   config.heremap_appCode;

var url_heremap = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

var sattelite = L.tileLayer(url_heremap, {
  attribution:
    '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
  maxZoom: 27,
  minZoom: 8,
});

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const pieChart = {
  options: {
    elements: {
      center: {
        text: "100%",
      },
    },
    legend: {
      display: true,
      position: "right",
      align: "middle",
      labels: {
        fontStyle: "bold",
        fontSize: 14,
        fontColor: "black",
        usePointStyle: true,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          return " " + data["labels"][tooltipItem["index"]];
        },
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        display: function(ctx) {
          return ctx.dataset.data[ctx.dataIndex] > 10;
        },
        font: {
          weight: "bold",
        },
        offset: 0,
        padding: 0,
      },

      // Core options
      aspectRatio: 3 / 2,
      cutoutPercentage: 8,
      layout: {
        padding: 16,
      },
      elements: {
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    },
  },
};

class GHProject extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.inputHouseRef = React.createRef();
    this.inputVillaRef = React.createRef();
    this.inputCondoRef = React.createRef();
    this.remarkRef = React.createRef();
    this.remarkRef.current = [];
  }
  state = {
    isBlob: true,
    value: 0,
    psurveyPath: config.requestUrl,
    srpPath: config.requestUrl,
    generatingPDF: false,
    generatingPDFAll: 0,
    generatingPDFinished: 0,
    curTab: 0,
    countapprovedsrp: 0,
    countdraftsrp: 0,
    orgList: [],
    regLog: [],
    check_quarter: false,
    check_halfyear: false,
    checked: 24,
    setChecked: 22,
    psurvey_approval: "",
    psurvey_approvalState: "",
    curSrp: { datapart1: [{}], datapart2: [{}] },
    loadingScreen: false,
    selectedDate: new Date(),
    selectedYear: 0,
    selectedQuarter: 0,
    uploadOldType: 1,
    markerLayer: L.layerGroup(),
    baseLayer: L.layerGroup(),
    passwordShown: false,
    currentSurveyYear: new Date().getFullYear,
    currentSurveyHalfYear: new Date().getMonth(),
    currentSurveyQuarterYear: new Date().getMonth(),
    filterCompany: 0,
    filterYear: 0,
    filterPeriodYear: 0,
    filterType: 0,
    filterCompanyOld: 0,
    filterYearOld: "0",
    filterPeriodYearOld: "0",
    filterTypeOld: 0,
    selected_prov_id: "10",
    selected_prov_name: "กรุงเทพมหานคร",
    selection: [],
    selectAll: false,
    showTable: false,
    showHouse: false,
    showVilla: false,
    showCondo: false,
    selectedCurrent: "0-0",
    selectedPrevious: "0-0",
    view_psurvey: false,
    showEditPsurvey: true,
    checkBoxQC: [],
    remark: [],
    selectedQC: 0,
  };

  componentDidMount() {
    var self = this;
    this.getProvince();

    setTimeout(() => {
      if (self.refs.map) {
        self.map = L.map(self.refs.map, {
          layers: [sattelite],
        })
          .setView([13, 100], 8)
          .setZoom(14);

        var baseMaps = {
          ภาพดาวเทียม: sattelite,
        };

        L.control.layers(baseMaps).addTo(self.map);
        self.state.markerLayer.addTo(self.map);
      }
    }, 3000);

    this.getSecretKey();
  }
  componentDidUpdate(prevProp) {
    if (prevProp.modaltype !== this.props.modaltype) {
      this.setState({ projecttype: this.props.modaltype });
    }
    // console.log(this.state.psurvey_approval);
  }
  getOrgList = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_list_regorg}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({}),
    });

    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          if (data.code === 200 && data.data) {
            that.setState({
              orgList: data.data,
            });
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey Completed",that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey  Completed",{success:true});
          } else {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
          }
          // if()
        });
      })
      .catch(function(err) {
        // console.log(err);
        // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
      });
  };
  getRegLog = () => {
    // console.log('list psurvey')
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    // console.log(decryptedData)
    var request = new Request(`${config.API_regtime_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });

    fetch(request)
      .then(function(response) {
        // console.log(response);
        response.json().then(function(data) {
          // console.log(data);
          // message = 'Login Success';
          if (data.code === 200 && data.data) {
            that.setState({ regLog: data.data });
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey Completed",that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey  Completed",{success:true});
          } else {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
          }
        });
      })
      .catch(function(err) {
        // console.log(err);
        // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
      });
  };

  formatApproval(data) {
    switch (data) {
      case 1:
        return <p style={{ color: "#008125", fontWeight: 600 }}>ผ่าน</p>;
      case 2:
        return <p style={{ color: "red", fontWeight: 600 }}>ไม่ผ่าน</p>;
      case 3:
        return <p style={{ color: "#FFBD00", fontWeight: 600 }}>รอตรวจ</p>;
      case 4:
        return "บันทึกชั่วคราว";
      case 5:
        return <p style={{ color: "#FFBD00", fontWeight: 600 }}>โครงการเก่า</p>;
      default:
        return data;
    }
  }
  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
    }
  }

  deletePsurvey = (index, member_passwd) => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    // console.log(this.state.psurveyList[index])

    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var id =
      index in this.state.psurveyList &&
      "projectid" in this.state.psurveyList[index]
        ? this.state.psurveyList[index].projectid
        : null;
    var form =
      index in this.state.psurveyList &&
      "project_type" in this.state.psurveyList[index]
        ? this.state.psurveyList[index].project_type
        : null;
    var refcode =
      index in this.state.psurveyList &&
      "condo_refcode" in this.state.psurveyList[index]
        ? this.state.psurveyList[index].condo_refcode
        : null;
    const body = {
      projectid: id,
      refcode,
      form,
      member_passwd,
      event: "Delete project",
    };
    var request = new Request(config.API_delpsurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });
    return fetch(request)
      .then(function(response) {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response");
        } else {
          return response.json(); // go the desired response
        }
      })
      .then(function(data) {
        // console.log(data);
        // window.open(data.data);
        switch (data.code ? data.code : data.status) {
          case 200:
            // console.log("no data")
            self.successAlert("ลบแบบสอบถามสำเร็จ", "ลบแบบสอบถามสำเร็จ", {
              status: "info",
            });
            self.getListPsurvey();
            break;
          case 402:
            // console.log("no data")
            self.successAlert(
              "ลบแบบสอบถามล้มเหลว",
              "เนื่องจากรหัสผ่านไม่ถูกต้อง",
              { status: "warning" }
            );
            self.getListPsurvey();
            break;
          default:
            // console.log("no data")
            self.successAlert("ลบแบบสอบถามล้มเหลว", "ลบแบบสอบถามล้มเหลว", {
              status: "warning",
            });
            break;
        }
      })
      .catch(function(err) {
        self.successAlert("ลบแบบสอบถามล้มเหลว", "ลบแบบสอบถามล้มเหลว", {
          status: "warning",
        });
        // console.log(err);
      });
    // return false;
  };

  deleteSRP = (index, member_passwd) => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);

    const jsonDec = JSON.parse(decStr);
    // const org_id = jsonDec.member_org;

    const tokenData = jsonDec.token;
    // const org_id = jsonDec.member_org;
    var srp_projectid =
      index in this.state.srpList &&
      "srp_projectid" in this.state.srpList[index]
        ? this.state.srpList[index].srp_projectid
        : null;
    var srp_approved =
      index in this.state.srpList && "srp_approved" in this.state.srpList[index]
        ? this.state.srpList[index].srp_approved
        : null;

    // alert('delete' + id)
    const body = {
      srp_projectid,
      srp_approved,
      member_passwd,
    };
    // console.log(body);
    var request = new Request(config.API_deleteProject, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });
    return fetch(request)
      .then(function(response) {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response");
        } else {
          return response.json(); // go the desired response
        }
      })
      .then(function(data) {
        // console.log(data);
        // window.open(data.data);
        switch (data.code ? data.code : data.status) {
          case 200:
            // console.log("no data")
            self.successAlert("ลบโครงการสำเร็จ", "ลบโครงการสำเร็จ", {
              status: "info",
            });
            self.getListSrp();
            break;
          case 402:
            // console.log("no data")
            self.successAlert(
              "ลบแบบสอบถามล้มเหลว",
              "เนื่องจากรหัสผ่านไม่ถูกต้อง",
              { status: "warning" }
            );
            self.getListSrp();
            break;
          default:
            // console.log("no data")
            self.successAlert("ลบโครงการล้มเหลว", "ลบโครงการล้มเหลว", {
              status: "warning",
            });
            break;
        }
      })
      .catch(function(err) {
        self.successAlert("ลบโครงการล้มเหลว", "ลบโครงการล้มเหลว", {
          status: "warning",
        });
        // console.log(err);
      });
  };

  genPDFPsurvey = async (proId) => {
    // if not selected province
    if (!this.state.selected_prov_name) {
      return alert("เลือกจังหวัดก่อนพิมพ์ไฟล์ pdf");
    }
    let province_id = this.state.selected_prov_id;
    let survey_year =
      (this.state.filterYear + "").substring(2, 4) +
      this.state.filterPeriodYear;
    // console.log(survey_year);
    let token = this.state.token;
    // filter psurvey project list only in this province
    const filterProvince = _.filter(this.state.psurveyList, (e) => {
      return (
        e.province_t === this.state.selected_prov_name &&
        e.projectid.includes(
          (this.state.filterYear + "").substring(2, 4) +
            this.state.filterPeriodYear +
            "-"
        ) &&
        e.condo_refcode.includes(
          (this.state.filterYear + "").substring(2, 4) +
            this.state.filterPeriodYear +
            "-"
        )
      );
    });

    // console.log("filter province", filterProvince);

    // Loop each project to used function print
    // this.printProject(filterProvince[0].projectid);

    if (filterProvince.length === 0) return alert("ไม่มีแบบสอบถามโครงการ");
    this.setState({ generatingPDFAll: filterProvince.length });
    this.setState({ generatingPDF: true });

    for (var i = 0; i < filterProvince.length; i++) {
      this.setState({ generatingPDFinished: i });
      // console.log("filter province", filterProvince[i].projectid);
      await this.printProject(i, filterProvince, "noview");
      // console.log("working on idex:", i);
      if (i === filterProvince.length - 1) {
        this.setState({ generatingPDF: false });
        var request = new Request(config.API_compressedpdf, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            token,
          }),
          body: JSON.stringify({
            survey_year,
            province_id,
          }),
        });
        await fetch(request)
          .then(function(response) {
            response.json().then(function(data) {
              // console.log(data)
              switch (data.code) {
                case 200:
                  // success
                  // self.formatData(data.data);
                  if (data.data) {
                    // TO BE OPEN FOR NORMAL CASE
                    // if ((pattern = "one")) {
                    window.open(config.psurveyPath + data.data);
                    // }
                    // self.setState({ loadingScreen: false });
                  }
                  break;
                default:
                  // failed
                  break;
              }
            });
          })
          .catch(function() {});
      }
    }
  };
  downloadPDFPsurvey = async (proId) => {
    // if not selected province
    if (!this.state.selected_prov_name) {
      return alert("เลือกจังหวัดก่อนพิมพ์ไฟล์ pdf");
    }
    let province_id = this.state.selected_prov_id;
    let survey_year =
      (this.state.filterYear + "").substring(2, 4) +
      this.state.filterPeriodYear;
    let token = this.state.token;
    // let self = this;
    var request = new Request(config.API_downloadzip, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token,
      }),
      body: JSON.stringify({
        survey_year,
        province_id,
      }),
    });
    await fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // console.log(data)
          switch (data.code) {
            case 200:
              // success
              // self.formatData(data.data);
              if (data.data) {
                // TO BE OPEN FOR NORMAL CASE
                // if ((pattern = "one")) {
                window.open(config.psurveyPath + data.data);
                // }
                // self.setState({ loadingScreen: false });
              }
              break;
            case 400:
              alert("ไม่พบไฟล์ zip โครงการ");
              break;
            default:
              break;
          }
        });
      })
      .catch(function() {});
  };
  downloadCSVSRP = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    const body = { org_id: 0 };
    var request = new Request(API_exp_CSVSRP, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
      body: JSON.stringify(body),
    });
    return fetch(request)
      .then(function(response) {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response");
        } else {
          return response.json(); // go the desired response
        }
      })
      .then(function(data) {
        // console.log(data);
        // window.open(data.data);
        switch (data.code ? data.code : data.status) {
          case 200:
            if (data.message === "no data") {
              // console.log("no data")
              self.successAlert(
                "ไม่มีข้อมูล",
                "ไม่มีข้อมูลในการส่งออกไฟล์ CSV",
                { status: "warning" }
              );
            } else {
              // console.log('start download')
              window.open(data.data);
            }
            break;
          default:
            // console.log("no data")
            self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
              status: "warning",
            });
            break;
        }
      })
      .catch(function(err) {
        self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
          status: "warning",
        });
        // console.log(err);
      });
    // return false;
  };

  getProvince() {
    // let self = this;
    let data = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };
    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.code === 200) {
          let prov = data.data;
          // console.log("provinces", prov);
          this.setState({
            province: prov,
            // selected_prov: prov[0].value
          });
        }
      });
  }

  downloadCSVPsurvey = async () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    const body = {
      org_id: this.state.filterCompany,
      year:
        (this.state.filterYear + "").substring(2) +
        "" +
        this.state.filterPeriodYear,

      form: this.state.filterType,
    };
    if (!this.state.filterYear || !this.state.filterPeriodYear) {
      self.successAlert(
        "ไม่สามารถส่งออกไฟล์ CSV",
        "กรุณาเลือกแถบเงื่อนไข ปีช่วงสำรวจ",
        { status: "warning" }
      );
      return;
    }
    this.setState({ loadingScreen: true });

    await axios
      .post(config.API_exp_CSVPSURVEY, body, {
        headers: {
          "Content-Type": "application/json",
          token: self.state.tokenData,
        },
        timeout: 360000,
      })
      .then(function(response) {
        // console.log(response);
        return response.data;
      })
      .then(function(data) {
        switch (data.code) {
          case 200:
            if (data.message === "no data") {
              // console.log("no data")
              self.successAlert(
                "ไม่มีข้อมูล",
                "ไม่มีข้อมูลในการส่งออกไฟล์ CSV",
                { status: "warning" }
              );
              return self.setState({ loadingScreen: false });
            } else {
              // console.log('start download')
              return setTimeout(() => {
                self.setState({ loadingScreen: false });
                return window.open(data.data);
              }, 2000);
            }
          default:
            // console.log("no data")
            self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
              status: "warning",
            });
            return self.setState({ loadingScreen: false });
        }
      })
      .catch(function(err) {
        self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
          status: "warning",
        });
        return self.setState({ loadingScreen: false });
      });
  };

  // downloadCSVPsurveyAll = async () => {
  //   const { key } = this.state;
  //   if (!key) {
  //     return;
  //   }
  //   var self = this;
  //   const body = {
  //     org_id: this.state.filterCompany,
  //     year:
  //       (this.state.filterYear + "").substring(2) +
  //       "" +
  //       this.state.filterPeriodYear,

  //     form: this.state.filterType,
  //   };
  //   if (
  //     !this.state.filterYear ||
  //     !this.state.filterType ||
  //     !this.state.filterPeriodYear
  //   ) {
  //     self.successAlert(
  //       "ไม่สามารถส่งออกไฟล์ CSV",
  //       "กรุณาเลือกแถบเงื่อนไขให้ครบทั้ง ปี,ช่วงสำรวจและ ประเภท",
  //       { status: "warning" }
  //     );
  //     return;
  //   }
  //   this.setState({ loadingScreen: true });

  //   await axios
  //     .post(config.API_exp_CSVPSURVEY + "all", body, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         token: self.state.tokenData,
  //       },
  //       timeout: 360000,
  //     })
  //     .then(function(response) {
  //       // console.log(response);
  //       return response.data;
  //     })
  //     .then(function(data) {
  //       switch (data.code) {
  //         case 200:
  //           if (data.message === "no data") {
  //             // console.log("no data")
  //             self.successAlert(
  //               "ไม่มีข้อมูล",
  //               "ไม่มีข้อมูลในการส่งออกไฟล์ CSV",
  //               { status: "warning" }
  //             );
  //             return self.setState({ loadingScreen: false });
  //           } else {
  //             // console.log('start download')
  //             return setTimeout(() => {
  //               self.setState({ loadingScreen: false });
  //               return window.open(data.data);
  //             }, 2000);
  //           }
  //         default:
  //           // console.log("no data")
  //           self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
  //             status: "warning",
  //           });
  //           return self.setState({ loadingScreen: false });
  //       }
  //     })
  //     .catch(function(err) {
  //       self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
  //         status: "warning",
  //       });
  //       // console.log(err);
  //       return self.setState({ loadingScreen: false });
  //     });
  // };

  parseDigit(string) {
    // return string
    return numeral(string).format("0,0");
  }

  DDMMYY2FullThaiDate(string) {
    const monthArray = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน ",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    console.log("string", string);

    if (string.length === 10) {
      try {
        return (
          string.substring(8, 10) +
          " " +
          monthArray[parseInt(string.substring(5, 8)) - 1] +
          " " +
          // parseInt(string.substring(5,8))+" "+
          string.substring(0, 4)
        );
      } catch {
        return string;
      }
    } else {
      return string;
    }
  }

  formatDateFinish(string) {
    const monthArray = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน ",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    console.log("string", string);
    console.log(
      "sub",
      string.substring(0, 2),
      string.substring(3, 5),
      string.substring(6, 10)
    );

    if (string.length === 10) {
      try {
        return (
          string.substring(0, 2) +
          " " +
          monthArray[parseInt(string.substring(3, 5)) - 1] +
          " " +
          // parseInt(string.substring(5,8))+" "+
          (parseInt(string.substring(6, 10)) + 543)
        );
      } catch {
        return string;
      }
    } else {
      return string;
    }
  }

  html2Pdf = async (id, refcode, pdfText, pattern) => {
    var self = this;
    var param = {
      projectid: id,
      refcode: refcode,
      attr: pdfText,
    };
    var request = new Request(config.API_exportpsurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token,
      }),
      body: JSON.stringify(param),
    });
    await fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // console.log(data)
          switch (data.code) {
            case 200:
              // success
              // self.formatData(data.data);
              if (data.data) {
                // TO BE OPEN FOR NORMAL CASE
                if (pattern === "view") {
                  window.open(config.psurveyPath + data.data);
                }
                self.setState({ loadingScreen: false });
              }
              break;
            default:
              // failed
              break;
          }
        });
      })
      .catch(function() {});
  };

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  async printProject(b, psurvey, pattern) {
    var self = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var type =
      b in psurvey && "project_type" in psurvey[b]
        ? psurvey[b].project_type
        : null;
    var id =
      b in psurvey && "projectid" in psurvey[b] ? psurvey[b].projectid : null;
    var status =
      b in psurvey && "condo_approved" in psurvey[b]
        ? psurvey[b].condo_approved
        : null;
    var refcode =
      b in psurvey && "condo_refcode" in psurvey[b]
        ? psurvey[b].condo_refcode
        : null;
    let body = {};

    switch (type) {
      case "household":
        body = {
          household_projectid: id,
          household_approved: status,
        };
        break;
      case "villa":
        body = {
          villa_projectid: id,
          villa_approved: status,
        };
        break;
      case "condo":
        body = {
          condo_projectid: id,
          condo_approved: status,
        };
        break;
      default:
        break;
    }
    let api_call = ``;
    if (type === "household") {
      api_call = config.API_showdetail_projectid_household;
    } else if (type === "condo") {
      api_call = config.API_showdetail_projectid_condo;
    } else if (type === "villa") {
      api_call = config.API_showdetail_projectid_villa;
    }

    var allText = "";
    try {
      var request = new Request(api_call, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(body),
      });
      // console.log(body);
      await fetch(request)
        .then((response) => response.json())
        .then(async (responseJson) => {
          // console.log(responseJson);
          switch (responseJson.code) {
            case 200:
              //window.open(psurveyPrintTemplate);
              try {
                var pdfText = await this.renderPDF(
                  responseJson,
                  type,
                  allText,
                  id,
                  false
                );
                // console.log("pdfText", pdfText);
                // var htmlText = await this.downloadText(pdfText)
                // window.open(htmlText)
                // return self.setState({ loadingScreen: false });
                return await self.html2Pdf(id, refcode, pdfText, pattern);
                // break;
              } catch {
                self.successAlert(
                  self.props.toggleLng === "TH"
                    ? "การพิมพ์โครงการล้มเหลว"
                    : "Print Project Detail Failed",
                  self.props.toggleLng === "TH"
                    ? "การพิมพ์โครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
                    : "Print Project Detail Failed",
                  { warning: true }
                );
                return;
              }
            default:
              self.successAlert(
                self.props.toggleLng === "TH"
                  ? "การพิมพ์โครงการล้มเหลว"
                  : "Print Project Detail Failed",
                self.props.toggleLng === "TH"
                  ? "การพิมพ์โครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
                  : "Print Project Detail Failed",
                { warning: true }
              );
              return;
          }
        })
        .catch((error) => {
          self.successAlert(
            self.props.toggleLng === "TH"
              ? "การพิมพ์โครงการล้มเหลว"
              : "Print Project Detail Failed",
            self.props.toggleLng === "TH"
              ? "การพิมพ์โครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
              : "Print Project Detail Failed",
            { warning: true }
          );
          return;
        });
    } catch {
      self.successAlert(
        self.props.toggleLng === "TH"
          ? "การพิมพ์โครงการล้มเหลว"
          : "Print Project Detail Failed",
        self.props.toggleLng === "TH"
          ? "การพิมพ์โครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
          : "Print Project Detail Failed",
        { warning: true }
      );
      return;
    }
  }

  renderPDF = async (responseJson, type, allText, id, isBlob) => {
    var self = this;
    // console.log("responseJson", responseJson.bs[0].bs_finishedbuild);

    return new Promise(async function(outresolve, outreject) {
      var rawFile = new XMLHttpRequest();
      rawFile.open("GET", psurveyPrintTemplate, false);
      rawFile.onreadystatechange = async function() {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status === 0) {
            allText = rawFile.responseText;
            try {
              return new Promise(async function(resolve, reject) {
                var popLocation = {
                  lat: responseJson.data[0].lat ? responseJson.data[0].lat : 0,
                  lng: responseJson.data[0].lon ? responseJson.data[0].lon : 0,
                };
                var marker = L.marker(popLocation);
                await self.state.markerLayer.clearLayers();
                await marker
                  .bindPopup(responseJson.data[0][type + "_nameth"])
                  .openPopup();
                await self.state.markerLayer.addLayer(marker);
                await self.map.setView(popLocation, 17).setZoom(17);
                leafletImage(self.map, function(err, canvas) {
                  if (err) {
                    return reject(false);
                  }
                  return resolve(canvas.toDataURL());
                });
              }).then(async (curImage) => {
                allText = self.renderPDFTable(allText, responseJson, type);

                if (curImage) {
                  allText = allText.replace(
                    "printValImage.googlemapimg",
                    '<img width="240px" height="160px" src="' +
                      curImage +
                      '" >' +
                      "</img>"
                  );
                }
                for (let i = 0; i < attributeName.length; i++) {
                  // console.log("search for " + "printVal." + attributeName[i]);
                  let foundPlace = allText.indexOf(
                    "printVal." + attributeName[i]
                  );
                  // console.log(foundPlace)
                  var replaceAll = new RegExp(
                    "printVal." + attributeName[i],
                    "g"
                  );
                  if (
                    foundPlace > -1 &&
                    responseJson.data[0][type + "_" + attributeName[i]]
                  ) {
                    if (attributeName[i] === "datefirstsell") {
                      console.log(
                        "datefirstsell",
                        responseJson.data[0][type + "_" + attributeName[i]]
                      );
                      allText = allText.replace(
                        replaceAll,
                        responseJson.data[0][type + "_" + attributeName[i]]
                          .length > 9
                          ? self.DDMMYY2FullThaiDate(
                              parseInt(
                                responseJson.data[0][
                                  type + "_" + attributeName[i]
                                ].substring(0, 4)
                              ) +
                                543 +
                                responseJson.data[0][
                                  type + "_" + attributeName[i]
                                ].substring(4, 10)
                            )
                          : responseJson.data[0][type + "_" + attributeName[i]]
                      );
                    }
                    if (type === "condo" && attributeName[i] === "datefinish") {
                      console.log(
                        "datefinish",
                        responseJson.bs[0].bs_finishedbuild
                      );
                      allText = allText.replace(
                        replaceAll,
                        responseJson.bs[0].bs_finishedbuild.length > 9
                          ? self.formatDateFinish(
                              responseJson.bs[0].bs_finishedbuild
                            )
                          : responseJson.bs[0].bs_finishedbuild
                      );
                    }
                    if (type !== "condo" && attributeName[i] === "datefinish") {
                      allText = allText.replace(
                        replaceAll,
                        responseJson.data[0][type + "_" + attributeName[i]]
                          .length > 9
                          ? self.DDMMYY2FullThaiDate(
                              parseInt(
                                responseJson.data[0][
                                  type + "_" + attributeName[i]
                                ].substring(0, 4)
                              ) +
                                543 +
                                responseJson.data[0][
                                  type + "_" + attributeName[i]
                                ].substring(4, 10)
                            )
                          : responseJson.data[0][type + "_" + attributeName[i]]
                      );
                    } else {
                      allText = allText.replace(
                        replaceAll,
                        responseJson.data[0][type + "_" + attributeName[i]]
                      );
                    }
                    // console.log("replace with")
                    // console.log(responseJson.data[0][type+"_"+attributeName[i]])
                  } else if (
                    foundPlace > -1 &&
                    responseJson.data[0][attributeName[i]]
                  ) {
                    allText = allText.replace(
                      replaceAll,
                      responseJson.data[0][attributeName[i]]
                    );
                  } else {
                    if (foundPlace > -1) {
                      if (attributeName[i] === "periodtext") {
                        // console.log("periodtext")
                        // console.log(id)
                        var periodtext = "";
                        if (id.substring(2, 4) === "H1") {
                          periodtext = "สำรวจครึ่งแรก";
                        } else if (id.substring(2, 4) === "H2") {
                          periodtext = "สำรวจครึ่งหลัง";
                        } else if (id.substring(2, 4) === "Q1") {
                          periodtext = "สำรวจไตรมาส 1";
                        } else if (id.substring(2, 4) === "Q2") {
                          periodtext = "สำรวจไตรมาส 2";
                        } else if (id.substring(2, 4) === "Q3") {
                          periodtext = "สำรวจไตรมาส 3";
                        } else if (id.substring(2, 4) === "Q4") {
                          periodtext = "สำรวจไตรมาส 4";
                        }
                        periodtext =
                          periodtext + "  ปี 25" + id.substring(0, 2);
                        // console.log(periodtext)
                        allText = allText.replace(replaceAll, periodtext);
                      } else {
                        if (
                          attributeName[i] === "rai" ||
                          attributeName[i] === "ngan" ||
                          attributeName[i] === "sqwa"
                        ) {
                          allText = allText.replace(replaceAll, "0");
                        } else {
                          allText = allText.replace(
                            replaceAll,
                            // attributeName[i]);
                            "ไม่มี" +
                              (attributeName[i] === "refcode"
                                ? " refcode*"
                                : "")
                          );
                        }
                      }
                    }
                  }
                }
                for (let i = 0; i < attributeImageName.length; i++) {
                  // console.log(
                  //   "search for " + "printValImage." + attributeImageName[i]
                  // );
                  let foundPlace = allText.indexOf(
                    "printValImage." + attributeImageName[i]
                  );
                  // console.log(foundPlace);
                  if (
                    foundPlace > -1 &&
                    responseJson.data[0][type + "_" + attributeImageName[i]] &&
                    responseJson.data[0][type + "_" + attributeImageName[i]][0]
                  ) {
                    if (false && attributeImageName[i] === "googlemapimg") {
                    } else {
                      allText = allText.replace(
                        "printValImage." + attributeImageName[i],
                        '<img width="240px" height="160px"  src="' +
                          (isBlob
                            ? config.psurveyPath +
                              responseJson.data[0][
                                type + "_" + attributeImageName[i]
                              ][0]
                            : "file:///var/www/html/public/" +
                              responseJson.data[0][
                                type + "_" + attributeImageName[i]
                              ][0].substring(5)) +
                          '" />' +
                          ""
                      );
                      // console.log(allText);
                      // console.log("replace img with");
                      // console.log(
                      //   responseJson.data[0][
                      //     type + "_" + attributeImageName[i]
                      //   ][0]
                      // );
                    }
                  } else {
                    if (foundPlace > -1) {
                      allText = allText.replace(
                        "printValImage." + attributeImageName[i],
                        // attributeImageName[i]);
                        "ไม่มี"
                      );
                    }
                  }
                }
                let foundPlace = allText.indexOf("printValTable.unity");
                if (foundPlace > -1 && responseJson["unity"]) {
                  let unityCode = "";
                  responseJson["unity"].forEach(function(uni, ix) {
                    let ThUni = uni.unity_detail;

                    if (ThUni === "pool") {
                      ThUni = "สระว่ายน้ำ";
                    } else if (ThUni === "other") {
                      ThUni = null;
                    } else if (ThUni === "clubhouse") {
                      ThUni = "คลับเฮ้าส์";
                    } else if (ThUni === "security") {
                      ThUni = "รปภ.";
                    }

                    if (ix > 0) {
                      if (ThUni) {
                        unityCode = unityCode + "," + ThUni;
                      }
                    } else {
                      unityCode = unityCode + ThUni;
                    }
                  });
                  allText = allText.replace("printValTable.unity", unityCode);
                  // console.log("replace with")
                  // console.log(responseJson['unity'])
                }
                // done page one
                // console.log("done 1 proj");

                // console.log(allText)
                // return allText
                return outresolve(allText);
              });
            } catch {
              self.successAlert(
                self.props.toggleLng === "TH"
                  ? "การพิมพ์โครงการล้มเหลว"
                  : "Print Project Detail Failed",
                self.props.toggleLng === "TH"
                  ? "การพิมพ์โครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
                  : "Print Project Detail Failed",
                { warning: true }
              );
              self.setState({ loadingScreen: true });

              return;
            }
          }
        }
      };
      rawFile.send(null);
    });
  };

  renderPDFTable = (allText, responseJson, type) => {
    // var self = this;

    // let attributeTableName = type === "condo" ? ["st"] : ["stc"];
    try {
      if (type === "condo") {
        var condoHeader = {
          st_buildingname: "ชื่ออาคาร",
          floortype: "ประเภทชั้นของห้องชุด",
          roomtype: "ประเภทห้อง",
          pricerange: "ช่วงราคา",
          minarealicense: "พื้นที่ตามเอกสารสิทธิ์ต่ำสุด",
          maxarealicense: "พื้นที่ตามเอกสารสิทธิ์สูงสุด",
          minarea: "พื้นที่ใช้สอยต่ำสุด",
          maxarea: "พื้นที่ใช้สอยสูงสุด",
          st_allunit: "หน่วยทั้งหมด",
          st_allsigned: "หน่วยที่ทำ<br/>สัญญาแล้ว",
          st_remaining: "หน่วย<br/>เหลือขาย",
        };
        var condoRoomType = [
          { id: 1, name: "สตูดิโอ" },
          { id: 2, name: "1 ห้องนอน" },
          { id: 3, name: "2 ห้องนอน" },
          { id: 4, name: "3 ห้องนอนขึ้นไป" },
        ];
        var condoRoomFloorType = [
          { id: 1, name: "ห้องชั้นเดียว" },
          { id: 2, name: "ห้องมีชั้นลอย (Loft)" },
          { id: 3, name: "ห้องชุด 2 ชั้น (Duplex)" },
        ];
        for (let i = 0; i < ["st"].length; i++) {
          if (true) {
            const rowPerPage = 50;
            var tablePages = "";
            const pageNumber = Math.ceil(
              responseJson[["st"][i]].length / rowPerPage
            );
            for (let pageInx = 0; pageInx < pageNumber; pageInx++) {
              const fromIndex = pageInx * rowPerPage;
              const toIndex = fromIndex + rowPerPage;

              let tableCode = "";
              var classNameCss = "dataTable";
              tableCode = tableCode + "<table class=" + classNameCss + " >";
              tableCode = tableCode + "<tr>";
              Object.keys(condoHeader).forEach(function(idx) {
                if (condoHeader[idx]) {
                  // console.log("IN", condoHeader[idx]);
                  if (
                    condoHeader[idx] !== "พื้นที่ตามเอกสารสิทธิ์สูงสุด" &&
                    condoHeader[idx] !== "พื้นที่ตามเอกสารสิทธิ์ต่ำสุด" &&
                    condoHeader[idx] !== "พื้นที่ใช้สอยสูงสุด" &&
                    condoHeader[idx] !== "พื้นที่ใช้สอยต่ำสุด"
                  ) {
                    // console.log("IF1", condoHeader[idx]);
                    tableCode =
                      tableCode +
                      "<th class=" +
                      classNameCss +
                      " rowSpan='2' >";
                    tableCode = tableCode + condoHeader[idx];
                    tableCode = tableCode + "</th>";
                  } else {
                    if (
                      condoHeader[idx] !== "พื้นที่ตามเอกสารสิทธิ์ต่ำสุด" &&
                      condoHeader[idx] !== "พื้นที่ใช้สอยต่ำสุด"
                    ) {
                      // console.log("IF2", condoHeader[idx]);
                      tableCode =
                        tableCode +
                        "<th class=" +
                        classNameCss +
                        " rowSpan='1' colSpan='2' >";
                      if (condoHeader[idx] === "พื้นที่ตามเอกสารสิทธิ์สูงสุด") {
                        tableCode = tableCode + "พื้นที่ตามเอกสารสิทธิ์";
                      } else if (condoHeader[idx] === "พื้นที่ใช้สอยสูงสุด") {
                        tableCode = tableCode + "พื้นที่ใช้สอย";
                      }
                      tableCode = tableCode + "</th>";
                    }
                  }
                }
              });
              tableCode = tableCode + "</tr>";
              tableCode =
                tableCode +
                "<tr><th class=" +
                classNameCss +
                ">ต่ำสุด</th><th class=" +
                classNameCss +
                ">สูงสุด</th><th class=" +
                classNameCss +
                ">ต่ำสุด</th><th class=" +
                classNameCss +
                ">สูงสุด</th></tr>";

              var sumAll = 0;
              var sumSigned = 0;
              var sumRemaining = 0;

              for (let j = fromIndex; j < toIndex; j++) {
                if (responseJson[["st"][i]].length > j) {
                  tableCode = tableCode + "<tr class=" + classNameCss + " >";

                  Object.keys(condoHeader).forEach((idx) => {
                    if (idx === "st_allunit") {
                      sumAll = sumAll + responseJson[["st"][i]][j][idx];
                    } else if (idx === "st_allsigned") {
                      sumSigned = sumSigned + responseJson[["st"][i]][j][idx];
                    } else if (idx === "st_remaining") {
                      sumRemaining =
                        sumRemaining + responseJson[["st"][i]][j][idx];
                    }

                    if (
                      idx === "st_buildingname" &&
                      responseJson["sellroom"][
                        parseInt(responseJson[["st"][i]][j][idx]) - 1
                      ] &&
                      responseJson["sellroom"][
                        parseInt(responseJson[["st"][i]][j][idx]) - 1
                      ]["sellroom_buildingname"]
                    ) {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode =
                        tableCode +
                        responseJson["sellroom"][
                          parseInt(responseJson[["st"][i]][j][idx]) - 1
                        ]["sellroom_buildingname"];
                      tableCode = tableCode + "</td>";
                    } else if (idx === "floortype") {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";

                      if (
                        "sellroom_floortype" in
                          responseJson["sellroom"][
                            parseInt(
                              responseJson[["st"][i]][j]["st_buildingname"]
                            ) - 1
                          ] &&
                        parseInt(
                          responseJson["sellroom"][
                            parseInt(
                              responseJson[["st"][i]][j]["st_buildingname"]
                            ) - 1
                          ]["sellroom_floortype"]
                        ) -
                          1 <
                          4
                      ) {
                        tableCode +=
                          condoRoomFloorType[
                            parseInt(
                              responseJson["sellroom"][
                                parseInt(
                                  responseJson[["st"][i]][j]["st_buildingname"]
                                ) - 1
                              ]["sellroom_floortype"]
                            ) - 1
                          ]["name"] + "</td>";
                      } else {
                        tableCode = tableCode + "ไม่มี</td>";
                      }
                    } else if (idx === "roomtype") {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";

                      if (
                        "sellroom_type" in
                          responseJson["sellroom"][
                            parseInt(
                              responseJson[["st"][i]][j]["st_buildingname"]
                            ) - 1
                          ] &&
                        parseInt(
                          responseJson["sellroom"][
                            parseInt(
                              responseJson[["st"][i]][j]["st_buildingname"]
                            ) - 1
                          ]["sellroom_type"]
                        ) -
                          1 <
                          4
                      ) {
                        tableCode +=
                          condoRoomType[
                            parseInt(
                              responseJson["sellroom"][
                                parseInt(
                                  responseJson[["st"][i]][j]["st_buildingname"]
                                ) - 1
                              ]["sellroom_type"]
                            ) - 1
                          ]["name"] + "</td>";
                      } else {
                        tableCode = tableCode + "ไม่มี</td>";
                      }
                    } else if (idx === "pricerange") {
                      tableCode =
                        tableCode +
                        "<td class=" +
                        classNameCss +
                        " >" +
                        responseJson["st"][j]["srpprice_info"] +
                        "</td>";
                    } else if (
                      idx === "minarealicense" &&
                      responseJson["sellroom"][
                        parseInt(
                          responseJson[["st"][i]][j]["st_buildingname"]
                        ) - 1
                      ]
                    ) {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode =
                        tableCode +
                        responseJson["sellroom"][
                          parseInt(
                            responseJson[["st"][i]][j]["st_buildingname"]
                          ) - 1
                        ]["sellroom_minarealicense"];
                      tableCode = tableCode + "</td>";
                    } else if (
                      idx === "maxarealicense" &&
                      responseJson["sellroom"][
                        parseInt(
                          responseJson[["st"][i]][j]["st_buildingname"]
                        ) - 1
                      ]
                    ) {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode =
                        tableCode +
                        responseJson["sellroom"][
                          parseInt(
                            responseJson[["st"][i]][j]["st_buildingname"]
                          ) - 1
                        ]["sellroom_maxarealicense"];
                      tableCode = tableCode + "</td>";
                    } else if (
                      idx === "minarea" &&
                      responseJson["sellroom"][
                        parseInt(
                          responseJson[["st"][i]][j]["st_buildingname"]
                        ) - 1
                      ]
                    ) {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode =
                        tableCode +
                        responseJson["sellroom"][
                          parseInt(
                            responseJson[["st"][i]][j]["st_buildingname"]
                          ) - 1
                        ]["sellroom_minarea"];
                      tableCode = tableCode + "</td>";
                    } else if (
                      idx === "maxarea" &&
                      responseJson["sellroom"][
                        parseInt(
                          responseJson[["st"][i]][j]["st_buildingname"]
                        ) - 1
                      ]
                    ) {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode =
                        tableCode +
                        responseJson["sellroom"][
                          parseInt(
                            responseJson[["st"][i]][j]["st_buildingname"]
                          ) - 1
                        ]["sellroom_maxarea"];
                      tableCode = tableCode + "</td>";
                    } else {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      tableCode = tableCode + responseJson["st"][j][idx];
                      tableCode = tableCode + "</td>";
                    }
                  });

                  tableCode = tableCode + "</tr>";
                }
              }
              if (pageInx + 1 === pageNumber) {
                tableCode = tableCode + "<tr>";
                Object.keys(condoHeader).forEach(function(idx, ix) {
                  tableCode = tableCode + "<th class=" + classNameCss + " >";
                  if (ix === 3) {
                    tableCode = tableCode + "รวมทั้งหมด";
                  } else if (ix === 8) {
                    tableCode = tableCode + numeral(sumAll).format("0,0");
                  } else if (ix === 9) {
                    tableCode = tableCode + numeral(sumSigned).format("0,0");
                  } else if (ix === 10) {
                    tableCode = tableCode + numeral(sumRemaining).format("0,0");
                  }
                  tableCode = tableCode + "</th>";
                });
                tableCode = tableCode + "</tr>";

                tableCode = tableCode + "<tr>";
                Object.keys(condoHeader).forEach(function(idx, ix) {
                  tableCode = tableCode + "<th class=" + classNameCss + " >";
                  if (ix === 3) {
                    tableCode = tableCode + "% ขายได้";
                  } else if (ix === 8) {
                    let allsigned = 0;
                    let allunit = 0;
                    for (let i = 0; i < responseJson["st"].length; i++) {
                      allsigned += responseJson["st"][i]["st_remaining"];
                      allunit += responseJson["st"][i]["st_allunit"];
                    }
                    let percentage = 100 - (allsigned / allunit) * 100;
                    tableCode =
                      tableCode +
                      (percentage ? percentage.toFixed(2) : 0) +
                      " %";
                  }
                  tableCode = tableCode + "</th>";
                });
                tableCode = tableCode + "</tr>";
              }
              tableCode = tableCode + "</table>";
              tablePages += htmlTablePage(
                pageInx + 2 + "/" + (pageNumber + 1),
                tableCode
              );
            }
            allText = allText.replace(
              "printVal.firstPageNumber",
              "1/" + (pageNumber + 1)
            );
            allText = allText.replace("printValTablePage.X", tablePages);
          } else {
            allText.replace(
              "printValTable." + ["st"][i],
              // tableCode);
              "ไม่มี"
            );
          }
          // console.log(responseJson.data[0][type+"_"+["st"][i]][0])
        }
      } else {
        var housevillaHeader = {
          stc_type: "ประเภทบ้าน หน้ากว้าง",
          floor: "จำนวนชั้น",
          pricerange: "ช่วงราคา",
          minarea: "พื้นที่ใช้สอยต่ำสุด",
          maxarea: "พื้นที่ใช้สอยสูงสุด",
          stc_unit: "หน่วยทั้งหมด",
          stc_sellsigned: "หน่วยที่ทำ<br/>สัญญาแล้ว",
          stc_remaining: "หน่วย<br/>เหลือขาย",
        };
        var houseCatType = [
          { id: 1, name: "บ้านเดี่ยว" },
          { id: 2, name: "บ้านแฝด" },
          { id: 3, name: "ทาวน์เฮ้าส์" },
          { id: 4, name: "อาคารพาณิชย์" },
          { id: 4, name: "ที่ดินเปล่า" },
        ];

        for (let i = 0; i < [["stc"]].length; i++) {
          // console.log('search for ' + "printValTable.st")
          if (true) {
            const rowPerPage = 40;
            tablePages = "";
            const pageNumber = Math.ceil(
              responseJson[["stc"][i]].length / rowPerPage
            );
            // console.log(
            //   responseJson[["stc"][i]].length / rowPerPage
            // );
            // console.log(pageNumber);
            for (let pageInx = 0; pageInx < pageNumber; pageInx++) {
              const fromIndex = pageInx * rowPerPage;
              const toIndex = fromIndex + rowPerPage;

              let tableCode = "";
              classNameCss = "dataTable";
              tableCode = tableCode + "<table class=" + classNameCss + " >";
              tableCode = tableCode + "<tr>";
              Object.keys(housevillaHeader).forEach(function(idx) {
                if (housevillaHeader[idx]) {
                  if (housevillaHeader[idx] !== "พื้นที่ใช้สอยสูงสุด") {
                    if (housevillaHeader[idx] !== "พื้นที่ใช้สอยต่ำสุด") {
                      tableCode =
                        tableCode +
                        "<th class=" +
                        classNameCss +
                        " rowSpan='2' >";
                      tableCode = tableCode + housevillaHeader[idx];
                      tableCode = tableCode + "</th>";
                    }
                  } else {
                    if (housevillaHeader[idx] !== "พื้นที่ใช้สอยต่ำสุด") {
                      tableCode =
                        tableCode +
                        "<th class=" +
                        classNameCss +
                        " rowSpan='1' colSpan='2' >";
                      tableCode = tableCode + "พื้นที่ใช้สอย";
                      tableCode = tableCode + "</th>";
                    }
                  }
                }
              });
              tableCode = tableCode + "</tr>";
              tableCode =
                tableCode +
                "<tr><th class=" +
                classNameCss +
                ">ต่ำสุด</th><th class=" +
                classNameCss +
                ">สูงสุด</th></tr>";
              sumAll = 0;
              sumSigned = 0;
              sumRemaining = 0;
              for (let j = fromIndex; j < toIndex; j++) {
                if (responseJson[["stc"][i]].length > j) {
                  tableCode = tableCode + "<tr class=" + classNameCss + " >";

                  Object.keys(housevillaHeader).forEach(function(idx) {
                    if (idx === "stc_unit") {
                      sumAll = sumAll + responseJson[["stc"][i]][j][idx];
                    } else if (idx === "stc_sellsigned") {
                      sumSigned = sumSigned + responseJson[["stc"][i]][j][idx];
                    } else if (idx === "stc_remaining") {
                      sumRemaining =
                        sumRemaining + responseJson[["stc"][i]][j][idx];
                    }
                    if (idx === "pricerange") {
                      tableCode =
                        tableCode +
                        "<td class=" +
                        classNameCss +
                        " >" +
                        responseJson["stc"][j]["srpprice_info"] +
                        "</td>";
                    } else if (
                      idx === "minarea" ||
                      idx === "maxarea" ||
                      idx === "floor"
                    ) {
                      let thisCat = {};
                      // console.log(responseJson['stc'][j]['stc_type'])
                      // console.log(isNaN(responseJson['stc'][j]['stc_type']))
                      if (isNaN(responseJson["stc"][j]["stc_type"])) {
                        let thisType = houseCatType.filter(
                          (cat) =>
                            responseJson["stc"][j]["stc_type"].indexOf(
                              cat.name
                            ) > -1
                        );
                        // console.log(thisType)

                        thisCat = responseJson["cat"].filter(
                          (cat) =>
                            responseJson["stc"][j]["stc_type"].indexOf(
                              " " + cat.cat_floor + " ชั้น"
                            ) > -1 &&
                            responseJson["stc"][j]["stc_type"].indexOf(
                              "กว้าง " + cat.cat_width + " ม."
                            ) > -1 &&
                            responseJson["stc"][j]["stc_type"].indexOf(
                              thisType[0].name + " "
                            ) > -1
                        )[0];
                        // console.log(responseJson['cat'].filter((cat) =>
                        // responseJson['stc'][j]['stc_type'].indexOf("" + cat.cat_floor) > -1 &&
                        // responseJson['stc'][j]['stc_type'].indexOf("" + cat.cat_width) > -1 &&
                        // responseJson['stc'][j]['stc_type'].indexOf("" + thisType[0].name) > -1
                        // ))
                      } else {
                        thisCat =
                          responseJson["cat"][
                            parseInt(responseJson["stc"][j]["stc_type"]) - 1
                          ];
                      }
                      // console.log(thisCat)
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";
                      if (idx === "minarea" && thisCat) {
                        tableCode = tableCode + thisCat["cat_minarea"];
                      } else if (idx === "maxarea" && thisCat) {
                        tableCode = tableCode + thisCat["cat_maxarea"];
                      } else if (idx === "floor" && thisCat) {
                        tableCode = tableCode + thisCat["cat_floor"];
                      }
                      tableCode = tableCode + "</td>";
                    } else {
                      tableCode =
                        tableCode + "<td class=" + classNameCss + " >";

                      if (
                        responseJson["stc"][j][idx] &&
                        responseJson["stc"][j][idx].length > 0 &&
                        responseJson["stc"][j][idx].indexOf("ชั้น") > 1
                      ) {
                        try {
                          let fromFloor = responseJson["stc"][j][idx].indexOf(
                            "ชั้น"
                          );
                          const typeWithNoFloor =
                            responseJson["stc"][j][idx].substring(
                              0,
                              fromFloor - 2
                            ) +
                            responseJson["stc"][j][idx].substring(
                              fromFloor + 4
                            );
                          tableCode = tableCode + typeWithNoFloor;
                        } catch {
                          tableCode = tableCode + responseJson["stc"][j][idx];
                        }
                      } else {
                        tableCode = tableCode + responseJson["stc"][j][idx];
                      }
                      tableCode = tableCode + "</td>";
                    }
                  });
                  tableCode = tableCode + "</tr>";
                }
              }

              if (pageInx + 1 === pageNumber) {
                tableCode = tableCode + "<tr>";
                Object.keys(housevillaHeader).forEach(function(idx, ix) {
                  tableCode = tableCode + "<th class=" + classNameCss + " >";
                  if (ix === 2) {
                    tableCode = tableCode + "รวมทั้งหมด";
                  } else if (ix === 5) {
                    tableCode = tableCode + numeral(sumAll).format("0,0");
                  } else if (ix === 6) {
                    tableCode = tableCode + numeral(sumSigned).format("0,0");
                  } else if (ix === 7) {
                    tableCode = tableCode + numeral(sumRemaining).format("0,0");
                  }
                  tableCode = tableCode + "</th>";
                });
                tableCode = tableCode + "</tr>";

                tableCode = tableCode + "<tr>";
                Object.keys(housevillaHeader).forEach(function(idx, ix) {
                  tableCode = tableCode + "<th class=" + classNameCss + " >";
                  if (ix === 2) {
                    tableCode = tableCode + "% ขายได้";
                  } else if (ix === 5) {
                    let allsigned = 0;
                    let allunit = 0;
                    for (let i = 0; i < responseJson["stc"].length; i++) {
                      allsigned += responseJson["stc"][i]["stc_remaining"];
                      allunit += responseJson["stc"][i]["stc_unit"];
                    }
                    let percentage = 100 - (allsigned / allunit) * 100;

                    tableCode =
                      tableCode +
                      (percentage ? percentage.toFixed(2) : 0) +
                      " %";
                  }
                  tableCode = tableCode + "</th>";
                });
                tableCode = tableCode + "</tr>";
              }

              tableCode = tableCode + "</table>";

              tablePages += htmlTablePage(
                pageInx + 2 + "/" + (pageNumber + 1),
                tableCode
              );
            }
            allText = allText.replace(
              "printVal.firstPageNumber",
              "1/" + (pageNumber + 1)
            );
            allText = allText.replace("printValTablePage.X", tablePages);
            // console.log("replace tableCode");
            // allText += htmlTablePage("3/5", "123", tableCode);
            // allText += tableCode;
            // console.log(tableCode)

            // console.log(tableCode)

            // console.log("replace img with")
          } else {
            allText.replace(
              "printValTable.st",
              // tableCode);
              "ไม่มี"
            );
          }
          // console.log(responseJson.data[0][type+"_"+["stc"][i]][0])
        }
      }
    } catch (erre) {
      // console.log(erre)
      allText.replace("printValTable.st", "ไม่มี");
      // console.log(allText)
      // console.log("read error")
    }

    return allText;
  };

  downloadText(rawText) {
    var textFile = null,
      makeTextFile = function(text) {
        var data = new Blob([text], { type: "text/html;charset=utf-8" });
        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
          window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);

        return textFile;
      };
    return makeTextFile(rawText);
  }

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });
          self.getPsurvey();
          self.getDraftPsurvey();
          self.getListPsurvey();
          self.getSrp();
          self.getDraftSrp();
          self.getListSrp();
          self.getRegTime();
          self.getOrgList();
          self.getRegLog();
          self.getZone();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  appendHtml(el, str) {
    var div = document.createElement("div");
    div.innerHTML = str;
    while (div.children.length > 0) {
      el.appendChild(div.children[0]);
    }
  }

  handleUploadOld = () => {
    if (this.state.openUploadOld === true) {
      this.setState({ openUploadOld: false });
    } else {
      this.setState({ openUploadOld: true });
    }
  };

  handleUploadNew = () => {
    if (this.state.openUploadNew === true) {
      this.setState({ openUploadNew: false });
    } else {
      this.setState({ openUploadNew: true });
    }
  };

  handleQCProjectPass = (projectid, refcode, type) => {
    console.log(type);
    let { toggleLng } = this.props;
    let that = this;
    that.passQCAlert(
      toggleLng === "TH" ? "สถานะการตรวจสอบโครงการ" : "QC Project Status",
      toggleLng === "TH" ? "ผ่าน" : "Pass",
      {
        info: true,
        onConfirm: () => {
          let body = {
            projectid: projectid,
            refcode: refcode,
            type: type,
          };
          let { key } = this.state;
          if (!key) {
            return;
          }
          let keyutf = CryptoJS.enc.Utf8.parse(key);
          let iv = CryptoJS.enc.Base64.parse(key);

          let aesStr = localStorage.getItem("session");
          let dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          let decStr = CryptoJS.enc.Utf8.stringify(dec);
          let jsonDec = JSON.parse(decStr);
          let tokenData = jsonDec.token;
          var request = new Request(`${config.API_qcpass}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });
          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.getPsurvey();
                that.getDraftPsurvey();
                that.getListPsurvey();
                return that.setState({ alert: null });
              } else {
                return that.setState({ alert: null });
              }
            })
            .catch((error) => {
              return that.setState({ alert: null });
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  handleQCProjectReject = (projectid, refcode, type) => {
    let { toggleLng } = this.props;
    let that = this;
    that.rejectQCAlert(
      toggleLng === "TH" ? "สถานะการตรวจสอบโครงการ" : "QC Project Status",
      <div>
        {toggleLng === "TH" ? "ไม่ผ่าน" : "Reject"}
        <div className="pass-wrapper">
          <CustomInput
            inputProps={{
              type: "text",
            }}
            id="remarkInput"
          ></CustomInput>
        </div>
      </div>,
      {
        info: true,
        placeHolder: "กรุณากรอกเหตุผล",
        onConfirm: () => {
          let remark = document.getElementById("remarkInput").value;
          let body = {
            projectid: projectid,
            refcode: refcode,
            type: type,
            remark: remark,
          };
          let { key } = this.state;
          if (!key) {
            return;
          }
          let keyutf = CryptoJS.enc.Utf8.parse(key);
          let iv = CryptoJS.enc.Base64.parse(key);

          let aesStr = localStorage.getItem("session");
          let dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          let decStr = CryptoJS.enc.Utf8.stringify(dec);
          let jsonDec = JSON.parse(decStr);
          let tokenData = jsonDec.token;
          var request = new Request(`${config.API_qcreject}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });
          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.getPsurvey();
                that.getDraftPsurvey();
                that.getListPsurvey();
                that.setState({ alert: null });
              } else {
                that.setState({ alert: null });
              }
            })
            .catch((error) => {
              that.setState({ alert: null });
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  change(event, cate, currentRowNumber) {
    const category = { ...this.state[cate] };
    category[currentRowNumber] = parseInt(event.target.value);
    this.setState({ [cate]: category });
  }
  dateParse = (date) => {
    try {
      let day = date.substring(8, 10);
      let month = date.substring(5, 7);
      let year = date.substring(0, 4);
      // let time = date.substring(11, 19);
      // return day + "-" + month + "-" + (parseInt(year) + 543) + " : " + time;
      // return day + "-" + month + "-" + (parseInt(year) + 543) + " : " + time;
      return day + "/" + month + "/" + (parseInt(year) + 543);
    } catch {
      return "";
    }
  };
  dateOnlyParse = (date) => {
    try {
      let day = date.substring(8, 10);
      let month = date.substring(5, 7);
      let year = date.substring(0, 4);
      return day + "-" + month + "-" + (parseInt(year) + 543);
    } catch {
      return "";
    }
  };
  timeOnlyParse = (date) => {
    try {
      let time = date.substring(11, 19);
      return time;
    } catch {
      return "";
    }
  };
  handleDropFile = (file) => {
    this.handlePressFile({ target: { files: file } });
  };
  handleDropFileSrp = (file) => {
    this.handlePressFileSrp({ target: { files: file } });
  };

  handlePressFile = ({ target }) => {
    var that = this;
    // console.log("upload file")
    // console.log(target)
    const file = target.files[0] ? target.files[0] : false;

    if (target.files.length === 0 || !target.files[0]) {
      return;
    } else {
      target.value = "";
      var format = /[!@#$%^&*()_+=\]{};':"\\|,<>?]/;
      if (format.test(file.name)) {
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "ไม่สามารถอัพโหลดไฟล์ที่ชื่อมีตัวอักษรพิเศษได้"
            : "File name with special character can not be uploaded.",
          { warning: true }
        );
        return;
      } else if (file.size > 10485760) {
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "ไม่สามารถอัพโหลดไฟล์ขนาดเกิน 10mb ได้"
            : "File with size more than 10mb can not be uploaded.",
          { warning: true }
        );
        return;
      }
    }

    // console.log(file);
    that.setState({ loadingScreen: true });

    const data = new FormData();
    data.append("csv", file);

    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    // console.log(this.state.uploadOldType)
    let pathUpload = `${config.API_importhousecsv}`;
    if (this.state.uploadOldType === 2) {
      pathUpload = `${config.API_importcondocsv}`;
    } else if (this.state.uploadOldType === 3) {
      pathUpload = `${config.API_importvillacsv}`;
    }

    var request = new Request(pathUpload, {
      method: "POST",
      headers: new Headers({
        token: tokenData,
      }),
      body: data,
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ loadingScreen: false });
        // event.target.files=null;
        if (responseJson.code === 200) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าสำเร็จ"
              : "Upload Old Project Completed",
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าสำเร็จ"
              : "Project Approval Completed.",
            { success: true }
          );
          window.location.reload();
        } else if (responseJson.code === 405) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            that.props.toggleLng === "TH"
              ? "รหัสของภาษาไม่ใช่ UTF 8"
              : "Data is not UTF 8.",
            { warning: true }
          );
        } else if (responseJson.code === 406) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "ไม่พบรหัสจังหวัดในแถวที่"
              : "Province ID Not Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else if (responseJson.code === 407) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "พบเลขที่แบบสอบถามซ้ำในแถวที่"
              : "Duplicated Project ID Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else if (responseJson.code === 408) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "ไม่พบรหัสจังหวัดของผู้ประกอบการในแถวที่"
              : "Company Province ID Not Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed.",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          { warning: true }
        );
        // event.target.files=null;
        // console.log('upload crash');
        // console.log(error);
        this.setState({ loadingScreen: false });
      });
  };
  handlePressFileSrp = ({ target }) => {
    var that = this;

    // console.log("upload file")
    // console.log(target)
    const file = target.files[0] ? target.files[0] : false;

    if (target.files.length === 0 || !target.files[0]) {
      return;
    } else {
      target.value = "";
      var format = /[!@#$%^&*()_+=\]{};':"\\|,<>?]/;
      if (format.test(file.name)) {
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "ไม่สามารถอัพโหลดไฟล์ที่ชื่อมีตัวอักษรพิเศษได้"
            : "File name with special character can not be uploaded.",
          { warning: true }
        );
        return;
      } else if (file.size > 10485760) {
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "ไม่สามารถอัพโหลดไฟล์ขนาดเกิน 10mb ได้"
            : "File with size more than 10mb can not be uploaded.",
          { warning: true }
        );
        return;
      }
    }

    // console.log(file);
    that.setState({ loadingScreen: true });

    const data = new FormData();
    data.append("csv", file);

    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    // console.log(this.state.uploadOldType)
    // let pathUpload = "importreicsrpcsv";

    var request = new Request(`${config.API_importreicsrpcsv}`, {
      method: "POST",
      headers: new Headers({
        token: tokenData,
      }),
      body: data,
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ loadingScreen: false });
        // event.target.files = null;
        if (responseJson.code === 200) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าสำเร็จ"
              : "Upload Old Project Completed",
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าสำเร็จ"
              : "Project Approval Completed.",
            { success: true }
          );
          window.location.reload();
        } else if (responseJson.code === 405) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            that.props.toggleLng === "TH"
              ? "รหัสของภาษาไม่ใช่ UTF 8"
              : "Data is not UTF 8.",
            { warning: true }
          );
        } else if (responseJson.code === 406) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "ไม่พบรหัสจังหวัดในแถวที่"
              : "Province ID Not Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else if (responseJson.code === 407) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "พบเลขที่แบบสอบถามซ้ำในแถวที่"
              : "Duplicated Project ID Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else if (responseJson.code === 408) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            (that.props.toggleLng === "TH"
              ? "ไม่พบรหัสจังหวัดของผู้ประกอบการในแถวที่"
              : "Company Province ID Not Found at row") +
              "\n " +
              (responseJson.data && responseJson.data.length > 0
                ? responseJson.data.join(", ")
                : "0"),
            { warning: true }
          );
        } else {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed",
            that.props.toggleLng === "TH"
              ? "อัพโหลดโครงการเก่าล้มเหลว"
              : "Upload Old Project Failed.",
            { warning: true }
          );
        }
        // event.target.files = null;
        // event.target = { value: '' };
        // that.oldPjoectFile = null;
      })
      .catch((error) => {
        // event=null;
        // event.target = { value: '' };
        // that.oldPjoectFile = null;
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          that.props.toggleLng === "TH"
            ? "อัพโหลดโครงการเก่าล้มเหลว"
            : "Upload Old Project Failed",
          { warning: true }
        );
        // event.target.files=null;
        // console.log('upload crash');
        // console.log(error);
        this.setState({ loadingScreen: false });
      });
  };
  checkProjectType = (type) => {
    // let self = this;
    if (type) {
      if (type.includes("eh")) {
        return (
          <EditProjectHome
            assetType="eh"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else if (type.includes("ec")) {
        return (
          <EditProjectHome
            assetType="ec"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else if (type.includes("ev")) {
        return (
          <EditProjectHome
            assetType="ev"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else {
        return null;
      }
    }
  };
  checkSurveyType = (type) => {
    // console.log(type)
    if (type === "household") {
      return <WizardHouse />;
    } else if (type === "condo") {
      return <WizardCondo />;
    } else if (type === "villa") {
      return <WizardVilla />;
    } else {
      return null;
    }
  };
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  getRegTime = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_getRegTime}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          if (
            responseJson.data &&
            responseJson.data[0] &&
            responseJson.data[0].reg_year
          )
            if (
              responseJson.data[0].reg_year === 2563 ||
              responseJson.data[0].reg_year === 2564
            ) {
              this.setState({
                currentSurveyYear: responseJson.data[0].reg_year,
                currentSurveyHalfYear: responseJson.data[0].reg_halfyear,
                currentSurveyQuarterYear: responseJson.data[0].reg_quarter,

                filterYear: responseJson.data[0].reg_year,
                filterPeriodYear: "H" + responseJson.data[0].reg_halfyear,
                selectedCurrent:
                  responseJson.data[0].reg_year +
                  "-H" +
                  responseJson.data[0].reg_halfyear +
                  " (ปัจจุบัน)",
                selectedPrevious:
                  responseJson.data[0].reg_year +
                  "-H" +
                  responseJson.data[0].reg_halfyear +
                  " (ปัจจุบัน)",
              });
            } else {
              this.setState({
                currentSurveyYear: responseJson.data[0].reg_year,
                currentSurveyHalfYear: responseJson.data[0].reg_halfyear,
                currentSurveyQuarterYear: responseJson.data[0].reg_quarter,

                filterYear: responseJson.data[0].reg_year,
                filterPeriodYear: "Q" + responseJson.data[0].reg_quarter,
                selectedCurrent:
                  responseJson.data[0].reg_year +
                  "-Q" +
                  responseJson.data[0].reg_quarter +
                  " (ปัจจุบัน)",
                selectedPrevious:
                  responseJson.data[0].reg_year +
                  "-Q" +
                  responseJson.data[0].reg_quarter +
                  " (ปัจจุบัน)",
              });
            }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getZone = () => {
    // console.log("getZone");
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_list_zone}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ zone: 1 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        this.setState({ zoneList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getSrp = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_countsrp}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ countapprovedsrp: responseJson.data[0].approved });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getDraftSrp = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_draftsrp}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ countdraftsrp: responseJson.data[0].count });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getPsurvey = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_countpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ countpsurvey: responseJson });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getDraftPsurvey = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_draftpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ draftpsurveyList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getListSrp = () => {
    // console.log('list psurvey')
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    // console.log(decryptedData)
    var request = new Request(`${config.API_adminsrpproject}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      // body: JSON.stringify(homedata_section2)
    });

    fetch(request)
      .then(function(response) {
        // console.log(response);
        response.json().then(function(data) {
          // console.log(data);
          // message = 'Login Success';

          // console.log(message);
          const srpListFull =
            "data" in data &&
            0 in data.data &&
            "jsonb_build_object" in data.data[0] &&
            "features" in data.data[0].jsonb_build_object
              ? data.data[0].jsonb_build_object.features
              : [];
          // console.log(srpListFull)
          const srpList = [];
          if (srpListFull) {
            srpListFull.forEach(function(i, j) {
              if ("properties" in i) {
                srpList.push(i.properties);
              }
            });
          }
          that.setState({ srpList, srpListFull });
          if (data.code === 200) {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey Completed",that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey  Completed",{success:true});
          } else {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
          }
          // if()
        });
      })
      .catch(function(err) {
        // console.log(err);
        // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
      });
  };

  getListPsurvey = () => {
    // console.log('list psurvey')
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_adminpsurveyproject}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      // body: JSON.stringify(homedata_section2)
    });

    // console.log('token')
    // console.log(tokenData)
    fetch(request)
      .then(function(response) {
        // console.log(response);
        response.json().then(function(data) {
          // console.log(data);
          // message = 'Login Success';

          // console.log(message);
          const psurveyListFull =
            "data" in data &&
            0 in data.data &&
            "jsonb_build_object" in data.data[0] &&
            "features" in data.data[0].jsonb_build_object
              ? data.data[0].jsonb_build_object.features
              : [];
          // console.log(srpListFull)
          const psurveyList = [];
          psurveyListFull.forEach(function(i, j) {
            if ("properties" in i) {
              // console.log(i.properties);
              psurveyList.push(i.properties);
            }
          });
          // console.log(psurveyList)
          // console.log("psurvey list", psurveyList);
          that.setState({ psurveyList });
          if (data.code === 200) {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey Completed",that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จ":"Submit Survey  Completed",{success:true});
          } else {
            // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
          }
          // if()
        });
      })
      .catch(function(err) {
        // console.log(err);
        // that.successAlert(that.state.language==='th' ?"ส่งแบบสอบถามสำเร็จล้มเหลว":"Submit Survey Failed",that.state.language==='th' ?"ส่งแบบสอบถามล้มเหลว":"Submit Survey Failed",{warning:true});
      });
  };
  // editPsurvey = () => {
  //   var that = this;
  //   const { key } = this.state;
  //   if (!key) {
  //     return;
  //   }
  //   const keyutf = CryptoJS.enc.Utf8.parse(key);
  //   const iv = CryptoJS.enc.Base64.parse(key);

  //   var aesStr = localStorage.getItem("session");
  //   const dec = CryptoJS.AES.decrypt(
  //     { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
  //     keyutf,
  //     {
  //       iv: iv,
  //     }
  //   );
  //   const decStr = CryptoJS.enc.Utf8.stringify(dec);
  //   const jsonDec = JSON.parse(decStr);
  //   const tokenData = jsonDec.token;
  //   // console.log(this.state.psurveyListFull)
  //   console.log(this.state)
  //   const info = {
  //     // form: this.state.form,
  //     // approved: parseInt(this.state.psurvey_approval),
  //     // projectid: this.state.condo_projectid,
  //     // refcode: this.state.condo_refcode,
  //     projectid:
  //       this.state.psurveyDataModal in this.state.psurveyList &&
  //       "projectid" in this.state.psurveyList[this.state.psurveyDataModal]
  //         ? this.state.psurveyList[this.state.psurveyDataModal].projectid
  //         : "0",
  //     approved: parseInt(this.state.psurvey_approval),
  //     form:
  //       this.state.psurveyDataModal in this.state.psurveyList &&
  //       "project_type" in this.state.psurveyList[this.state.psurveyDataModal]
  //         ? this.state.psurveyList[this.state.psurveyDataModal].project_type
  //         : "household",
  //   };
  //   if (info.approved === 2) {
  //     // info.rj_detail = this.state.psurveyRj_detail ? this.state.psurveyRj_detail : "";
  //     const rj_detail =
  //       this.reasonRef && this.reasonRef.value ? this.reasonRef.value : "";
  //     var noQuote = /^[^'"]*$/;
  //     var noNewline = /^[^\r\n]*$/;
  //     if (!(noQuote.test(rj_detail) && noNewline.test(rj_detail))) {
  //       return this.successAlert(
  //         "อนุมัติล้มเหลว",
  //         "ห้ามมี Quotation หรือ Enter ในเหตุผล",
  //         { status: "warning" }
  //       );
  //     }
  //     info.rj_detail = rj_detail;
  //   }

  //   // alert(JSON.stringify(info))
  //   // console.log(info)
  //   var request = new Request(`${config.API_approvedpsurvey}`, {
  //     method: "POST",
  //     headers: new Headers({
  //       "Content-Type": "application/json",
  //       token: tokenData,
  //     }),
  //     body: JSON.stringify(info),
  //   });

  //   fetch(request)
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       // console.log(responseJson)
  //       if (responseJson.code === 200) {
  //         // that.setState({psurveyDataModal:false})
  //         that.successAlert(
  //           that.props.toggleLng === "TH"
  //             ? "โครงการแก้ไขสำเร็จ"
  //             : "Project Approval Completed",
  //           that.props.toggleLng === "TH"
  //             ? "โครงการอนุมัติสำเร็จ"
  //             : "Project Approval Completed",
  //           { success: true }
  //         );

  //         // that.forceUpdate();
  //       } else {
  //         that.successAlert(
  //           that.props.toggleLng === "TH"
  //             ? "โครงการอนุมัติล้มเหลว"
  //             : "Project Approval Failed",
  //           that.props.toggleLng === "TH"
  //             ? "โครงการอนุมัติล้มเหลว"
  //             : "Project Approval Failed",
  //           { warning: true }
  //         );
  //       }
  //       that.getPsurvey();
  //       that.getDraftPsurvey();
  //       that.getListPsurvey();
  //       // this.setState({countsrp:responseJson.data})
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };
  editSrp = () => {
    // var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    this.hideAlert();
    let self = this;
    let data_section1 = this.props.houseInfoSection1;
    let data_section2 = this.props.houseInfoSection2;
    // console.log("all data ===");
    // console.log(data_section1);
    // console.log(data_section2);
    let all_data = Object.assign({}, data_section1, data_section2);
    // console.log(JSON.stringify(all_data));
    // let URL = API_editSRP;

    var request = new Request(config.API_editSRP, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(all_data),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // console.log("res..")
          // console.log(data);

          switch (data.code) {
            case 200:
              //success
              self.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => {
                      //window.location.replace("/project");
                      this.getListPsurvey();
                    }}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    เปลี่ยนแปลงข้อมูลสำเร็จ
                  </SweetAlert>
                ),
              });
              break;
            default:
              //failed
              self.setState({
                alert: (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    ไม่สามารถบันทึกข้อมูลได้ในขณะนี้ กรุณาตรวจสอบข้อมูลอีกครั้ง
                  </SweetAlert>
                ),
              });
              break;
          }
        });
      })
      .catch(function(err) {
        //console.log(err);
      });
  };
  submitPsurveyApproval = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    // console.log(this.state.psurveyListFull);
    // console.log(this.state);
    // console.log(this.state.form);
    // console.log(parseInt(this.state.psurvey_approval));
    // console.log(this.state.condo_projectid);
    // console.log(this.state.condo_refcode);

    const info = {
      // form: this.state.form,
      // approved: parseInt(this.state.psurvey_approval),
      // projectid: this.state.condo_projectid,
      // refcode: this.state.condo_refcode,
      projectid:
        this.state.psurveyDataModal in this.state.psurveyList &&
        "projectid" in this.state.psurveyList[this.state.psurveyDataModal]
          ? this.state.psurveyList[this.state.psurveyDataModal].projectid
          : this.state.condo_projectid,
      approved: parseInt(this.state.psurvey_approval),
      form:
        this.state.psurveyDataModal in this.state.psurveyList &&
        "project_type" in this.state.psurveyList[this.state.psurveyDataModal]
          ? this.state.psurveyList[this.state.psurveyDataModal].project_type
          : "household",
      refcode:
        this.state.psurveyDataModal in this.state.psurveyList &&
        "condo_refcode" in this.state.psurveyList[this.state.psurveyDataModal]
          ? this.state.psurveyList[this.state.psurveyDataModal].condo_refcode
          : this.state.condo_refcode,
    };
    if (info.approved === 2) {
      // info.rj_detail = this.state.psurveyRj_detail ? this.state.psurveyRj_detail : "";
      const rj_detail =
        this.reasonRef && this.reasonRef.value ? this.reasonRef.value : "";
      var noQuote = /^[^'"]*$/;
      var noNewline = /^[^\r\n]*$/;
      if (!(noQuote.test(rj_detail) && noNewline.test(rj_detail))) {
        return this.successAlert(
          "อนุมัติล้มเหลว",
          "ห้ามมี Quotation หรือ Enter ในเหตุผล",
          { status: "warning" }
        );
      }
      info.rj_detail = rj_detail;
    }

    // alert(JSON.stringify(info))
    // กำหนดสถานะโครงการ
    var request = new Request(`${config.API_approvedpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(info),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          // that.setState({psurveyDataModal:false})
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ส่งสถานะการอนุมัติสำเร็จ"
              : "Project Approval Completed",
            that.props.toggleLng === "TH"
              ? "โครงการอนุมัติสำเร็จ"
              : "Project Approval Completed",
            { success: true }
          );
          setTimeout(() => {
            that.setState({ psurveyDataModal: false, psurveyRj_detail: "" });
          }, 500);
          // that.forceUpdate();
        } else {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "่ส่งสถานะการอนุมัติล้มเหลว"
              : "Project Approval Failed",
            that.props.toggleLng === "TH"
              ? "โครงการอนุมัติล้มเหลว"
              : "Project Approval Failed",
            { warning: true }
          );
        }
        that.getPsurvey();
        that.getDraftPsurvey();
        that.getListPsurvey();
        // this.printProject(projectid, "noview");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  submitSrpApproval = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    const info = {
      srp_projectid:
        this.state.srpDataModal in this.state.srpList &&
        "srp_projectid" in this.state.srpList[this.state.srpDataModal]
          ? this.state.srpList[this.state.srpDataModal].srp_projectid
          : "0",
      approved: parseInt(this.state.srp_approval),
    };
    if (info.approved === 2) {
      const rj_detail =
        this.reasonRef && this.reasonRef.value ? this.reasonRef.value : "";
      var noQuote = /^[^'"]*$/;
      var noNewline = /^[^\r\n]*$/;
      if (!(noQuote.test(rj_detail) && noNewline.test(rj_detail))) {
        return this.successAlert(
          "อนุมัติล้มเหลว",
          "ห้ามมี Quotation หรือ Enter ในเหตุผล",
          { status: "warning" }
        );
      }
      info.rj_detail = rj_detail;
    }
    // console.log(info)
    var request = new Request(`${config.API_approvedsrp}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(info),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ส่งสถานะการอนุมัติสำเร็จ"
              : "Project Approval Completed",
            that.props.toggleLng === "TH"
              ? "โครงการอนุมัติสำเร็จ"
              : "Project Approval Completed",
            {
              success: true,
              //  onConfirm:()=>that.setState({srpDataModal:false})
            }
          );
          setTimeout(() => {
            that.setState({ srpDataModal: false });
          }, 500);
          // that.forceUpdate();
        } else {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ส่งสถานะการอนุมัติล้มเหลว"
              : "Project Approval Failed",
            that.props.toggleLng === "TH"
              ? "โครงการอนุมัติล้มเหลว"
              : "Project Approval Failed",
            { warning: true }
          );
        }
        that.getSrp();
        that.getDraftSrp();
        that.getListSrp();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  handleCheckedQuarter = (event) => {
    if (event.target.checked) {
      this.setState({ check_quarter: true });
    } else {
      this.setState({ check_quarter: false });
    }
  };

  handleCheckedHalf = (event) => {
    if (event.target.checked) {
      this.setState({ check_halfyear: true });
    } else {
      this.setState({ check_halfyear: false });
    }
  };

  showEmptyPsurveyData = (curForm) => {
    // console.log("showEmptyPsurveyData", curForm);
    this.props.surveyType(curForm);
    this.props.toggleSurvey(true);
    // this.setState({
    //   cardloaded: false,
    //   surveyloaded: ""
    // });
    this.setState({ form: curForm, psurveyDataModal: true });
  };
  showPsurveyData = (b) => {
    this.setState({ loadingScreen: true });
    var curForm =
      b in this.state.psurveyList && "project_type" in this.state.psurveyList[b]
        ? this.state.psurveyList[b].project_type
        : "household";
    this.props.surveyType(curForm);
    this.props.toggleSurvey(true);
    // this.setState({
    //   cardloaded: false,
    //   surveyloaded: "",
    // });

    this.setState({ form: curForm });
    // console.log(this.state.psurveyList)
    let dataStatus = "1";
    if (
      b in this.state.psurveyList &&
      "condo_approved" in this.state.psurveyList[b] &&
      "projectid" in this.state.psurveyList[b]
    ) {
      if (this.state.psurveyList[b].condo_approved === 1) {
        this.setState({ psurvey_approval: "1" });
      } else if (this.state.psurveyList[b].condo_approved === 2) {
        this.setState({ psurvey_approval: "2" });
        dataStatus = "2";
      } else if (this.state.psurveyList[b].condo_approved === 3) {
        this.setState({ psurvey_approval: "3" });
      } else if (this.state.psurveyList[b].condo_approved === 4) {
        this.setState({ psurvey_approval: "4" });
      } else if (this.state.psurveyList[b].condo_approved === 5) {
        this.setState({ psurvey_approval: "5" });
        dataStatus = "5";
      }

      // console.log(dataStatus);

      var that = this;
      const { key } = this.state;
      if (!key) {
        return;
      }
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);

      var aesStr = localStorage.getItem("session");
      const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
        keyutf,
        {
          iv: iv,
        }
      );
      const decStr = CryptoJS.enc.Utf8.stringify(dec);
      const jsonDec = JSON.parse(decStr);
      const tokenData = jsonDec.token;

      var form =
        b in this.state.psurveyList &&
        "project_type" in this.state.psurveyList[b]
          ? this.state.psurveyList[b].project_type
          : "house";

      let curProid =
        b in this.state.psurveyList && "projectid" in this.state.psurveyList[b]
          ? this.state.psurveyList[b].projectid
          : "0";

      // console.log(form);

      const info = {
        [form + "_projectid"]: curProid,
        [form + "_approved"]:
          b in this.state.psurveyList &&
          "condo_approved" in this.state.psurveyList[b]
            ? this.state.psurveyList[b]["condo_approved"]
            : "0",
      };
      // console.log(info)

      let form_call = ``;
      if (form === "household") {
        form_call = config.API_showdetail_projectid_household;
      } else if (form === "condo") {
        form_call = config.API_showdetail_projectid_condo;
      } else if (form === "villa") {
        form_call = config.API_showdetail_projectid_villa;
      }

      var request = new Request(form_call, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(info),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          that.setState({ loadingScreen: false });
          // console.log(responseJson);
          const body = {
            ...responseJson,
            status: dataStatus,
            view_psurvey: false,
          };

          if (responseJson.code === 200 && "data" in responseJson) {
            if (responseJson.data.length === 0) {
              //alert("No data in this project id");
              this.setState({
                popperOpen: false,
                old_projectidState: "error",
                old_projectid: "",
              });
            } else {
              // this.setState({
              //   household_oldprojid: curProid
              // });
              if (responseJson.data.length > 0) {
                // if(responseJson.data[0].lat===0&&responseJson.data[0].lon===0){
                //   throw 'e'
                // }
                if (
                  "rj_detail" in responseJson.data[0] &&
                  responseJson.data[0].rj_detail !== ""
                ) {
                  this.setState({
                    psurveyRj_detail: responseJson.data[0].rj_detail,
                  });
                }

                // if(responseJson.data.length>0 &&
                //   responseJson.data[0] instanceof Object &&
                //   "lat" in responseJson.data[0]&&
                //   "lon" in responseJson.data[0]
                //   ){
                //     let popLocation = {
                //       lat: parseFloat(responseJson.data[0].lat.toFixed(6)),
                //       lng: parseFloat(responseJson.data[0].lon.toFixed(6))
                //     };
                //     this.props.setLatlon(popLocation);
                //   }

                // that.setState({ view_psurvey: true });

                this.props.setProjectStatus(
                  responseJson.data[0][form + "_projectstatus"]
                );

                this.props.oldproject_data(responseJson.data[0]);
                this.props.setProjectid(curProid);

                this.props.project_datatable(body);
                this.props.surveyType(curForm);
                this.props.toggleSurvey(true);
                this.props.loading_datatable(false);
              } else {
                throw new Error();
              }
            }

            that.setState({ curPsurvey: responseJson });
            if ("bs" in responseJson) {
              that.setState({ bs: responseJson.bs });
            }
            if ("sellroom" in responseJson) {
              that.setState({ sellroom: responseJson.sellroom });
            }
            if ("st" in responseJson) {
              that.setState({ st: responseJson.st });
            }
            if ("cat" in responseJson) {
              that.setState({ cat: responseJson.cat });
            }
            if ("stc" in responseJson) {
              that.setState({ stc: responseJson.stc });
            }

            that.setState({ ...responseJson.data[0] }, () =>
              this.setState({ psurveyDataModal: b })
            );
          } else {
            that.successAlert(
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว"
                : "Show Project Detail Failed",
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
                : "Show Project Detail Failed",
              { warning: true }
            );
            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});
          }
        })
        .catch((error) => {
          that.setState({ loadingScreen: false });

          that.successAlert(
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            { warning: true }
          );
          // console.log(error);
        });
    }
  };

  viewPsurveyData = (b) => {
    // console.log(b);
    this.setState({ loadingScreen: true });
    var curForm =
      b in this.state.psurveyList && "project_type" in this.state.psurveyList[b]
        ? this.state.psurveyList[b].project_type
        : "household";
    // console.log(curForm);
    this.props.surveyType(curForm);
    this.props.toggleSurvey(true);
    this.setState({
      cardloaded: false,
      surveyloaded: "",
    });

    this.setState({ form: curForm });
    // console.log(this.state.psurveyList)
    let dataStatus = "1";
    if (
      b in this.state.psurveyList &&
      "condo_approved" in this.state.psurveyList[b] &&
      "projectid" in this.state.psurveyList[b]
    ) {
      if (this.state.psurveyList[b].condo_approved === 1) {
        this.setState({ psurvey_approval: "1" });
      } else if (this.state.psurveyList[b].condo_approved === 2) {
        this.setState({ psurvey_approval: "2" });
        dataStatus = "2";
      } else if (this.state.psurveyList[b].condo_approved === 3) {
        this.setState({ psurvey_approval: "3" });
      } else if (this.state.psurveyList[b].condo_approved === 4) {
        this.setState({ psurvey_approval: "4" });
      } else if (this.state.psurveyList[b].condo_approved === 5) {
        this.setState({ psurvey_approval: "5" });
        dataStatus = "5";
      }

      // console.log(this.state.psurvey_approval);

      var that = this;
      const { key } = this.state;
      if (!key) {
        return;
      }
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);

      var aesStr = localStorage.getItem("session");
      const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
        keyutf,
        {
          iv: iv,
        }
      );
      const decStr = CryptoJS.enc.Utf8.stringify(dec);
      const jsonDec = JSON.parse(decStr);
      const tokenData = jsonDec.token;

      var form =
        b in this.state.psurveyList &&
        "project_type" in this.state.psurveyList[b]
          ? this.state.psurveyList[b].project_type
          : "house";

      let curProid =
        b in this.state.psurveyList && "projectid" in this.state.psurveyList[b]
          ? this.state.psurveyList[b].projectid
          : "0";

      const info = {
        [form + "_projectid"]: curProid,
        [form + "_approved"]:
          b in this.state.psurveyList &&
          "condo_approved" in this.state.psurveyList[b]
            ? this.state.psurveyList[b]["condo_approved"]
            : "0",
      };
      // console.log(info);

      let form_call = ``;
      if (form === "household") {
        form_call = config.API_showdetail_projectid_household;
      } else if (form === "condo") {
        form_call = config.API_showdetail_projectid_condo;
      } else if (form === "villa") {
        form_call = config.API_showdetail_projectid_villa;
      }

      var request = new Request(form_call, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(info),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          that.setState({ loadingScreen: false });
          // console.log(responseJson);
          const body = {
            ...responseJson,
            status: dataStatus,
            view_psurvey: true,
          };

          if (responseJson.code === 200 && "data" in responseJson) {
            if (responseJson.data.length === 0) {
              //alert("No data in this project id");
              this.setState({
                popperOpen: false,
                old_projectidState: "error",
                old_projectid: "",
              });
            } else {
              // this.setState({
              //   household_oldprojid: curProid
              // });
              if (responseJson.data.length > 0) {
                // if(responseJson.data[0].lat===0&&responseJson.data[0].lon===0){
                //   throw 'e'
                // }
                if (
                  "rj_detail" in responseJson.data[0] &&
                  responseJson.data[0].rj_detail !== ""
                ) {
                  this.setState({
                    psurveyRj_detail: responseJson.data[0].rj_detail,
                  });
                }
                // if(responseJson.data.length>0 &&
                //   responseJson.data[0] instanceof Object &&
                //   "lat" in responseJson.data[0]&&
                //   "lon" in responseJson.data[0]
                //   ){
                //     let popLocation = {
                //       lat: parseFloat(responseJson.data[0].lat.toFixed(6)),
                //       lng: parseFloat(responseJson.data[0].lon.toFixed(6))
                //     };
                //     this.props.setLatlon(popLocation);
                //   }
                this.props.setProjectStatus(
                  responseJson.data[0][form + "_projectstatus"]
                );
                // this.props.oldproject_data(responseJson.data[0]);
                this.props.project_datatable(body);
                this.props.surveyType(curForm);
                this.props.toggleSurvey(true);
                this.props.loading_datatable(false);
                this.props.setProjectid(curProid);
              } else {
                throw new Error();
              }
            }

            that.setState({ curPsurvey: responseJson });
            if ("bs" in responseJson) {
              that.setState({ bs: responseJson.bs });
            }
            if ("sellroom" in responseJson) {
              that.setState({ sellroom: responseJson.sellroom });
            }
            if ("st" in responseJson) {
              that.setState({ st: responseJson.st });
            }
            if ("cat" in responseJson) {
              that.setState({ cat: responseJson.cat });
            }
            if ("stc" in responseJson) {
              that.setState({ stc: responseJson.stc });
            }

            that.setState({ ...responseJson.data[0] }, () =>
              this.setState({ psurveyDataModal: b })
            );
          } else {
            that.successAlert(
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว"
                : "Show Project Detail Failed",
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
                : "Show Project Detail Failed",
              { warning: true }
            );
            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});
          }
        })
        .catch((error) => {
          that.setState({ loadingScreen: false });

          that.successAlert(
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            { warning: true }
          );
          // console.log(error);
        });
    }
  };

  handleUploadFile = async (e) => {
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("csv", file);
    let response = await fetch(`${config.API_importhouseeditcsv}`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // console.log(response);

    if (response.status === "OK") {
      this.setState({ ...this.state, showTable: true });
    }
  };

  handleUploadHouse = async (e) => {
    this.setState({ loadingScreen: true });
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("csv", file);
    const deleteData = async () => {
      await fetch(`${config.API_deletehousetemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(deletedata.data);
    };
    deleteData();
    let response = await fetch(`${config.API_importhousetemp}`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // console.log(response);

    if (response.status === "OK" && response.errors.length !== 0) {
      this.setState({
        ...this.state,
        showHouseErrorDetails: true,
        errorData: response.errors,
      });
      this.setState({ loadingScreen: false });
    }

    if (response.status === "OK" && response.errors.length === 0) {
      this.setState({ ...this.state, showHouse: true });
      this.setState({ loadingScreen: false });
    }
  };

  handleUploadVilla = async (e) => {
    this.setState({ loadingScreen: true });
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("csv", file);
    const deleteData = async () => {
      await fetch(`${config.API_deletevillatemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(deletedata.data);
    };
    deleteData();
    let response = await fetch(`${config.API_importvillatemp}`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // console.log(response);

    if (response.status === "OK" && response.errors.length !== 0) {
      this.setState({
        ...this.state,
        showVillaErrorDetails: true,
        errorData: response.errors,
      });
      this.setState({ loadingScreen: false });
    }

    if (response.status === "OK" && response.errors.length === 0) {
      this.setState({ ...this.state, showVilla: true });
      this.setState({ loadingScreen: false });
    }
  };

  handleUploadCondo = async (e) => {
    this.setState({ loadingScreen: true });
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("csv", file);
    const deleteData = async () => {
      await fetch(`${config.API_deletecondotemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(deletedata.data);
    };
    deleteData();
    let response = await fetch(`${config.API_importcondotemp}`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // console.log(response);

    if (response.status === "OK" && response.errors.length !== 0) {
      this.setState({
        ...this.state,
        showCondoErrorDetails: true,
        errorData: response.errors,
      });
      this.setState({ loadingScreen: false });
    }

    if (response.status === "OK" && response.errors.length === 0) {
      this.setState({ ...this.state, showCondo: true });
      this.setState({ loadingScreen: false });
    }
  };

  handleDateChange = (date) => {
    // console.log(date)
    this.setState({ selectedDate: date });
    // console.log(date.format("YYYY/MM/DD"))
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    const info = {
      date: date.format("YYYY/MM/DD"),
    };
    // console.log(info)
    var request = new Request(`${config.API_setregtime}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(info),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("responseJson")
        // console.log(responseJson)
        that.setState({ loadingScreen: false });

        if (responseJson.code === 200) {
          // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",{success:true});
          // that.forceUpdate();
          that.getRegLog();
          that.getRegTime();
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการสำเร็จ"
              : "Set Period Time Success",
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการสำเร็จ"
              : "Set Period Time Success",
            { success: true }
          );
        } else {
          // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});

          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
              : "Set Period Time Failed",
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
              : "Set Period Time Failed",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        that.setState({ loadingScreen: false });
        that.successAlert(
          that.props.toggleLng === "TH"
            ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
            : "Set Period Time Failed",
          that.props.toggleLng === "TH"
            ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
            : "Set Period Time Failed",
          { warning: true }
        );
        // console.log(error);
      });
  };
  handlePeriodChange = (year, quarter, check_halfyear, check_quarter) => {
    if (
      year === 0 ||
      quarter === 0 ||
      (check_halfyear === false && check_quarter === false)
    ) {
      return;
    } else {
      // console.log(date)
      this.setState({ selectedYear: year });
      // this.setState({ selectedHalf: half });
      this.setState({ selectedQuarter: quarter });
      // console.log(date.format("YYYY/MM/DD"))
      var that = this;
      const { key } = this.state;
      if (!key) {
        return;
      }
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);
      var aesStr = localStorage.getItem("session");
      const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
        keyutf,
        {
          iv: iv,
        }
      );
      const decStr = CryptoJS.enc.Utf8.stringify(dec);
      const jsonDec = JSON.parse(decStr);
      const tokenData = jsonDec.token;
      const info = {
        year: year,
        quarter: quarter,
        check_halfyear: check_halfyear,
        check_quarter: check_quarter,
      };
      // console.log(info)
      var request = new Request(`${config.API_setregtime}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(info),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("responseJson")
          // console.log(responseJson)
          that.setState({ loadingScreen: false });

          if (responseJson.code === 200) {
            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",{success:true});
            // that.forceUpdate();
            that.getRegLog();
            that.getRegTime();
            that.successAlert(
              that.props.toggleLng === "TH"
                ? "ตั้งช่วงสำรวจโครงการสำเร็จ"
                : "Set Period Time Success",
              that.props.toggleLng === "TH"
                ? "ตั้งช่วงสำรวจโครงการสำเร็จ"
                : "Set Period Time Success",
              { success: true }
            );
          } else {
            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});

            that.successAlert(
              that.props.toggleLng === "TH"
                ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
                : "Set Period Time Failed",
              that.props.toggleLng === "TH"
                ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
                : "Set Period Time Failed",
              { warning: true }
            );
          }
        })
        .catch((error) => {
          that.setState({ loadingScreen: false });
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
              : "Set Period Time Failed",
            that.props.toggleLng === "TH"
              ? "ตั้งช่วงสำรวจโครงการล้มเหลว"
              : "Set Period Time Failed",
            { warning: true }
          );
          // console.log(error);
        });
    }
  };
  showSrpData = (b, proid) => {
    this.setState({ loadingScreen: true });
    // console.log(b)
    // console.log(this.state.srpList)
    let obj = this.state.srpList.find((o) => o.srp_projectid === proid);
    // console.log(obj);
    let projectID = obj.srp_projectid;
    let approved = obj.srp_approved;
    // console.log("set"+projectID)
    this.props.setProjectIDHome(projectID);
    this.props.setApproved(approved);
    let modaltype = "";
    // if (obj.type === 1) {
    if (obj.srp_cat === 1) {
      // Condo
      modaltype += "ec_" + new Date().getTime();
    } else if (obj.srp_cat === 2) {
      // Home
      modaltype += "eh_" + new Date().getTime();
    } else {
      // Villa
      modaltype += "ev_" + new Date().getTime();
    }
    this.props.setModalType(modaltype);

    if (
      b in this.state.srpList &&
      "srp_approved" in this.state.srpList[b] &&
      "srp_projectid" in this.state.srpList[b]
    ) {
      if (this.state.srpList[b].srp_approved === 1) {
        this.setState({ srp_approval: "1" });
      } else if (this.state.srpList[b].srp_approved === 2) {
        this.setState({ srp_approval: "2" });
      }

      var that = this;
      const { key } = this.state;
      if (!key) {
        return;
      }
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);

      var aesStr = localStorage.getItem("session");
      const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
        keyutf,
        {
          iv: iv,
        }
      );
      const decStr = CryptoJS.enc.Utf8.stringify(dec);
      const jsonDec = JSON.parse(decStr);
      const tokenData = jsonDec.token;

      const info = {
        srp_projectid:
          b in this.state.srpList && "srp_projectid" in this.state.srpList[b]
            ? this.state.srpList[b].srp_projectid
            : "0",
      };
      // console.log(info)
      var request = new Request(`${config.API_get_project_detail}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(info),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("responseJson")
          // console.log(responseJson)
          that.setState({ loadingScreen: false });

          if (responseJson.code === 200 && responseJson.datapart1) {
            // console.log(responseJson);
            that.setState({ curSrp: responseJson }, () =>
              that.setState({ srpDataModal: b })
            );

            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",that.props.toggleLng==='TH' ?"โครงการอนุมัติสำเร็จ":"Project Approval Completed",{success:true});
            // that.forceUpdate();
          } else {
            // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});

            that.successAlert(
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว"
                : "Show Project Detail Failed",
              that.props.toggleLng === "TH"
                ? "แสดงข้อมูลโครงการล้มเหลว หรือข้้อมูลว่างเปล่า"
                : "Show Project Detail Failed",
              { warning: true }
            );
          }
        })
        .catch((error) => {
          that.setState({ loadingScreen: false });
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            { warning: true }
          );
          // console.log(error);
        });
    }
  };

  showTemplate = (b) => {
    // console.log('download tem')
    this.setState({ templateModal: b });
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  handle_psurveyApproval = (event) => {
    // console.log(this.state.psurvey_approval);
    this.setState({ psurvey_approval: event.target.value });
  };
  resetSurvey() {
    this.props.toggleSurvey(false);
    this.props.surveyType("");
    this.props.project_datatable(undefined);
    this.props.setProjectid(undefined);
    this.props.setLatlon({
      lat: 0,
      lng: 0,
    });
    this.getListPsurvey();
    // window.location.replace("/project");
    // setTimeout(() => {
    //   window.location.replace("/admin/survey");
    // }, 500);
  }
  handle_srpApproval = (event) => {
    this.setState({ srp_approval: event.target.value });
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
          //confirmBtnCssClass={}
        >
          {message}
        </SweetAlert>
      ),
    });
  }
  passQCAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
        >
          <b style={{ color: "#008b29", fontSize: "130%", fontWeight: 900 }}>
            {message}
          </b>
        </SweetAlert>
      ),
    });
  }
  rejectQCAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
        >
          <b style={{ color: "#cc2c24", fontSize: "130%", fontWeight: 900 }}>
            {message}
          </b>
        </SweetAlert>
      ),
    });
  }
  togglePasswordVisiblity = () => {
    // console.log(this.state.passwordShown);
    return this.setState({
      passwordShown: !this.state.passwordShown,
      alert: this.state.alert,
    });
  };

  handleCloseHouse = (name) => {
    this.setState({ ...this.state, [name]: false });
  };
  handleCloseVilla = (name) => {
    this.setState({ ...this.state, [name]: false });
  };
  handleCloseCondo = (name) => {
    this.setState({ ...this.state, [name]: false });
  };
  handleUpdateHouse = (name) => {
    this.handleCloseHouse(name);
    this.setState({ loadingScreen: true });
    // console.log("update");
    const updateData = async () => {
      await fetch(`${config.API_updatehousetemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(updatedata.data);
      this.setState({ loadingScreen: false });
      // window.location.replace("/project");
    };
    updateData();
  };
  handleUpdateVilla = (name) => {
    this.handleCloseVilla(name);
    this.setState({ loadingScreen: true });
    // console.log("update");
    const updateData = async () => {
      await fetch(`${config.API_updatevillatemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(updatedata.data);
      this.setState({ loadingScreen: false });
      // window.location.replace("/project");
    };
    updateData();
  };
  handleUpdateCondo = (name) => {
    this.handleCloseCondo(name);
    this.setState({ loadingScreen: true });
    // console.log("update");
    const updateData = async () => {
      await fetch(`${config.API_updatecondotemp}`, {
        method: "POST",
      }).then((res) => res.json());
      // console.log(updatedata.data);
      this.setState({ loadingScreen: false });
      // window.location.replace("/project");
    };
    updateData();
  };

  // toggleSelection = (key, shift, row) => {
  //   /*
  //     Implementation of how to manage the selection state is up to the developer.
  //     This implementation uses an array stored in the component state.
  //     Other implementations could use object keys, a Javascript Set, or Redux... etc.
  //   */
  //   // start off with the existing state
  //   if (this.state.selectType === "checkbox") {
  //     let selection = [];
  //     if (selection.indexOf(key) < 0) selection.push(key);
  //     this.setState({ selection });
  //   } else {
  //     let selection = [...this.state.selection];
  //     const keyIndex = selection.indexOf(key);
  //     // check to see if the key exists
  //     if (keyIndex >= 0) {
  //       // it does exist so we will remove it using destructing
  //       selection = [
  //         ...selection.slice(0, keyIndex),
  //         ...selection.slice(keyIndex + 1),
  //       ];
  //     } else {
  //       // it does not exist so add it
  //       selection.push(key);
  //     }
  //     // update the state
  //     this.setState({ selection });
  //   }
  // };
  // toggleAll = () => {
  //   /*
  //     'toggleAll' is a tricky concept with any filterable table
  //     do you just select ALL the records that are in your data?
  //     OR
  //     do you only select ALL the records that are in the current filtered data?

  //     The latter makes more sense because 'selection' is a visual thing for the user.
  //     This is especially true if you are going to implement a set of external functions
  //     that act on the selected information (you would not want to DELETE the wrong thing!).

  //     So, to that end, access to the internals of ReactTable are required to get what is
  //     currently visible in the table (either on the current page or any other page).

  //     The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
  //     ReactTable and then get the internal state and the 'sortedData'.
  //     That can then be iterrated to get all the currently visible records and set
  //     the selection state.
  //   */
  //   const selectAll = this.state.selectAll ? false : true;
  //   const selection = [];
  //   if (selectAll) {
  //     // we need to get at the internals of ReactTable
  //     const wrappedInstance = this.selectTable.getWrappedInstance();
  //     // the 'sortedData' property contains the currently accessible records based on the filter and sort
  //     const currentRecords = wrappedInstance.getResolvedState().sortedData;
  //     // we just push all the IDs onto the selection array
  //     currentRecords.forEach((item) => {
  //       if (item._original) {
  //         selection.push(item._original._id);
  //       }
  //     });
  //   }
  //   this.setState({ selectAll, selection });
  // };
  // isSelected = (key) => {
  //   console.log(key);
  //   /*
  //     Instead of passing our external selection state we provide an 'isSelected'
  //     callback and detect the selection state ourselves. This allows any implementation
  //     for selection (either an array, object keys, or even a Javascript Set object).
  //   */
  //   return this.state.selection.includes(key);
  // };
  // logSelection = () => {
  //   console.log("selection:", this.state.selection);
  // };
  // toggleType = () => {
  //   this.setState({
  //     selectType: this.state.selectType === "radio" ? "checkbox" : "radio",
  //     selection: [],
  //     selectAll: false,
  //   });
  // };

  render() {
    // const {
    //   toggleSelection,
    //   toggleAll,
    //   isSelected,
    //   logSelection,
    //   toggleType,
    // } = this;
    // const { data, columns, selectAll, selectType } = this.state;
    // const extraProps = {
    //   selectAll,
    //   isSelected,
    //   toggleAll,
    //   toggleSelection,
    //   selectType,
    // };
    if (this.state.showHouseErrorDetails === true) {
      return (
        <HouseImport
          open={this.state.showHouseErrorDetails}
          handleClose={() => this.handleCloseHouse("showHouseErrorDetails")}
          handleUpdate={() => this.handleUpdateHouse("showHouseErrorDetails")}
        >
          <HouseErrorView data={this.state.errorData}></HouseErrorView>
        </HouseImport>
      );
    } else if (this.state.showHouse === true) {
      return (
        <HouseImport
          open={this.state.showHouse}
          handleClose={() => this.handleCloseHouse("showHouse")}
          handleUpdate={() => this.handleUpdateHouse("showHouse")}
        >
          <HouseImportView></HouseImportView>
        </HouseImport>
      );
    } else if (this.state.showVillaErrorDetails === true) {
      return (
        <VillaImport
          open={this.state.showVillaErrorDetails}
          handleClose={() => this.handleCloseVilla("showVillaErrorDetails")}
          handleUpdate={() => this.handleUpdateVilla("showVillaErrorDetails")}
        >
          <VillaErrorView data={this.state.errorData}></VillaErrorView>
        </VillaImport>
      );
    } else if (this.state.showVilla === true) {
      return (
        <VillaImport
          open={this.state.showVilla}
          handleClose={() => this.handleCloseVilla("showVilla")}
          handleUpdate={() => this.handleUpdateVilla("showVilla")}
        >
          <VillaImportView></VillaImportView>
        </VillaImport>
      );
    } else if (this.state.showCondoErrorDetails === true) {
      return (
        <CondoImport
          open={this.state.showCondoErrorDetails}
          handleClose={() => this.handleCloseCondo("showCondoErrorDetails")}
          handleUpdate={() => this.handleUpdateCondo("showCondoErrorDetails")}
        >
          <CondoErrorView data={this.state.errorData}></CondoErrorView>
        </CondoImport>
      );
    } else if (this.state.showCondo === true) {
      return (
        <CondoImport
          open={this.state.showCondo}
          handleClose={() => this.handleCloseCondo("showCondo")}
          handleUpdate={() => this.handleUpdateCondo("showCondo")}
        >
          <CondoImportView></CondoImportView>
        </CondoImport>
      );
    }
    const { generatingPDFAll, generatingPDFinished } = this.state;
    var that = this;
    const { classes, toggleLng } = this.props;

    const {
      // countsrp,
      countdraftsrp,
      // countpsurvey,
      // countdraftpsurvey,
      countapprovedsrp,
    } = this.state;
    // console.log(countsrp)

    const draftSrp = parseInt(countdraftsrp);
    const approvedSrp = parseInt(countapprovedsrp);

    const srpColumns = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col0,
        accessor: "srp_projectid", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col1,
        accessor: "srp_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col2,
        accessor: "srp_phase", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col3,
        accessor: "guild_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col5,
        accessor: "province_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col6,
        accessor: "amphoe_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col7,
        accessor: "tambon_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col8,
        accessor: "srp_selldate", // String-based value accessors!
        filterable: false,
        Cell: (val) => <div>{this.dateParse(val.row["srp_selldate"])}</div>,
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col12,
        accessor: "srp_modifined", // String-based value accessors!
        filterable: false,
        Cell: (val) => <div>{this.dateParse(val.row["srp_modifined"])}</div>,
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col9,
        accessor: "cstats_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col11,
        accessor: "buttonFormatter",
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
      },
    ];
    // console.log(this.state.srpList)
    const srpList = this.state.srpList ? this.state.srpList : [];
    srpList.map(function(a, b) {
      a.buttonFormatter = (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            justIcon
            round
            color="info"
            simple
            onClick={() => that.showSrpData(b, a.srp_projectid)}
          >
            <Tooltip title="แก้ไข">
              <Edit />
            </Tooltip>
          </Button>
          <Button
            justIcon
            round
            color="info"
            simple
            onClick={() =>
              that.successAlert(
                toggleLng === "TH" ? "ยืนยันลบโครงการ" : "Confirm Delete SRP",

                <div>
                  {toggleLng === "TH"
                    ? "กรอกรหัสผ่านอีกครั้งเพื่อยืนยันการลบ"
                    : "Confirm with Password"}
                  {/* {that.state.passwordShown?'true':'false'}
                  {` `}<a onClick={that.togglePasswordVisiblity}>{eye}</a> */}
                  <div className="pass-wrapper">
                    <CustomInput
                      inputProps={{
                        type: "password",
                        //value:that.state.srpDeletePassword,
                        //onChange:(e)=>that.setState({srpDeletePassword:e.target.value})
                      }}
                      id="myInput"
                    ></CustomInput>
                    <i
                      onClick={() => {
                        var x = document.getElementById("myInput");
                        if (x.type === "password") {
                          x.type = "text";
                        } else {
                          x.type = "password";
                        }
                      }}
                    >
                      {eye}
                    </i>
                  </div>
                </div>,
                {
                  style: { display: "block", marginTop: "-100px" },
                  placeHolder: "กรอกรหัสผ่านอีกครั้งเพื่อยืนยันการลบ",
                  // input:true,
                  // inputType:"password",
                  // inputProps:{id:"myInput"},
                  onConfirm: () => {
                    //document.getElementById("myInput")&&"value" in document.getElementById("myInput")?document.getElementById("myInput").value:""
                    let password = document.getElementById("myInput").value;
                    return that.setState({ alert: null }, () =>
                      that.deleteSRP(b, password)
                    );
                  },
                  confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout
                    .conBtTxt,
                  cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout
                    .canBtTxt,
                  showCancel: true,
                }
              )
            }
          >
            <Tooltip title="ลบ">
              <Delete />
            </Tooltip>
          </Button>
        </div>
      );
      return a;
    });

    const psurveyList = this.state.psurveyList ? this.state.psurveyList : [];

    var draftpsurveyList = this.state.draftpsurveyList
      ? this.state.draftpsurveyList
      : [];
    // console.log("Definition", this.state.draftpsurveyList)
    // console.log(this.state.showEditPsurvey);
    psurveyList.map(function(a, b) {
      // console.log(that && that.state.checkBoxQC.includes(b));
      // console.log(that && that.state.checkBoxQC, b);
      // console.log(that.state.remark);
      // let remarkValue = that.state.remark.filter((item, index) => {
      //   return index === b ? true : false;
      // });

      // a.qc = (
      //   <div style={{ display: "flex", flexDirection: "row" }}>
      //     <Checkbox
      //       id={a.id}
      //       key={a.id}
      //       defaultChecked={that.state.checkBoxQC.includes(a.id)}
      //       onChange={(e) => {
      //         let newCheckBoxList = [...that.state.checkBoxQC];
      //         if (e.target.checked) {
      //           newCheckBoxList.push(a.id);
      //           that.setState({ checkBoxQC: newCheckBoxList });
      //         } else {
      //           newCheckBoxList = newCheckBoxList.filter(
      //             (item) => item !== a.id
      //           );
      //           that.setState({ checkBoxQC: newCheckBoxList });
      //         }
      //         // console.log(e.target.checked, a.id, that.state.checkBoxQC);
      //       }}
      //     ></Checkbox>
      //   </div>
      // );
      // a.remark = (
      //   <div style={{ display: "flex", flexDirection: "row" }}>
      //     <CustomInput
      //       id={a.id}
      //       key="test"
      //       inputProps={{
      //         type: "text",
      //         // value: remarkValue.length === 0 ? "" : remarkValue[0][b],
      //         onChange: (e) => {
      //           let newRemark = [...that.state.remark];
      //           if (newRemark.length === 0) {
      //             let allRemark = [];
      //             newRemark.map((item) => {
      //               allRemark.push(Object.keys(item)[0]);
      //             });
      //             if (allRemark.includes(b)) {
      //               newRemark.map((item, index) => {
      //                 if (index === b) {
      //                   return { [index]: e.target.value };
      //                 }
      //                 return item;
      //               });
      //             } else {
      //               newRemark.push({ [b]: e.target.value });
      //             }
      //           }
      //           that.setState((state) => ({
      //             ...state,
      //             remark: newRemark,
      //           }));
      //         },
      //       }}
      //     ></CustomInput>
      //   </div>
      // );
      a.check = (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            justIcon
            round
            color="success"
            simple
            onClick={() => {
              that.handleQCProjectPass(
                a.projectid,
                a.condo_refcode,
                a.project_type
              );
            }}
          >
            <Tooltip title="ผ่าน">
              <CheckCircleIcon />
            </Tooltip>
          </Button>
          <Button
            justIcon
            round
            color="danger"
            simple
            onClick={() => {
              that.handleQCProjectReject(
                a.projectid,
                a.condo_refcode,
                a.project_type
              );
            }}
          >
            <Tooltip title="ไม่ผ่าน">
              <CancelIcon />
            </Tooltip>
          </Button>
        </div>
      );
      return a;
    });

    if (this.state.showEditPsurvey === true) {
      // console.log("A");
      psurveyList.map(function(a, b) {
        // console.log(that && that.state.checkBoxQC.includes(b));
        // console.log(that && that.state.checkBoxQC, b);
        // a.qc = (
        //   <div style={{ display: "flex", flexDirection: "row" }}>
        //     <Checkbox
        //       id={b}
        //       key={b}
        //       // checked={that.state.checkBoxQC.includes(b)}
        //       onChange={(e) => {
        //         let newCheckBoxList = [...that.state.checkBoxQC];
        //         if (e.target.checked) {
        //           newCheckBoxList.push(b);
        //           that.setState({ checkBoxQC: newCheckBoxList });
        //         } else {
        //           newCheckBoxList = newCheckBoxList.filter(
        //             (item) => item !== b
        //           );
        //           that.setState({ checkBoxQC: newCheckBoxList });
        //         }
        //         console.log(e.target.checked, a.id, b, that.state.checkBoxQC);
        //       }}
        //     ></Checkbox>
        //   </div>
        // );
        a.buttonFormatter = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => {
                // save selected index
                that.viewPsurveyData(b);
              }}
            >
              <Tooltip title="ดูข้อมูล">
                <Assignment />
              </Tooltip>
            </Button>
            <Button
              justIcon
              round
              color="warning"
              simple
              onClick={() => {
                // savee selected index
                that.showPsurveyData(b);
              }}
            >
              <Tooltip title="แก้ไขข้อมูล">
                <Edit />
              </Tooltip>
            </Button>

            {/* {a.condo_approved===1? */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                that.printProject(b, psurveyList, "view");
              }}
              color="success"
              // color={this.colorStatusEdit(prop[8])}
              // disabled={this.isDisabled(prop[8])}
              className="like"
            >
              <Tooltip title="พิมพ์ไฟล์ PDF">
                <PrintIcon />
              </Tooltip>
            </Button>
            <Button
              justIcon
              round
              color="danger"
              simple
              onClick={() =>
                that.successAlert(
                  toggleLng === "TH"
                    ? "ยืนยันลบแบบสอบถาม"
                    : "Confirm Delete Psurvey",
                  <div>
                    {toggleLng === "TH"
                      ? "กรอกรหัสผ่านอีกครั้งเพื่อยืนยันการลบ"
                      : "Confirm with Password"}
                    <div className="pass-wrapper">
                      <CustomInput
                        inputProps={{
                          type: "password",
                        }}
                        id="myInput"
                      ></CustomInput>
                      <i
                        onClick={() => {
                          var x = document.getElementById("myInput");
                          if (x.type === "password") {
                            x.type = "text";
                          } else {
                            x.type = "password";
                          }
                        }}
                      >
                        {eye}
                      </i>
                    </div>
                  </div>,
                  {
                    style: { display: "block", marginTop: "-100px" },
                    onConfirm: () => {
                      let password = document.getElementById("myInput").value;
                      return that.setState({ alert: null }, () =>
                        that.deletePsurvey(b, password)
                      );
                    },
                    confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout
                      .conBtTxt,
                    cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout
                      .canBtTxt,
                    showCancel: true,
                  }
                )
              }
            >
              <Tooltip title="ลบ">
                <Delete />
              </Tooltip>
            </Button>
          </div>
        );
        a.oldbuttonFormatter = (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => {
                // savee selected index
                that.viewPsurveyData(b);
              }}
            >
              <Tooltip title="ดูข้อมูล">
                <Assignment />
              </Tooltip>
            </Button>
            {/* {a.condo_approved===1? */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                that.printProject(b, psurveyList, "view");
              }}
              color="success"
              // color={this.colorStatusEdit(prop[8])}
              // disabled={this.isDisabled(prop[8])}
              className="like"
            >
              <Tooltip title="พิมพ์ไฟล์ PDF">
                <PrintIcon />
              </Tooltip>
            </Button>
          </div>
        );
        a["approveFormatter"] = that.formatApproval(a.condo_approved);

        return a;
      });
    } else {
      psurveyList.map(function(a, b) {
        // console.log("B");
        a.buttonFormatter = (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => {
                // savee selected index
                that.viewPsurveyData(b);
              }}
            >
              <Tooltip title="ดูข้อมูล">
                <Assignment />
              </Tooltip>
            </Button>
            <Button
              justIcon
              round
              color="warning"
              simple
              onClick={() => {
                // savee selected index
                that.showPsurveyData(b);
              }}
            >
              <Tooltip title="แก้ไขข้อมูล">
                <Edit />
              </Tooltip>
            </Button>
            {/* {a.condo_approved===1? */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                that.printProject(b, psurveyList, "view");
              }}
              color="success"
              // color={this.colorStatusEdit(prop[8])}
              // disabled={this.isDisabled(prop[8])}
              className="like"
            >
              <Tooltip title="พิมพ์ไฟล์ PDF">
                <PrintIcon />
              </Tooltip>
            </Button>
            <Button
              justIcon
              round
              color="danger"
              simple
              onClick={() =>
                that.successAlert(
                  toggleLng === "TH"
                    ? "ยืนยันลบแบบสอบถาม"
                    : "Confirm Delete Psurvey",
                  <div>
                    {toggleLng === "TH"
                      ? "กรอกรหัสผ่านอีกครั้งเพื่อยืนยันการลบ"
                      : "Confirm with Password"}
                    <div className="pass-wrapper">
                      <CustomInput
                        inputProps={{
                          type: "password",
                        }}
                        id="myInput"
                      ></CustomInput>
                      <i
                        onClick={() => {
                          var x = document.getElementById("myInput");
                          if (x.type === "password") {
                            x.type = "text";
                          } else {
                            x.type = "password";
                          }
                        }}
                      >
                        {eye}
                      </i>
                    </div>
                  </div>,
                  {
                    style: { display: "block", marginTop: "-100px" },
                    onConfirm: () => {
                      let password = document.getElementById("myInput").value;
                      return that.setState({ alert: null }, () =>
                        that.deletePsurvey(b, password)
                      );
                    },
                    confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout
                      .conBtTxt,
                    cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout
                      .canBtTxt,
                    showCancel: true,
                  }
                )
              }
            >
              <Tooltip title="ลบ">
                <Delete />
              </Tooltip>
            </Button>
          </div>
        );
        a.oldbuttonFormatter = (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => {
                // savee selected index
                that.viewPsurveyData(b);
              }}
            >
              <Tooltip title="ดูข้อมูล">
                <Assignment />
              </Tooltip>
            </Button>
            {/* {a.condo_approved===1? */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                that.printProject(b, psurveyList, "view");
              }}
              color="success"
              // color={this.colorStatusEdit(prop[8])}
              // disabled={this.isDisabled(prop[8])}
              className="like"
            >
              <Tooltip title="พิมพ์ไฟล์ PDF">
                <PrintIcon />
              </Tooltip>
            </Button>
          </div>
        );
        a["approveFormatter"] = that.formatApproval(a.condo_approved);

        return a;
      });
    }

    var psurveyOldList = psurveyList
      ? psurveyList.filter((a) => a.projectid && a.condo_approved === 5)
      : [];
    if (psurveyOldList.length > 0) {
      if (this.state.filterTypeOld !== 0) {
        psurveyOldList = psurveyOldList.filter(
          (a) => a.project_type === that.state.filterTypeOld
        );
      }
      if (
        this.state.filterYearOld !== "0" &&
        this.state.filterPeriodYearOld !== "0"
      ) {
        psurveyOldList = psurveyOldList.filter(
          (a) =>
            a.projectid.substring(0, 4) ===
            this.state.filterYearOld.substring(2) +
              this.state.filterPeriodYearOld
        );
      } else {
        psurveyOldList = psurveyList
          ? psurveyList.filter((a) => a.projectid && a.condo_approved === 5)
          : [];
      }
    }

    var psurveyNewList = psurveyList
      ? psurveyList.filter((a) => a.projectid && a.condo_approved !== 5)
      : [];
    if (psurveyNewList.length > 0) {
      if (this.state.filterCompany !== 0) {
        let thisOrgName = that.state.orgList.filter(
          (a) => a.org_id === that.state.filterCompany
        );
        if (thisOrgName.length > 0) {
          psurveyNewList = psurveyNewList.filter(
            (a) => a.org_name === thisOrgName[0].org_name
          );
        } else {
          psurveyNewList = [];
        }
      }
      if (this.state.filterType !== 0) {
        psurveyNewList = psurveyNewList.filter(
          (a) => a.project_type === that.state.filterType
        );
      }
      if (this.state.filterYear !== 0) {
        psurveyNewList = psurveyNewList.filter(
          (a) =>
            a.projectid.substring(0, 2) ===
            (that.state.filterYear + "").substring(2)
        );
      }
      if (this.state.filterPeriodYear !== 0) {
        psurveyNewList = psurveyNewList.filter((a) => {
          return (
            a.projectid.substring(2, 4) === that.state.filterPeriodYear + ""
          );
        });
      }
    }

    if (draftpsurveyList.length > 0) {
      if (this.state.filterYear !== 0) {
        draftpsurveyList = draftpsurveyList.filter(
          (a) =>
            a.projectid.substring(0, 2) ===
            (that.state.filterYear + "").substring(2)
        );
      }
      if (this.state.filterPeriodYear !== 0) {
        draftpsurveyList = draftpsurveyList.filter(
          (a) =>
            a.projectid.substring(2, 4) === that.state.filterPeriodYear + ""
        );
      }
    }

    const psurveyWaitList = psurveyNewList
      ? psurveyNewList.filter((a) => a.condo_approved === 3)
      : [];

    const psurveyRejectList = psurveyNewList
      ? psurveyNewList.filter((a) => a.condo_approved === 2)
      : [];

    const psurveyApprovedList = psurveyNewList
      ? psurveyNewList.filter((a) => a.condo_approved === 1)
      : [];

    const draftPsurvey = parseInt(draftpsurveyList.length)
      ? draftpsurveyList.length
      : 0;
    const waitPsurvey = parseInt(psurveyWaitList.length)
      ? psurveyWaitList.length
      : 0;
    const approvedPsurvey = parseInt(psurveyApprovedList.length)
      ? psurveyApprovedList.length
      : 0;
    const rejectPsurvey = parseInt(psurveyRejectList.length)
      ? psurveyRejectList.length
      : 0;
    const allPsurvey =
      parseInt(waitPsurvey) +
      parseInt(approvedPsurvey) +
      parseInt(rejectPsurvey);

    const psurveyColumns = [
      // {
      //   Header: switchIntl(toggleLng).faq.no,
      //   accessor: "index", // String-based value accessors!
      //   width: 70,
      //   filterable: false,
      //   Cell: (row) => {
      //     return JSON.stringify(row.row._index + 1);
      //   },
      // },
      // {
      //   Header: "Checkbox",
      // },
      // {
      //   Header: "QC",
      //   accessor: "qc",
      //   headerStyle: { textAlign: "center" },
      //   width: 50,
      //   filterable: false, // String-based value accessors!
      //   sortable: false, // String-based value accessors!
      // },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col10,
        accessor: "rj_detail",
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col11,
        accessor: "check",
        headerStyle: { textAlign: "center" },
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col1,
        accessor: "condo_refcode", // String-based value accessors!
        filterMethod: (filter, row) => {
          return row.condo_refcode.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col2,
        accessor: "condo_nameth", // String-based value accessors!
        filterMethod: (filter, row) => {
          return row.condo_nameth.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col3,
        accessor: "project_type", // String-based value accessors!
        Cell: (row) =>
          switchIntl(this.props.toggleLng).project[row.row.project_type],
        filterMethod: (filter, row) => {
          return (
            switchIntl(this.props.toggleLng).project[row.project_type].indexOf(
              filter.value
            ) >= 0 || row.project_type.indexOf(filter.value) >= 0
          );
        },
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col4,
        accessor: "zone", // String-based value accessors!
        width: 60,
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col5,
        accessor: "province_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col6,
        accessor: "amphoe_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col7,
        accessor: "tambon_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col8,
        accessor: "cstats_name", // String-based value accessors!
      },
      // {
      //   Header: switchIntl(toggleLng).project.organize_name,
      //   accessor: "org_name", // String-based value accessors!
      // },
      // {
      //   Header: switchIntl(toggleLng).project.psurveytable_col1,
      //   accessor: "projectid", // String-based value accessors!
      //   filterMethod: (filter, row) => {
      //     return row.projectid.indexOf(filter.value) >= 0;
      //   },
      // },
      {
        Header: switchIntl(toggleLng).project.srptable_col10,
        filterable: false,
        width: 90,
        accessor: "approveFormatter", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col9,
        accessor: "buttonFormatter",
        headerStyle: { textAlign: "center" },
        width: 200,
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
      },
    ];

    const oldpsurveyColumns = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 70,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: "Refcode",
        accessor: "condo_refcode", // String-based value accessors!
        filterMethod: (filter, row) => {
          return row.condo_refcode.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col2,
        accessor: "condo_nameth", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col3,
        accessor: "project_type", // String-based value accessors!
        Cell: (row) =>
          switchIntl(this.props.toggleLng).project[row.row.project_type],
        filterMethod: (filter, row) => {
          return (
            switchIntl(this.props.toggleLng).project[row.project_type].indexOf(
              filter.value
            ) >= 0 || row.project_type.indexOf(filter.value) >= 0
          );
        },
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col4,
        accessor: "zone", // String-based value accessors!
        width: 60,
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col5,
        accessor: "province_t", // String-based value accessors!
        width: 60,
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col6,
        accessor: "amphoe_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col7,
        accessor: "tambon_t", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col8,
        accessor: "cstats_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.organize_name,
        accessor: "org_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col1,
        accessor: "projectid", // String-based value accessors!
        filterMethod: (filter, row) => {
          return row.projectid.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).project.srptable_col10,
        filterable: false,
        width: 90,
        accessor: "approveFormatter", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).project.psurveytable_col9,
        headerStyle: { textAlign: "center" },
        accessor: "oldbuttonFormatter",
        width: 140,
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
      },
    ];
    const periodLogColumns = [
      {
        Header: switchIntl(toggleLng).project.year,
        headerStyle: { textAlign: "left" },
        accessor: "reg_year", // String-based value accessors!
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row.reg_year);
        },
      },
      {
        Header: switchIntl(toggleLng).project.quarter,
        headerStyle: { textAlign: "left" },
        accessor: "reg_quarter", // String-based value accessors!
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row.reg_quarter);
        },
      },
      {
        Header: switchIntl(toggleLng).project.half,
        headerStyle: { textAlign: "left" },
        accessor: "reg_halfyear", // String-based value accessors!
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row.reg_halfyear);
        },
      },
      {
        Header: switchIntl(toggleLng).project.assigner,
        headerStyle: { textAlign: "left" },
        accessor: "member_username", // String-based value accessors!
        filterable: false,
      },
      {
        Header: switchIntl(toggleLng).project.date,
        headerStyle: { textAlign: "left" },
        accessor: "reg_timestamp",
        filterable: false,
        Cell: (row) => {
          return this.dateOnlyParse(row.row.reg_timestamp);
        },
      },
      {
        Header: switchIntl(toggleLng).project.time,
        headerStyle: { textAlign: "left" },
        accessor: "reg_timestamp",
        filterable: false,
        Cell: (row) => {
          return this.timeOnlyParse(row.row.reg_timestamp);
        },
      },
    ];

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });
    const srp_content = (
      <Row style={{ padding: "0px", margin: "0px" }}>
        {(() => {
          if (
            this.state.srpDataModal === undefined ||
            this.state.srpDataModal === false
              ? false
              : true
          ) {
            return (
              <Dialog
                fullScreen
                open={
                  this.state.srpDataModal === undefined ||
                  this.state.srpDataModal === false
                    ? false
                    : true
                }
                transition={Transition}
                style={{ width: "100%" }}
                keepMounted
                onClose={() =>
                  this.setState({ srpDataModal: false, srp_approval: "0" })
                }
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
              >
                <DialogContent
                  style={{ backgroundColor: "#fff" }}
                  id="modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    onClick={() =>
                      this.setState({ srpDataModal: false, srp_approval: "0" })
                    }
                    justIcon
                    round
                    color="white"
                    style={{
                      position: "absolute",
                      right: "50px",
                      zIndex: "100",
                    }}
                  >
                    <Close className={classes.icons} />
                  </Button>
                  <br />
                  {this.checkProjectType(this.state.projecttype)}
                </DialogContent>
              </Dialog>
            );
          }
        })()}
        <Col xs={12}>
          <Card style={{ padding: "15px" }}>
            <CardBody>
              <Row>
                <Col xl={6} lg={6} md={6}>
                  <Card className="cardContainer">
                    <CardBody className="cardBody">
                      <Row>
                        <Col xl={12} lg={12} md={12}>
                          <div className="box-chart">
                            <Pie
                              data={{
                                labels: [
                                  numeral(approvedSrp).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard.sentProject,
                                  numeral(draftSrp).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard
                                      .draftProject,
                                ],
                                datasets: [
                                  {
                                    backgroundColor: ["#008F00", "#0000AA"],
                                    data: [approvedSrp, draftSrp],
                                    datalabels: {
                                      labels: {
                                        value: {
                                          align: "center",
                                          backgroundColor: "white",
                                          borderColor: "white",
                                          borderWidth: 2,
                                          borderRadius: 4,
                                          color: (ctx) => {
                                            return ctx.dataset.backgroundColor;
                                          },
                                          formatter: (value, ctx) => {
                                            let datasets =
                                              ctx.chart.data.datasets;
                                            let sum = datasets[0].data.reduce(
                                              (a, b) => a + b,
                                              0
                                            );
                                            let percentage =
                                              Math.round((value / sum) * 100) +
                                              "%";
                                            return percentage;
                                          },
                                          padding: 4,
                                        },
                                      },
                                    },
                                  },
                                ],
                              }}
                              options={pieChart.options}
                              height={2}
                              width={10}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={6} lg={6} md={6}>
                  <Card className="cardContainer">
                    <CardBody className="cardBody">
                      <FileDrop
                        onDrop={this.handleDropFileSrp}
                        style={{
                          height: "100%",
                          width: "100%",
                          float: "center",
                        }}
                      >
                        <div
                          className="bgimg"
                          style={{
                            height: 250,
                            width: "100%",
                            float: "center",
                          }}
                        >
                          <h4>{switchIntl(toggleLng).project.uploadfilesrp}</h4>
                          <div className="oldproject-container">
                            <div className="picture">
                              <input
                                type="file"
                                accept=".csv"
                                onChange={this.handlePressFileSrp}
                                //ref={this.oldPjoectFile}
                              />
                            </div>
                            <div className="oldproject-button">
                              <h6 className="description">
                                {switchIntl(toggleLng).project.uploadfile}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </FileDrop>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Button color="info" onClick={() => this.downloadCSVSRP()}>
                {/* <CSVLink data={srpList} style={{color:'white'}}> */}
                <DownloadIcon />
                {switchIntl(toggleLng).manageuser.exportCSVfile}
                {/* </CSVLink> */}
              </Button>

              <div className="title-header">
                {switchIntl(toggleLng).project.project}
              </div>
              <CustomTabs
                classesname="change_tabColor"
                headerColor="info"
                tabs={[
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav1}
                        </h4>
                        <Badge color="success">
                          {numeral(approvedSrp).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <div style={{ float: "top" }}></div>
                        <ReactTable
                          data={srpList}
                          headerStyle={{
                            fontSize: "10",
                          }}
                          filterable
                          columns={srpColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                          // {...checkboxProps}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
    const print_content = (
      <div
        // eslint-disable-next-line react/no-string-refs
        id="map"
        ref="map"
        style={{
          height: "320px",
          //width: "800px",
          width: "480px",
          //width: "500px",
          position: "initial",
          // display: 'none'
          visibility: "hidden",
        }}
      />
    );

    var orgItems = this.state.orgList.map(function(org) {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
          }}
          value={org.org_id}
        >
          {org.org_name}
        </MenuItem>
      );
    });
    let optionItems =
      this.state.province &&
      this.state.province.map((prov) => (
        // eslint-disable-next-line react/jsx-key
        <MenuItem
          key={prov.value}
          id={prov.value}
          classes={{ root: classes.selectMenuItem }}
          value={prov.label}
          name={prov.label}
        >
          {prov.label}
        </MenuItem>
      ));

    const psurvey_content = (
      <Row style={{ padding: "0px", margin: "0px" }}>
        {(() => {
          if (
            this.state.psurveyDataModal === undefined ||
            this.state.psurveyDataModal === false
              ? false
              : true
          ) {
            return (
              <Dialog
                fullScreen
                open={
                  this.state.psurveyDataModal === undefined ||
                  this.state.psurveyDataModal === false
                    ? false
                    : true
                }
                transition={Transition}
                style={{ width: "100%" }}
                keepMounted
                onClose={() =>
                  this.setState({
                    psurveyDataModal: false,
                    psurvey_approval: "0",
                    cat: false,
                    stc: false,
                    bs: false,
                    sellroom: false,
                    st: false,
                  })
                }
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
              >
                <DialogContent
                  style={{ backgroundColor: "#ffffff" }}
                  id="modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    onClick={() =>
                      this.setState(
                        {
                          psurveyDataModal: false,
                          psurvey_approval: "0",
                          cat: false,
                          stc: false,
                          bs: false,
                          sellroom: false,
                          st: false,
                        },
                        () => this.resetSurvey()
                      )
                    }
                    justIcon
                    round
                    color="white"
                    style={{
                      position: "absolute",
                      right: "50px",
                      zIndex: "100",
                    }}
                  >
                    <Close className={classes.icons} />
                  </Button>
                  <br />

                  {this.checkSurveyType(this.props.surveytype)}

                  {this.state.psurvey_approval !== "5" ? (
                    <div>
                      {switchIntl(toggleLng).project.checkmessage}
                      {` `}
                      <Radio
                        checked={this.state.psurvey_approval === "1"}
                        onChange={this.handle_psurveyApproval}
                        value={"1"}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                      {switchIntl(toggleLng).project.pass}
                      {` `}
                      <Radio
                        checked={this.state.psurvey_approval === "2"}
                        onChange={this.handle_psurveyApproval}
                        value={"2"}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                      {switchIntl(toggleLng).project.notPass}
                      {` `}
                      <TextField
                        id="srpRj_detail"
                        label={switchIntl(toggleLng).project.stateReason}
                        multiline
                        rows="4"
                        defaultValue=""
                        className={classes.textField}
                        disabled={this.state.psurvey_approval !== "2"}
                        inputRef={(el) => (this.reasonRef = el)}
                        margin="normal"
                        // onChange={event =>
                        // this.setState({ psurveyRj_detail: event.target.value })
                        //   this.change(event, "survey_saleimprove", "name")
                        // }
                        // value={this.state.psurveyRj_detail}
                      />
                      {` `}
                      <br />
                      <div>
                        <Button
                          color="info"
                          onClick={() => this.submitPsurveyApproval()}
                          disabled={
                            this.state.psurvey_approval !== "1" &&
                            this.state.psurvey_approval !== "2"
                          }
                        >
                          {switchIntl(toggleLng).project.submitbuttonlabel}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </DialogContent>
              </Dialog>
            );
          }
        })()}
        <Col xs={12}>
          <Card chart style={{ padding: "15px" }}>
            <Row>
              <Col xl={6} lg={6} md={6}>
                <Card className="cardContainer">
                  <CardBody className="cardBody">
                    <div className="card-title-dashboard">
                      {switchIntl(toggleLng).project.overAllProject}
                    </div>
                    <Row>
                      <Col xl={12} lg={12} md={12}>
                        <div className="box-chart">
                          <Pie
                            data={{
                              labels: [
                                numeral(approvedPsurvey).format("0,0") +
                                  " " +
                                  switchIntl(toggleLng).dashboard
                                    .approvedProject,
                                numeral(rejectPsurvey).format("0,0") +
                                  " " +
                                  switchIntl(toggleLng).dashboard
                                    .rejectedProject,
                                numeral(waitPsurvey).format("0,0") +
                                  " " +
                                  switchIntl(toggleLng).dashboard.waitProject,
                                numeral(draftPsurvey).format("0,0") +
                                  " " +
                                  switchIntl(toggleLng).dashboard.draftProject,
                              ],
                              datasets: [
                                {
                                  backgroundColor: [
                                    "#008F00",
                                    "#CA180B",
                                    "#E68900",
                                    "#0000AA",
                                  ],
                                  data: [
                                    approvedPsurvey,
                                    rejectPsurvey,
                                    waitPsurvey,
                                    draftPsurvey,
                                  ],
                                  datalabels: {
                                    labels: {
                                      value: {
                                        align: "center",
                                        backgroundColor: "white",
                                        borderColor: "white",
                                        borderWidth: 2,
                                        borderRadius: 4,
                                        color: (ctx) => {
                                          return ctx.dataset.backgroundColor;
                                        },
                                        formatter: (value, ctx) => {
                                          let datasets =
                                            ctx.chart.data.datasets;
                                          let sum = datasets[0].data.reduce(
                                            (a, b) => a + b,
                                            0
                                          );
                                          let percentage =
                                            Math.round((value / sum) * 100) +
                                            "%";
                                          return percentage;
                                        },
                                        padding: 4,
                                      },
                                    },
                                  },
                                },
                              ],
                            }}
                            options={pieChart.options}
                            height={2}
                            width={10}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={6} lg={6} md={6}>
                <Card className="cardContainer">
                  <CardBody className="cardBody">
                    <h4 style={{ float: "center" }}>
                      {that.props.toggleLng === "TH"
                        ? "ตั้งช่วงสำรวจโครงการ"
                        : "Set Period Time"}
                    </h4>
                    <Row>
                      <Col xs={3}>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.selectedYear || 0}
                          onChange={(e) =>
                            this.setState({ selectedYear: e.target.value })
                          }
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={0}
                          >
                            {switchIntl(toggleLng).project.select_year}
                          </MenuItem>
                          <MenuItem
                            value={new Date().getFullYear() + 544}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {new Date().getFullYear() + 544}
                          </MenuItem>
                          <MenuItem
                            value={new Date().getFullYear() + 543}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {new Date().getFullYear() + 543}
                          </MenuItem>
                          <MenuItem
                            value={new Date().getFullYear() + 542}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {new Date().getFullYear() + 542}
                          </MenuItem>
                        </Select>
                      </Col>
                      <Col xs={3}>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.selectedQuarter || 0}
                          onChange={(e) =>
                            this.setState({ selectedQuarter: e.target.value })
                          }
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={0}
                          >
                            {switchIntl(toggleLng).project.select_quarter}
                          </MenuItem>
                          <MenuItem
                            value={"1"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            1
                          </MenuItem>
                          <MenuItem
                            value="2"
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            2
                          </MenuItem>
                          <MenuItem
                            value={"3"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            3
                          </MenuItem>
                          <MenuItem
                            value={"4"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            4
                          </MenuItem>
                        </Select>
                      </Col>
                      <Col xs={3}>
                        {/* <GridItem> */}
                        <div className={classes.inlineChecks}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={(event) =>
                                  this.handleCheckedQuarter(event)
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot,
                            }}
                            label="Q"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={(event) =>
                                  this.handleCheckedHalf(event)
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot,
                            }}
                            label="H"
                          />
                        </div>
                        {/* </GridItem> */}
                      </Col>
                      {/* <Col xs={3}>
                        <Button
                          color="info"
                          size="small"
                          onClick={() =>
                            this.handlePeriodChange(
                              this.state.selectedYear,
                              this.state.selectedQuarter,
                              this.state.check_halfyear,
                              this.state.check_quarter
                            )
                          }
                        >
                          {switchIntl(toggleLng).project.set_period}
                        </Button>
                      </Col> */}
                    </Row>
                    <ReactTable
                      data={this.state.regLog}
                      columns={periodLogColumns}
                      defaultPageSize={1}
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="title-header">
              {switchIntl(toggleLng).project.survey}
              <AntTabs
                value={this.state.curTab}
                onChange={(e, v) => this.setState({ curTab: v })}
                aria-label="ant example"
              >
                <AntTab
                  label={switchIntl(this.props.toggleLng).project.newsurvey}
                />
                <AntTab
                  label={switchIntl(this.props.toggleLng).project.oldsurvey}
                />
              </AntTabs>
            </div>
            <TabPanel value={this.state.curTab} index={0}>
              <Row>
                <Col>
                  {this.state.generatingPDF ? (
                    <Progress
                      value={(generatingPDFinished / generatingPDFAll) * 100}
                    >
                      {generatingPDFinished}/{generatingPDFAll}
                    </Progress>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={7}>
                  <Card
                    style={{
                      paddingTop: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    <Row>
                      <Col xs={4}>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.filterCompany || 0}
                          onChange={(e) =>
                            this.setState({
                              filterCompany: e.target.value,
                            })
                          }
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={0}
                          >
                            {switchIntl(toggleLng).project.select_organize}
                          </MenuItem>
                          {orgItems.length === 0 ? (
                            <MenuItem
                              value={1}
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              ...
                            </MenuItem>
                          ) : (
                            orgItems
                          )}
                        </Select>
                      </Col>
                      <Col xs={4}>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.selectedCurrent}
                          onChange={(e) => {
                            this.setState({
                              selectedCurrent: e.target.value,
                              filterYear: parseInt(
                                e.target.value.split("-")[0]
                              ),
                              filterPeriodYear: e.target.value
                                .split("-")[1]
                                .substring(0, 2),
                            });
                            // console.log(
                            //   this.state.selectedPrevious,
                            //   e.target.value
                            // );
                            if (
                              this.state.selectedPrevious !== e.target.value
                            ) {
                              this.setState({
                                showEditPsurvey: false,
                              });
                            } else {
                              this.setState({
                                showEditPsurvey: true,
                              });
                            }
                          }}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"0-0"}
                          >
                            {switchIntl(toggleLng).project.select_survey_year}
                          </MenuItem>
                          {this.state.currentSurveyYear}
                          {(() => {
                            var yearList = [];
                            for (
                              let year = this.state.currentSurveyYear;
                              year >= 2563;
                              year--
                            ) {
                              if (year === 2563 || year === 2564) {
                                for (let half = 1; half <= 2; half++) {
                                  let name = "";
                                  if (half === 1) {
                                    name =
                                      year +
                                      "-" +
                                      "H1" +
                                      (year === this.state.currentSurveyYear &&
                                      half === this.state.currentSurveyHalfYear
                                        ? " (ปัจจุบัน)"
                                        : "");
                                  } else {
                                    name =
                                      year +
                                      "-" +
                                      "H2" +
                                      (year === this.state.currentSurveyYear &&
                                      half === this.state.currentSurveyHalfYear
                                        ? " (ปัจจุบัน)"
                                        : "");
                                  }
                                  yearList.push(
                                    <MenuItem
                                      value={name}
                                      classes={{
                                        root: classes.selectMenuItem,
                                      }}
                                    >
                                      {name}
                                    </MenuItem>
                                  );
                                }
                              } else {
                                for (let quarter = 1; quarter <= 4; quarter++) {
                                  let name = "";
                                  if (quarter === 1) {
                                    name =
                                      year +
                                      "-" +
                                      "Q1" +
                                      (year === this.state.currentSurveyYear &&
                                      quarter ===
                                        this.state.currentSurveyQuarterYear
                                        ? " (ปัจจุบัน)"
                                        : "");
                                  } else if (quarter === 2) {
                                    name = [
                                      year +
                                        "-" +
                                        "Q2" +
                                        (year ===
                                          this.state.currentSurveyYear &&
                                        quarter ===
                                          this.state.currentSurveyQuarterYear
                                          ? " (ปัจจุบัน)"
                                          : ""),
                                      year +
                                        "-" +
                                        "H1" +
                                        (year ===
                                          this.state.currentSurveyYear &&
                                        quarter ===
                                          this.state.currentSurveyQuarterYear
                                          ? " (ปัจจุบัน)"
                                          : ""),
                                    ];
                                  } else if (quarter === 3) {
                                    name =
                                      year +
                                      "-" +
                                      "Q3" +
                                      (year === this.state.currentSurveyYear &&
                                      quarter ===
                                        this.state.currentSurveyQuarterYear
                                        ? " (ปัจจุบัน)"
                                        : "");
                                  } else if (quarter === 4) {
                                    name = [
                                      year +
                                        "-" +
                                        "Q4" +
                                        (year ===
                                          this.state.currentSurveyYear &&
                                        quarter ===
                                          this.state.currentSurveyQuarterYear
                                          ? " (ปัจจุบัน)"
                                          : ""),
                                      year +
                                        "-" +
                                        "H2" +
                                        (year ===
                                          this.state.currentSurveyYear &&
                                        quarter ===
                                          this.state.currentSurveyQuarterYear
                                          ? " (ปัจจุบัน)"
                                          : ""),
                                    ];
                                  }
                                  if (Array.isArray(name)) {
                                    name.map((item) =>
                                      yearList.push(
                                        <MenuItem
                                          value={item}
                                          classes={{
                                            root: classes.selectMenuItem,
                                          }}
                                        >
                                          {item}
                                        </MenuItem>
                                      )
                                    );
                                  } else {
                                    yearList.push(
                                      <MenuItem
                                        value={name}
                                        classes={{
                                          root: classes.selectMenuItem,
                                        }}
                                      >
                                        {name}
                                      </MenuItem>
                                    );
                                  }
                                }
                              }
                            }
                            return yearList;
                          })()}
                        </Select>
                      </Col>
                      <Col xs={4}>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.filterType || 0}
                          onChange={(e) =>
                            this.setState({ filterType: e.target.value })
                          }
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={0}
                          >
                            {switchIntl(toggleLng).project.select_type_asset}
                          </MenuItem>
                          <MenuItem
                            value={"household"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.household}
                          </MenuItem>
                          <MenuItem
                            value={"condo"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.condo}
                          </MenuItem>
                          <MenuItem
                            value={"villa"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.villa}
                          </MenuItem>
                        </Select>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col xs={3}>
                        <Button
                          color="info"
                          size="small"
                          onClick={() => this.downloadCSVPsurvey()}
                        >
                          {switchIntl(toggleLng).project.export_csv_report}
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <Button
                          m={-2}
                          color="primary"
                          size="small"
                          onClick={() => this.inputHouseRef.current.click()}
                        >
                          {switchIntl(toggleLng).project.import_csv_house}
                        </Button>
                        <input
                          type="file"
                          ref={this.inputHouseRef}
                          accept="*"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.handleUploadHouse(e)}
                        />
                      </Col>
                      <Col xs={3}>
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => this.inputVillaRef.current.click()}
                        >
                          {switchIntl(toggleLng).project.import_csv_villa}
                        </Button>
                        <input
                          type="file"
                          ref={this.inputVillaRef}
                          accept="*"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.handleUploadVilla(e)}
                        />
                      </Col>
                      <Col xs={3}>
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => this.inputCondoRef.current.click()}
                        >
                          {switchIntl(toggleLng).project.import_csv_condo}
                        </Button>
                        <input
                          type="file"
                          ref={this.inputCondoRef}
                          accept="*"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.handleUploadCondo(e)}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={5}>
                  <Card style={{ padding: 20 }}>
                    {/* Export by province */}

                    <Row>
                      <Col xs={4}>
                        {/* selected province */}
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          id={this.state.selected_prov_id}
                          value={this.state.selected_prov_name}
                          onChange={(e, child) => {
                            // console.log(e, child, child.props.id);
                            this.setState({
                              selected_prov_name: e.target.value,
                              selected_prov_id: child.props.id,
                            });
                          }}
                        >
                          {optionItems}
                        </Select>
                      </Col>
                      <Col xs={4}>
                        <Button
                          color="info"
                          size="small"
                          onClick={() =>
                            this.downloadPDFPsurvey(this.state.selected_prov)
                          }
                        >
                          {switchIntl(toggleLng).project.export_pdf_province}
                        </Button>
                      </Col>
                      <Col xs={4}>
                        <Button
                          color="success"
                          size="small"
                          onClick={() =>
                            this.genPDFPsurvey(this.state.selected_prov)
                          }
                        >
                          {switchIntl(toggleLng).project.update_pdf_province}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {this.state.openUploadNew ? (
                    <div
                      style={{
                        width: "100%",
                        height: "300px",
                        overflow: "visible",
                      }}
                    >
                      <Card>
                        {switchIntl(toggleLng).project.uploadproject}
                        {` `}
                        <small>
                          ({switchIntl(toggleLng).project.uploaddescription})
                        </small>
                        <div>
                          <a
                            href={
                              this.state.uploadOldType === 2
                                ? config.requestUrl +
                                  "/Hbg/files/condo_template.zip"
                                : this.state.uploadOldType === 1
                                ? config.requestUrl +
                                  "/Hbg/files/household_template.zip"
                                : config.requestUrl +
                                  "/Hbg/files/villa_template.zip"
                            }
                            download
                          >
                            <Button
                              color="info"
                              onClick={() => this.showTemplate(true)}
                            >
                              {switchIntl(toggleLng).project.lookuptemplate}
                            </Button>
                          </a>
                          <br />
                          <input
                            type="file"
                            ref={this.inputRef}
                            accept="*"
                            style={{ visibility: "hidden" }}
                            onChange={(e) => this.handleUploadFile(e)}
                          />
                          <Row>
                            <Button
                              m={-2}
                              color="primary"
                              size="small"
                              onClick={() => this.inputHouseRef.current.click()}
                            >
                              นำเข้าตาราง บ้านจัดสรร
                            </Button>
                            <input
                              type="file"
                              ref={this.inputHouseRef}
                              accept="*"
                              style={{ visibility: "hidden" }}
                              onChange={(e) => this.handleUploadHouse(e)}
                            />
                          </Row>
                          <Row>
                            <Button
                              color="primary"
                              size="small"
                              onClick={() => this.inputVillaRef.current.click()}
                            >
                              นำเข้าตาราง วิลล่า
                            </Button>
                            <input
                              type="file"
                              ref={this.inputVillaRef}
                              accept="*"
                              style={{ visibility: "hidden" }}
                              onChange={(e) => this.handleUploadVilla(e)}
                            />
                          </Row>
                          <Row>
                            <Button
                              color="primary"
                              size="small"
                              onClick={() => this.inputCondoRef.current.click()}
                            >
                              นำเข้าตาราง คอนโด
                            </Button>
                            <input
                              type="file"
                              ref={this.inputCondoRef}
                              accept="*"
                              style={{ visibility: "hidden" }}
                              onChange={(e) => this.handleUploadCondo(e)}
                            />
                          </Row>
                        </div>
                      </Card>
                    </div>
                  ) : null}
                </Col>
              </Row>

              <br />
              {/* <Select
                fullWidth
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={that.state.selectedQC || 0}
                // onChange={(e) => that.setState({ selectedQC: e.target.value })}
                onChange={
                  (e) => console.log(e)
                  // that.handleSelectQC(
                  //   a.projectid,
                  //   a.project_type,
                  //   a.remark,
                  //   e.target.value
                  // )
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={0}
                >
                  เลือก
                </MenuItem>
                <MenuItem
                  value={1}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  ผ่าน
                </MenuItem>
                <MenuItem
                  value={2}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  ไม่ผ่าน
                </MenuItem>
              </Select> */}
              <CustomTabs
                classesname="change_tabColor"
                headerColor="info"
                tabs={[
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav1}
                        </h4>
                        <Badge color="success">
                          {numeral(allPsurvey).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <div style={{ float: "top" }}>
                          <Row>
                            <Col md={4}></Col>
                          </Row>
                          <Row>
                            <Col md={12}></Col>
                          </Row>
                        </div>
                        {/* {psurveyNewList ? (
                          <SelectTable
                            data={psurveyNewList}
                            columns={psurveyColumns}
                            ref={(r) => (this.selectTable = r)}
                            className="-striped -highlight"
                            {...extraProps}
                          />
                        ) : null} */}
                        <ReactTable
                          data={psurveyNewList}
                          filterable
                          columns={psurveyColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav2}
                        </h4>
                        <Badge color="success">
                          {numeral(waitPsurvey).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <div style={{ float: "top" }}></div>
                        <ReactTable
                          data={psurveyWaitList}
                          filterable
                          columns={psurveyColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav3}
                        </h4>
                        <Badge color="success">
                          {numeral(approvedPsurvey).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <div style={{ float: "top" }}></div>
                        <ReactTable
                          data={psurveyApprovedList}
                          filterable
                          columns={psurveyColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav4}
                        </h4>
                        <Badge color="success">
                          {numeral(rejectPsurvey).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <div style={{ float: "top" }}></div>
                        <ReactTable
                          data={psurveyRejectList}
                          filterable
                          columns={psurveyColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </TabPanel>
            <TabPanel value={this.state.curTab} index={1}>
              <Row>
                <Col xs={3}>
                  <Card style={{ padding: 20 }}>
                    <Row>
                      <Col xs={6}>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={
                            this.state.filterYearOld === 0 &&
                            this.state.filterPeriodYearOld === 0
                              ? "0-0"
                              : this.state.filterYearOld +
                                "-" +
                                this.state.filterPeriodYearOld
                          }
                          onChange={(e) => {
                            this.setState({
                              filterYearOld: e.target.value.split("-")[0],
                              filterPeriodYearOld: e.target.value.split("-")[1],
                            });
                          }}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"0-0"}
                          >
                            {switchIntl(toggleLng).project.select_survey_year}
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2562-H1"}
                          >
                            2562-H1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2562-H2"}
                          >
                            2562-H2
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2561-H1"}
                          >
                            2561-H1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2561-H2"}
                          >
                            2561-H2
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2560-H1"}
                          >
                            2560-H1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2560-H2"}
                          >
                            2560-H2
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2560-Q3"}
                          >
                            2560-Q3
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2559-H1"}
                          >
                            2559-H1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2559-H2"}
                          >
                            2559-H2
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2559-Q3"}
                          >
                            2559-Q3
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2558-H1"}
                          >
                            2558-H1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2558-H2"}
                          >
                            2558-H2
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={"2558-Q3"}
                          >
                            2558-Q3
                          </MenuItem>
                        </Select>
                      </Col>
                      <Col xs={6}>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.filterTypeOld || 0}
                          onChange={(e) =>
                            this.setState({ filterTypeOld: e.target.value })
                          }
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={0}
                          >
                            {switchIntl(toggleLng).project.select_type_asset}
                          </MenuItem>
                          <MenuItem
                            value={"household"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.household}
                          </MenuItem>
                          <MenuItem
                            value={"condo"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.condo}
                          </MenuItem>
                          <MenuItem
                            value={"villa"}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {switchIntl(toggleLng).project.villa}
                          </MenuItem>
                        </Select>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <CustomTabs
                classesname="change_tabColor"
                headerColor="info"
                tabs={[
                  {
                    tabName: (
                      <div style={{}}>
                        <h4 style={{ marginRight: "10px" }}>
                          {switchIntl(toggleLng).dashboard.table_nav1}
                        </h4>
                        <Badge color="success">
                          {numeral(psurveyOldList.length).format("0,0")}
                        </Badge>
                      </div>
                    ),
                    tabContent: (
                      <div>
                        <ReactTable
                          data={psurveyOldList}
                          filterable
                          columns={oldpsurveyColumns
                            .slice(0, 9)
                            .concat(oldpsurveyColumns.slice(11))}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </TabPanel>
          </Card>
        </Col>
      </Row>
    );

    return (
      <div>
        {this.state.alert}
        {this.props.survey_modal}
        {this.loadingScreen(this.state.loadingScreen)}
        <NavPills
          active={this.state.tabActive}
          handleTabChange={(active) => this.setState({ tabActive: active })}
          color="warning"
          tabs={[
            {
              tabButton: "PSurvey Application",
              tabContent: psurvey_content,
            },
            {
              tabButton: "SRP Application",
              tabContent: srp_content,
            },
            {
              tabContent: print_content,
            },
          ]}
        />
      </div>
    );
  }
}

GHProject.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
  surveyType: (payload) => dispatch(surveyType(payload)),
  setModalType: (payload) => dispatch(setModalType(payload)),
  setProjectIDHome: (payload) => dispatch(setProjectIDHome(payload)),
  setProjectStatus: (payload) => dispatch(setProjectStatus(payload)),
  setApproved: (payload) => dispatch(setApproved(payload)),
  oldproject_data: (payload) => dispatch(oldproject_data(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  loading_datatable: (payload) => dispatch(loading_datatable(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(GHProject));
