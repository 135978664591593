/* eslint-disable no-useless-escape */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ClipLoader } from "react-spinners";

import * as config from "configure/configServer.js";

// redux
import { connect } from "react-redux";
import surveyInfo from "actions/survey/surveyInfo.js";
import house_sec2Object from "actions/survey/house/house_sec2Data.js";
import houseTypeListObject from "actions/survey/houseTypeListData.js";
import householdSec2_Object from "actions/survey/house/household_sec2Obj.js";
import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import house_sec3Object from "actions/survey/house/house_sec3Data.js";
import householdSec3_Object from "actions/survey/house/household_sec3Obj.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";
import Add from "@material-ui/icons/Add";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CryptoJS from "crypto-js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";

import MaterialTable from "material-table";
import surveyModal from "actions/survey/surveyModal.js";
import imageuploading from "actions/uploading_image.js";

import numeral from "numeral";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./HouseTable.css";
import SweetAlert from "react-bootstrap-sweetalert";

class HouseStep2 extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,

      // noti core
      noti: false,
      open: false,
      color: "info",
      message: "Hello",
      place: "tc",
      token: "",
      btLoading: false,
      value: 0,
      createModal: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      markerPosition: { lat: null, lng: null },
      welfareCheck: [],
      openNotification: false,
      language: "th",
      cat_detail: [],
      cat_sumrow: [],
      chk_projectId: null,
      chk_userId: null,
      cat_type_Disabled: true,
      // cat detail state change
      cat_type_State: "",
      cat_floor_State: "",
      cat_width_State: "",
      cat_minarea_State: "",
      cat_maxarea_State: "",
      cat_minuseablearea_State: "",
      cat_maxuseablearea_State: "",
      cat_bedroom_State: "",
      cat_bathroom_State: "",
      cat_minprice_State: "",
      cat_maxprice_State: "",
      cat_discount_State: "",
      cat_allunitinproject_State: "",
      // tooltip state value
      tooltip_floor: "",
      tooltip_width: "",
      tooltip_minarea: "",
      tooltip_maxarea: "",
      tooltip_minuseable: "",
      tooltip_maxuseable: "",
      tooltip_bedroom: "",
      tooltip_bathroom: "",
      tooltip_minprice: "",
      tooltip_maxprice: "",
      tooltip_discount: "",
      tooltip_allunit: "",
      // start of column render
      sumUnit: 0,
      column: [
        {
          title: "ประเภทบ้าน",
          field: "cat_type",
          lookup: {},
          initialEditValue: "",
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.cat_type_State === ""
                      ? "#AAAAAA"
                      : this.state.cat_type_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                โปรดเลือก
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) => this.change(event, props, "cat_type")}
              >
                <MenuItem value="1">บ้านเดี่ยว</MenuItem>
                <MenuItem value="2">บ้านแฝด</MenuItem>
                <MenuItem value="3">ทาวน์เฮ้าส์</MenuItem>
                <MenuItem value="4">อาคารพาณิชย์</MenuItem>
                <MenuItem value="5">ที่ดินเปล่า</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          title: "จำนวนชั้น",
          field: "cat_floor",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_floor).format("0.[0]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_floor_State === "error" ? "**" : undefined
              }
              success={this.state.cat_floor_State === "success"}
              error={this.state.cat_floor_State === "error"}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) => this.change(event, props, "cat_floor"),
                type: "number",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_floor(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "บ้านหน้ากว้าง",
          field: "cat_width",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_width).format("0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_width_State === "error" ? "**" : undefined
              }
              success={this.state.cat_width_State === "success"}
              error={this.state.cat_width_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) => this.change(event, props, "cat_width"),
                type: "number",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_width(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "เนื้อที่ดิน ต่ำสุด",
          field: "cat_minarea",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_minarea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_minarea_State === "error" ? "**" : undefined
              }
              success={this.state.cat_minarea_State === "success"}
              error={this.state.cat_minarea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) => this.change(event, props, "cat_minarea"),
                type: "number",
                value: props.value ? props.value : "",
              }}
              tooltipText={this.getTooltip_minarea(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "เนื้อที่ดิน สูงสุด",
          field: "cat_maxarea",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_maxarea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_maxarea_State === "error" ? "**" : undefined
              }
              success={this.state.cat_maxarea_State === "success"}
              error={this.state.cat_maxarea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) => this.change(event, props, "cat_maxarea"),
                type: "number",
                value: props.value ? props.value : "",
              }}
              tooltipText={this.getTooltip_maxarea(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "พื้นที่ใช้สอย ต่ำสุด",
          field: "cat_minuseablearea",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.cat_minuseablearea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_minuseablearea_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.cat_minuseablearea_State === "success"}
              error={this.state.cat_minuseablearea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) =>
                  this.change(event, props, "cat_minuseablearea"),
                type: "number",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_minuseable(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "พื้นที่ใช้สอย สูงสุด",
          field: "cat_maxuseablearea",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.cat_maxuseablearea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_maxuseablearea_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.cat_maxuseablearea_State === "success"}
              error={this.state.cat_maxuseablearea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) =>
                  this.change(event, props, "cat_maxuseablearea"),
                type: "number",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_maxuseable(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "จำนวนห้องนอน",
          field: "cat_bedroom",
          type: "string",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => rowData.cat_bedroom,
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_bedroom_State === "error" ? "**" : undefined
              }
              success={this.state.cat_bedroom_State === "success"}
              error={this.state.cat_bedroom_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) => this.change(event, props, "cat_bedroom"),
                type: "text",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_bedroom(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "จำนวนห้องน้ำ",
          field: "cat_bathroom",
          type: "string",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => rowData.cat_bathroom,
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_bathroom_State === "error" ? "**" : undefined
              }
              success={this.state.cat_bathroom_State === "success"}
              error={this.state.cat_bathroom_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: parseInt(props.rowData.cat_type) === 5,
                onChange: (event) => this.change(event, props, "cat_bathroom"),
                type: "text",
                value:
                  parseInt(props.rowData.cat_type) === 5
                    ? "0"
                    : props.value
                    ? props.value
                    : "",
              }}
              tooltipText={this.getTooltip_bathroom(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "ราคาขาย/ยูนิต ต่ำสุด",
          field: "cat_minprice",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_minprice).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_minprice_State === "error" ? "**" : undefined
              }
              success={this.state.cat_minprice_State === "success"}
              error={this.state.cat_minprice_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) => this.change(event, props, "cat_minprice"),
                type: "text",
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.getTooltip_minprice(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "ราคาขาย/ยูนิต สูงสุด",
          field: "cat_maxprice",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.cat_maxprice).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_maxprice_State === "error" ? "**" : undefined
              }
              success={this.state.cat_maxprice_State === "success"}
              error={this.state.cat_maxprice_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) => this.change(event, props, "cat_maxprice"),
                type: "text",
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.getTooltip_maxprice(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "ส่วนลด",
          field: "cat_discount",
          type: "numeric",
          //initialEditValue: "0",
          emptyValue: "0",
          render: (rowData) => numeral(rowData.cat_discount).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_discount_State === "error" ? "**" : undefined
              }
              success={this.state.cat_discount_State === "success"}
              error={this.state.cat_discount_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) => this.change(event, props, "cat_discount"),
                type: "text",
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.getTooltip_discount(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
        {
          title: "หน่วยในผังโครงการบ้านทั้งหมด",
          field: "cat_allunitinproject",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.cat_allunitinproject).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.cat_allunitinproject_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.cat_allunitinproject_State === "success"}
              error={this.state.cat_allunitinproject_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: this.state.cat_type_Disabled,
                onChange: (event) =>
                  this.change(event, props, "cat_allunitinproject"),
                type: "number",
                value: props.value ? props.value : "",
              }}
              tooltipText={this.getTooltip_allunit(
                parseInt(props.rowData.cat_type)
              )}
            />
          ),
        },
      ],
      data: [],
      index: -1,
    };
  }

  static propTypes = {
    house_sec2Object: PropTypes.object.isRequired,
    proj_dataTable: PropTypes.object.isRequired,
  };
  sendState() {
    return this.state;
  }
  componentDidMount() {
    this.getType();
    this.getSecretKey();

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
    } else if (
      !(
        this.props.proj_dataTable.cat.length === 1 &&
        "cat_type" in this.props.proj_dataTable.cat[0] &&
        (this.props.proj_dataTable.cat[0].cat_type === "" ||
          this.props.proj_dataTable.cat[0].cat_type === "N_A" ||
          (this.props.proj_dataTable.cat[0].cat_type === 0 &&
            this.props.proj_dataTable.cat[0].cat_width === 0))
      )
    ) {
      this.get_projectData(this.props.proj_dataTable);
      // this.show_label(this.props.proj_dataTable);
    } else {
      // this.show_label(this.props.proj_dataTable);
    }
  }

  deleteSection() {
    let projectid =
      this.props.house_initial_data.household_projectid === "N_A"
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].household_projectid
          : this.props.householdSec1Object.household_projectid
        : this.props.house_initial_data.household_projectid;
    let refcode =
      this.props.house_initial_data.household_refcode === undefined
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].household_refcode
          : this.props.householdSec1Object.household_refcode
        : this.props.house_initial_data.household_refcode;
    var param = {
      projectid: projectid,
      refcode: refcode,
      event: "Delete house section 2 and 3",
    };
    var request = new Request(config.API_sendevent, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then((response) => {
        response.json().then((data) => {
          if (data.code === 200) {
          } else {
            this.setState({
              deleteAlert: (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="ทำรายการล้มเหลว กรุณาทำรายการใหม่"
                  showConfirm={false}
                ></SweetAlert>
              ),
            });
          }
          setTimeout(() => {
            this.setState({ deleteAlert: null });
          }, 1500);
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
  }

  // show_label = (value) => {
  //   const edit_data = value.data[0];
  //   this.setState({
  //     refcode: edit_data.household_refcode,
  //     project_nameth: edit_data.household_nameth,
  //     project_nameen: edit_data.household_nameeng,
  //     project_status: edit_data.household_projectstatus,
  //   });

  //   this.call_merge_sec2();
  // };
  get_projectData = (value) => {
    const status = value.status;
    // const edit_data = value.data[0];
    this.setState({
      cat_detail: value.cat,
    });
    this.sumallUnit(value.cat);
    // console.log("status")
    // console.log(status)
    this.setState({
      cat_type_Disabled: false,
      projectStatus: status,
      view_psurvey: value.view_psurvey,
    });

    this.call_merge_sec2();
  };

  cattype_value = (value) => {
    return value;
  };

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  getType() {
    var that = this;
    var request = new Request(config.API_get_cate_house, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // console.log(data)
          if (data.code === 200) {
            that.setState({ typeList: data.data });
            let tempCol = that.state.column;
            let tempList = {};
            data.data.forEach(function(v) {
              tempList[v.value] = v.label;
            });

            tempCol[0].lookup = tempList;
            that.setState({ column: tempCol });
            that.props.houseTypeListObject(data.data);
          } else {
            //
          }
        });
      })
      .catch(function() {});
  }

  merge_data() {
    let cat_detail_obj = {};
    if (this.state.cat_detail.length > 0) {
      this.state.cat_detail.map(function(value) {
        return Object.keys(value).forEach(function(i) {
          if (!cat_detail_obj[i]) {
            cat_detail_obj[i] = [];
          }
          if (i === "cat_bathroom" || i === "cat_bedroom") {
            // value[i] = Number(value[i]);
          } else {
            value[i] = Number(value[i]);
          }
          cat_detail_obj[i].push(value[i]);
        });
      });
    }
    let homedata_section2 = {
      cat_type:
        cat_detail_obj && "cat_type" in cat_detail_obj
          ? cat_detail_obj["cat_type"]
          : [],
      cat_floor:
        cat_detail_obj && "cat_floor" in cat_detail_obj
          ? cat_detail_obj["cat_floor"]
          : [],
      cat_width:
        cat_detail_obj && "cat_width" in cat_detail_obj
          ? cat_detail_obj["cat_width"]
          : [],
      cat_minarea:
        cat_detail_obj && "cat_minarea" in cat_detail_obj
          ? cat_detail_obj["cat_minarea"]
          : [],
      cat_maxarea:
        cat_detail_obj && "cat_maxarea" in cat_detail_obj
          ? cat_detail_obj["cat_maxarea"]
          : [],
      cat_minuseablearea:
        cat_detail_obj && "cat_minuseablearea" in cat_detail_obj
          ? cat_detail_obj["cat_minuseablearea"]
          : [],
      cat_maxuseablearea:
        cat_detail_obj && "cat_maxuseablearea" in cat_detail_obj
          ? cat_detail_obj["cat_maxuseablearea"]
          : [],
      cat_bedroom:
        cat_detail_obj && "cat_bedroom" in cat_detail_obj
          ? cat_detail_obj["cat_bedroom"]
          : [],
      cat_bathroom:
        cat_detail_obj && "cat_bathroom" in cat_detail_obj
          ? cat_detail_obj["cat_bathroom"]
          : [],
      cat_minprice:
        cat_detail_obj && "cat_minprice" in cat_detail_obj
          ? cat_detail_obj["cat_minprice"]
          : [],
      cat_maxprice:
        cat_detail_obj && "cat_maxprice" in cat_detail_obj
          ? cat_detail_obj["cat_maxprice"]
          : [],
      cat_discount:
        cat_detail_obj && "cat_discount" in cat_detail_obj
          ? cat_detail_obj["cat_discount"]
          : [],
      cat_allunitinproject:
        cat_detail_obj && "cat_allunitinproject" in cat_detail_obj
          ? cat_detail_obj["cat_allunitinproject"]
          : [],
    };
    // console.log(homedata_section2);
    this.props.householdSec2_Object(homedata_section2);

    return homedata_section2;
  }

  async call_merge_sec2() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  async isValidated() {
    this.props.imageuploading(true);
    const body = await this.call_merge_sec2();

    if (
      body.cat_allunitinproject.length === 0 ||
      body.cat_bathroom.length === 0 ||
      body.cat_bedroom.length === 0 ||
      body.cat_floor.length === 0 ||
      body.cat_maxarea.length === 0 ||
      body.cat_maxprice.length === 0 ||
      body.cat_maxuseablearea.length === 0 ||
      body.cat_minarea.length === 0 ||
      body.cat_minprice.length === 0 ||
      body.cat_minuseablearea.length === 0 ||
      body.cat_type.length === 0 ||
      body.cat_width.length === 0
    ) {
      this.surveyModal("notallow");
      this.props.imageuploading(false);
      return false;
    } else {
      this.props.imageuploading(false);
      return true;
    }
  }

  surveyModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "save" ? "ไม่สามารถบันทึกเอกสารได้" : "ไม่สามารถไปต่อได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการบันทึก"
          : "กรุณากรอกข้อมูล ส่วนที่ 1 และ 2 ทั้งหมดให้ครบถ้วนก่อนไปส่วนถัดไป"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  errorModal() {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title="เกิดปัญหาในการเชื่อมต่อกับระบบ"
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        กรุณาตรวจสอบการเชื่อมต่อของท่านอีกครั้ง
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  async submit_save() {
    this.setState({
      btLoading: true,
    });
    const token = this.state.token;
    const part1 = this.props.householdSec1Object;
    const part3 = this.props.householdSec3Object;
    const body = await this.call_merge_sec2();

    if (
      body.cat_allunitinproject.length === 0 ||
      body.cat_bathroom.length === 0 ||
      body.cat_bedroom.length === 0 ||
      body.cat_floor.length === 0 ||
      body.cat_maxarea.length === 0 ||
      body.cat_maxprice.length === 0 ||
      body.cat_maxuseablearea.length === 0 ||
      body.cat_minarea.length === 0 ||
      body.cat_minprice.length === 0 ||
      body.cat_minuseablearea.length === 0 ||
      body.cat_type.length === 0 ||
      body.cat_width.length === 0
    ) {
      this.surveyModal("save");
      this.setState({
        btLoading: false,
      });
    } else {
      const source = {
        ...part1,
        ...body,
        ...part3,
      };

      const target = this.props.house_initial_data;
      const log_house_sec_2 = { event: "Insert or update house section 2" };
      const mergebody = Object.assign(target, source, log_house_sec_2);

      var request = new Request(config.API_save_household, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
        body: JSON.stringify(mergebody),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            btLoading: false,
          });
          if (responseJson.code === 200) {
            this.alertModal("success");
          } else {
            this.errorModal();
          }
        })
        .catch((err) => {
          this.errorModal();
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success" ? "บันทึกสำเร็จ" : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  successAlert(title, message, status) {
    let trashStyle = {
      paddingTop: "10px",
      color: "#e74c3c",
    };
    let messageStyle = {
      color: "#e74c3c",
      fontWeight: "bold",
      fontSize: "16px",
    };
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          customerrorAll_State
          className="SweetAlert"
          title={<div>{title}</div>}
          showCancel
          showConfirm
          confirmBtnCssClass="modalBtDelete"
          cancelBtnCssClass="modalBt modalBtExit"
          customIcon={
            <div style={trashStyle}>
              <i className="fa fa-trash fa-4x" />
            </div>
          }
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {<span style={messageStyle}>{message}</span>}
        </SweetAlert>
      ),
    });
  }

  // my new function start here
  // -----------------------------------------------
  change(event, props, stateName) {
    switch (stateName) {
      case "cat_type":
        if (this.cat_selectHomeType(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_floor":
        if (this.cat_floorHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_width":
        if (this.cat_widthHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_minarea":
        if (
          this.cat_minAreaHome(
            event.target.value,
            props,
            props.rowData.cat_maxarea
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_maxarea":
        if (
          this.cat_maxAreaHome(
            event.target.value,
            props,
            props.rowData.cat_minarea
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_minuseablearea":
        if (
          this.cat_minUseableAreaHome(
            event.target.value,
            props,
            props.rowData.cat_maxuseablearea
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_maxuseablearea":
        if (
          this.cat_maxUseableAreaHome(
            event.target.value,
            props,
            props.rowData.cat_minuseablearea
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_bedroom":
        if (this.cat_bedroomHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_bathroom":
        if (this.cat_bathroomHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_minprice":
        if (
          this.cat_minpriceHome(
            numeral(event.target.value).value(),
            props,
            props.rowData.cat_maxprice
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_maxprice":
        if (
          this.cat_maxpriceHome(
            numeral(event.target.value).value(),
            props,
            props.rowData.cat_minprice
          )
        ) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_discount":
        if (this.cat_discountHome(numeral(event.target.value).value(), props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "cat_allunitinproject":
        if (this.cat_allunitinprojectHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      default:
        break;
    }
  }

  cat_selectHomeType = (event, props) => {
    let hometype = parseInt(event);
    this.setState({
      cat_type_Disabled: false,
    });
    this.errorAll_State(hometype);

    props.onChange(event);
    return true;
  };

  getTooltip_floor = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "กรอกตัวเลข (มีทศนิยมได้ 1 ตัว) สูงสุดไม่เกิน 8 ชั้น";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_width = (hometype) => {
    switch (hometype) {
      case 1:
        return "กรอกตัวเลข (มีทศนิยมได้ 2 ตัว) มีความกว้างระหว่าง 4 - 50 เมตร";
      case 2:
        return "กรอกตัวเลข (มีทศนิยมได้ 2 ตัว) มีความกว้างระหว่าง 4 - 30 เมตร";
      case 3:
      case 4:
        return "กรอกตัวเลข (มีทศนิยมได้ 2 ตัว) มีความกว้างระหว่าง 3 - 15 เมตร";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_minarea = (hometype) => {
    switch (hometype) {
      case 1:
        return "ต้องมีค่ามากกว่า 35 ตร.ว. ขึ้นไป และ น้อยกว่า หรือ เท่ากับเนื้อที่ดินสูงสุด";
      case 2:
        return "ต้องมีค่ามากกว่า 30 ตร.ว. ขึ้นไป และ น้อยกว่า หรือ เท่ากับเนื้อที่ดินสูงสุด";
      case 3:
      case 4:
        return "ต้องมีค่ามากกว่า 15 ตร.ว. ขึ้นไป และ น้อยกว่า หรือ เท่ากับเนื้อที่ดินสูงสุด";
      case 5:
        return "ต้องมีค่ามากกว่า 10 ตร.ว. ขึ้นไป และ น้อยกว่า หรือ เท่ากับเนื้อที่ดินสูงสุด";
      default:
        return "";
    }
  };

  getTooltip_maxarea = (hometype) => {
    switch (hometype) {
      case 1:
        return "ต้องมีค่ามากกว่า 35 ตร.ว. ขึ้นไป และ มากกว่า หรือ เท่ากับเนื้อที่ดินต่ำสุด";
      case 2:
        return "ต้องมีค่ามากกว่า 30 ตร.ว. ขึ้นไป และ มากกว่า หรือ เท่ากับเนื้อที่ดินต่ำสุด";
      case 3:
      case 4:
        return "ต้องมีค่ามากกว่า 15 ตร.ว. ขึ้นไป และ มากกว่า หรือ เท่ากับเนื้อที่ดินต่ำสุด";
      case 5:
        return "ต้องมีค่ามากกว่า 10 ตร.ว. ขึ้นไป และ มากกว่า หรือ เท่ากับเนื้อที่ดินต่ำสุด";
      default:
        return "";
    }
  };

  getTooltip_minuseable = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "ต้องมีค่ามากกว่า 30 ตร.ม. ขึ้นไป และ น้อยกว่า หรือ เท่ากับพื้นที่ใช้สอยสูงสุด";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_maxuseable = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "ต้องมีค่ามากกว่า 30 ตร.ม. ขึ้นไป และ มากกว่า หรือ เท่ากับพื้นที่ใช้สอยต่ำสุด";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_bedroom = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "กรอกตัวเลขเท่านั้น (ไม่มีทศนิยม)";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_bathroom = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "กรอกตัวเลขเท่านั้น (ไม่มีทศนิยม)";
      case 5:
        return "";
      default:
        return "";
    }
  };

  getTooltip_minprice = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ต้องมีค่าน้อยกว่า หรือ เท่ากับราคาขายสูงสุด";
      default:
        return "";
    }
  };

  getTooltip_maxprice = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ต้องมีค่ามากกว่า หรือ เท่ากับราคาขายต่ำสุด";
      default:
        return "";
    }
  };

  getTooltip_discount = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "กรอกตัวเลขเท่านั้น (ไม่มีทศนิยม)";
      default:
        return "";
    }
  };

  getTooltip_allunit = (hometype) => {
    switch (hometype) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "กรอกตัวเลขเท่านั้น (ไม่มีทศนิยม)";
      default:
        return "";
    }
  };

  cat_floorHome = (event, props) => {
    var phoneRex = /^[0-9.]+$/;
    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0.[0]"));
      if (event === "0" || event === "00") {
        this.showNotification("warning", "จำนวนชั้นต้องมีค่าไม่เท่ากับ 0");
        return false;
      } else if (event > 8) {
        this.showNotification("warning", "จำนวนชั้นต้องน้อยกว่า 8 ชั้น");
        return false;
      } else if (event % 1 !== 0.5 && event % 1 !== 0) {
        this.showNotification(
          "warning",
          "จำนวนชั้นต้องเป็นจำนวนเต็ม หรือ ทศนิยม .5 เท่านั้น"
        );
        return false;
      } else {
        return true;
      }
    }
    props.onChange("");
    return false;
  };

  cat_widthHome = (event, props) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const val = event;

    var phoneRex = /^[0-9.]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0.[00]"));
      switch (cat_type) {
        case 1:
          if (this.between(val, 4, 50)) {
            return true;
          }
          return false;
        case 2:
          if (this.between(val, 4, 30)) {
            return true;
          }
          return false;
        case 3:
        case 4:
          if (this.between(val, 3, 15)) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_minAreaHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseFloat(numeral(event).value());
    const intcval = parseFloat(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0.[00]"));
      switch (cat_type) {
        case 1:
          if (event_intval < 35) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (event_intval <= intcval && intcval >= 35 && event_intval >= 35) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 2:
          if (event_intval < 30) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (event_intval <= intcval && intcval >= 30 && event_intval >= 30) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 3:
          if (event_intval < 15) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (event_intval <= intcval && intcval >= 15 && event_intval >= 15) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 4:
        case 5:
          if (event_intval < 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (event_intval <= intcval && intcval >= 10 && event_intval >= 10) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_maxAreaHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseFloat(numeral(event).value());
    const intcval = parseFloat(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0.[00]"));
      switch (cat_type) {
        case 1:
          if (event_intval < 35) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (cat_type !== 5 && event_intval > intcval * 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 10 เท่าของราคาที่ดินต่ำสุด"
            );
            return false;
          }
          if (event_intval >= intcval && intcval >= 35 && event_intval >= 35) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 2:
          if (event_intval < 30) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (cat_type !== 5 && event_intval > intcval * 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 10 เท่าของราคาที่ดินต่ำสุด"
            );
            return false;
          }
          if (event_intval >= intcval && intcval >= 30 && event_intval >= 30) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 3:
          if (event_intval < 15) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (cat_type !== 5 && event_intval > intcval * 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 10 เท่าของราคาที่ดินต่ำสุด"
            );
            return false;
          }
          if (event_intval >= intcval && intcval >= 15 && event_intval >= 15) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        case 4:
        case 5:
          if (event_intval < 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          if (cat_type !== 5 && event_intval > intcval * 10) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 10 เท่าของราคาที่ดินต่ำสุด"
            );
            return false;
          }
          if (event_intval >= intcval && intcval >= 10 && event_intval >= 10) {
            this.setState({
              cat_minarea_State: "success",
              cat_maxarea_State: "success",
            });
            return true;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minarea_State: "error",
              cat_maxarea_State: "error",
            });
            return false;
          }
          return false;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_minUseableAreaHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseFloat(numeral(event).value());
    const intcval = parseFloat(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0.[00]"));
      switch (cat_type) {
        case 1:
          if (event_intval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 2:
          if (event_intval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 3:
          if (event_intval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 4:
          if (event_intval < 30) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            return false;
          }
          if (intcval >= event_intval * 5) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 5 เท่าของราคาพื้นที่ใช้สอยต่ำสุด"
            );
            return false;
          }
          if (event_intval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          if (event_intval <= intcval && intcval >= 30 && event_intval >= 30) {
            this.setState({
              cat_minuseablearea_State: "success",
              cat_maxuseablearea_State: "success",
            });
            return true;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_maxUseableAreaHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseFloat(numeral(event).value());
    const intcval = parseFloat(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0.[00]"));
      switch (cat_type) {
        case 1:
          if (event_intval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 2:
          if (event_intval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 3:
          if (event_intval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        case 4:
          if (event_intval < 30) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          if (event_intval >= intcval * 5) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 5 เท่าของราคาพื้นที่ใช้สอยต่ำสุด"
            );
            return false;
          }
          if (event_intval > 3000) {
            this.setState({
              cat_maxuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยสูงสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (intcval > 3000) {
            this.setState({
              cat_minuseablearea_State: "error",
            });
            this.showNotification(
              "warning",
              "พื้นที่ใช้สอยต่ำสุดต้องมีค่าไม่เกิน 3000"
            );
            return false;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minuseablearea_State: "error",
              cat_maxuseablearea_State: "error",
            });
            return false;
          }
          if (event_intval >= intcval && intcval >= 30 && event_intval >= 30) {
            this.setState({
              cat_minuseablearea_State: "success",
              cat_maxuseablearea_State: "success",
            });
            return true;
          }
          this.setState({
            cat_minuseablearea_State: "success",
            cat_maxuseablearea_State: "success",
          });
          return true;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_bedroomHome = (event, props) => {
    const val = event;

    var phoneRex = /^[0-9,]+$/;
    if (phoneRex.test(val)) {
      props.onChange(event);
      return true;
    }
    props.onChange("");
    return false;
  };

  cat_bathroomHome = (event, props) => {
    const val = event;

    var phoneRex = /^[1-9][0-9,]*$/;
    if (phoneRex.test(val)) {
      props.onChange(event);
      return true;
    }
    props.onChange("");
    return false;
  };

  cat_minpriceHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseInt(numeral(event).value());
    const intcval = parseInt(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0"));
      switch (cat_type) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          if (event_intval <= intcval) {
            this.setState({
              cat_minprice_State: "success",
              cat_maxprice_State: "success",
            });
            return true;
          }
          if (event_intval > intcval) {
            this.setState({
              cat_minprice_State: "error",
              cat_maxprice_State: "error",
            });
            return false;
          }
          return false;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_maxpriceHome = (event, props, cval) => {
    const cat_type = parseInt(props.rowData.cat_type);
    const event_intval = parseInt(numeral(event).value());
    const intcval = parseInt(numeral(cval).value());

    var phoneRex = /^[0-9.]+$/;

    if (phoneRex.test(event)) {
      props.onChange(numeral(event).format("0"));
      switch (cat_type) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          if (cat_type !== 5 && event_intval > intcval * 10) {
            this.setState({
              cat_minprice_State: "error",
              cat_maxprice_State: "error",
            });
            this.showNotification(
              "warning",
              "มูลค่าเกิน 10 เท่าของราคาขายต่ำสุด"
            );
            return false;
          }
          if (event_intval >= intcval) {
            this.setState({
              cat_minprice_State: "success",
              cat_maxprice_State: "success",
            });
            return true;
          }
          if (event_intval < intcval) {
            this.setState({
              cat_minprice_State: "error",
              cat_maxprice_State: "error",
            });
            return false;
          }
          return false;
        default:
          return false;
      }
    }
    props.onChange("");
    return false;
  };

  cat_discountHome = (event, props) => {
    const val = event;

    var phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0"));
      return true;
    }
    if (val === "") {
      props.onChange("");
      return true;
    }
    props.onChange("");
    return false;
  };

  cat_allunitinprojectHome = (event, props) => {
    const val = event;

    var phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0"));
      return true;
    }
    props.onChange("");
    return false;
  };

  between(x, min, max) {
    return x >= min && x <= max;
  }

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  resetAll_State() {
    this.setState({
      cat_type_State: "",
      cat_floor_State: "",
      cat_width_State: "",
      cat_minarea_State: "",
      cat_maxarea_State: "",
      cat_minuseablearea_State: "",
      cat_maxuseablearea_State: "",
      cat_bedroom_State: "",
      cat_bathroom_State: "",
      cat_minprice_State: "",
      cat_maxprice_State: "",
      cat_discount_State: "",
      cat_allunitinproject_State: "",
    });
  }

  errorAll_State(data) {
    switch (data) {
      case 4:
        this.setState({
          cat_floor_State: "error",
          cat_width_State: "error",
          cat_minarea_State: "error",
          cat_maxarea_State: "error",
          cat_minuseablearea_State: "error",
          cat_maxuseablearea_State: "error",
          cat_bedroom_State: "",
          cat_bathroom_State: "error",
          cat_minprice_State: "error",
          cat_maxprice_State: "error",
          cat_discount_State: "",
          cat_allunitinproject_State: "error",
        });
        break;
      case 5:
        this.setState({
          cat_floor_State: "",
          cat_width_State: "",
          cat_minarea_State: "error",
          cat_maxarea_State: "error",
          cat_minuseablearea_State: "",
          cat_maxuseablearea_State: "",
          cat_bedroom_State: "",
          cat_bathroom_State: "",
          cat_minprice_State: "error",
          cat_maxprice_State: "error",
          cat_discount_State: "",
          cat_allunitinproject_State: "error",
        });
        break;
      default:
        this.setState({
          cat_floor_State: "error",
          cat_width_State: "error",
          cat_minarea_State: "error",
          cat_maxarea_State: "error",
          cat_minuseablearea_State: "error",
          cat_maxuseablearea_State: "error",
          cat_bedroom_State: "error",
          cat_bathroom_State: "error",
          cat_minprice_State: "error",
          cat_maxprice_State: "error",
          cat_discount_State: "",
          cat_allunitinproject_State: "error",
        });
        break;
    }
  }

  checkDataInRowBeforeAdd = (data) => {
    const cat_type = parseInt(data.cat_type);

    switch (cat_type) {
      case 1:
      case 2:
      case 3:
        // check case for type 1 2 3
        if (data.cat_floor === "0" || data.cat_floor === "") {
          this.setState({ cat_floor_State: "error" });
        }
        if (data.cat_width === "0" || data.cat_width === "") {
          this.setState({ cat_width_State: "error" });
        }
        if (data.cat_minarea === "0" || data.cat_minarea === "") {
          this.setState({ cat_minarea_State: "error" });
        }
        if (data.cat_maxarea === "0" || data.cat_maxarea === "") {
          this.setState({ cat_maxarea_State: "error" });
        }
        if (data.cat_minuseablearea === "0" || data.cat_minuseablearea === "") {
          this.setState({ cat_minuseablearea_State: "error" });
        }
        if (data.cat_maxuseablearea === "0" || data.cat_maxuseablearea === "") {
          this.setState({ cat_maxuseablearea_State: "error" });
        }
        if (data.cat_bedroom === "0" || data.cat_bedroom === "") {
          this.setState({ cat_bedroom_State: "error" });
        }
        if (data.cat_bathroom === "0" || data.cat_bathroom === "") {
          this.setState({ cat_bathroom_State: "error" });
        }
        if (data.cat_minprice === "0" || data.cat_minprice === "") {
          this.setState({ cat_minprice_State: "error" });
        }
        if (data.cat_maxprice === "0" || data.cat_maxprice === "") {
          this.setState({ cat_maxprice_State: "error" });
        }
        if (
          data.cat_allunitinproject === "0" ||
          data.cat_allunitinproject === ""
        ) {
          this.setState({ cat_allunitinproject_State: "error" });
        }

        if (
          this.state.cat_type_State === "error" ||
          this.state.cat_floor_State === "error" ||
          this.state.cat_width_State === "error" ||
          this.state.cat_minarea_State === "error" ||
          this.state.cat_maxarea_State === "error" ||
          this.state.cat_minuseablearea_State === "error" ||
          this.state.cat_maxuseablearea_State === "error" ||
          this.state.cat_bedroom_State === "error" ||
          this.state.cat_bathroom_State === "error" ||
          this.state.cat_minprice_State === "error" ||
          this.state.cat_maxprice_State === "error" ||
          this.state.cat_allunitinproject_State === "error"
        ) {
          return false;
        } else {
          return true;
        }
      case 4:
        // check case for type 1 2 3 4
        if (data.cat_floor === "0" || data.cat_floor === "") {
          this.setState({ cat_floor_State: "error" });
        }
        if (data.cat_width === "0" || data.cat_width === "") {
          this.setState({ cat_width_State: "error" });
        }
        if (data.cat_minarea === "0" || data.cat_minarea === "") {
          this.setState({ cat_minarea_State: "error" });
        }
        if (data.cat_maxarea === "0" || data.cat_maxarea === "") {
          this.setState({ cat_maxarea_State: "error" });
        }
        if (data.cat_minuseablearea === "0" || data.cat_minuseablearea === "") {
          this.setState({ cat_minuseablearea_State: "error" });
        }
        if (data.cat_maxuseablearea === "0" || data.cat_maxuseablearea === "") {
          this.setState({ cat_maxuseablearea_State: "error" });
        }
        if (data.cat_bedroom === "") {
          this.setState({ cat_bedroom_State: "error" });
        }
        if (data.cat_bathroom === "0" || data.cat_bathroom === "") {
          this.setState({ cat_bathroom_State: "error" });
        }
        if (data.cat_minprice === "0" || data.cat_minprice === "") {
          this.setState({ cat_minprice_State: "error" });
        }
        if (data.cat_maxprice === "0" || data.cat_maxprice === "") {
          this.setState({ cat_maxprice_State: "error" });
        }
        if (
          data.cat_allunitinproject === "0" ||
          data.cat_allunitinproject === ""
        ) {
          this.setState({ cat_allunitinproject_State: "error" });
        }

        if (
          this.state.cat_type_State === "error" ||
          this.state.cat_floor_State === "error" ||
          this.state.cat_width_State === "error" ||
          this.state.cat_minarea_State === "error" ||
          this.state.cat_maxarea_State === "error" ||
          this.state.cat_minuseablearea_State === "error" ||
          this.state.cat_maxuseablearea_State === "error" ||
          this.state.cat_bedroom_State === "error" ||
          this.state.cat_bathroom_State === "error" ||
          this.state.cat_minprice_State === "error" ||
          this.state.cat_maxprice_State === "error" ||
          this.state.cat_allunitinproject_State === "error"
        ) {
          return false;
        } else {
          return true;
        }
      case 5:
        // check case for type 5
        if (data.cat_minarea === "0" || data.cat_minarea === "") {
          this.setState({ cat_minarea_State: "error" });
        }
        if (data.cat_maxarea === "0" || data.cat_maxarea === "") {
          this.setState({ cat_maxarea_State: "error" });
        }
        if (data.cat_minprice === "0" || data.cat_minprice === "") {
          this.setState({ cat_minprice_State: "error" });
        }
        if (data.cat_maxprice === "0" || data.cat_maxprice === "") {
          this.setState({ cat_maxprice_State: "error" });
        }
        if (
          data.cat_allunitinproject === "0" ||
          data.cat_allunitinproject === ""
        ) {
          this.setState({ cat_allunitinproject_State: "error" });
        }

        if (
          this.state.cat_type_State === "error" ||
          this.state.cat_minarea_State === "error" ||
          this.state.cat_maxarea_State === "error" ||
          this.state.cat_minprice_State === "error" ||
          this.state.cat_maxprice_State === "error" ||
          this.state.cat_allunitinproject_State === "error"
        ) {
          return false;
        } else {
          return true;
        }
      default:
        this.setState({ cat_type_State: "error" });
        return false;
    }
  };

  checkDataInRowBeforeChange = (oldData, newData) => {
    const new_cat_type = parseInt(newData.cat_type);

    switch (new_cat_type) {
      case 1:
      case 2:
      case 3:
      case 4:
        break;
      case 5:
        if (newData.cat_type === oldData.cat_type) {
          this.setState({ cat_type_State: "success" });
        }
        if (newData.cat_minarea === oldData.cat_minarea) {
          this.setState({ cat_minarea_State: "success" });
        }
        if (newData.cat_maxarea === oldData.cat_maxarea) {
          this.setState({ cat_maxarea_State: "success" });
        }
        if (newData.cat_minprice === oldData.cat_minprice) {
          this.setState({ cat_minprice_State: "success" });
        }
        if (newData.cat_maxprice === oldData.cat_maxprice) {
          this.setState({ cat_maxprice_State: "success" });
        }
        if (newData.cat_allunitinproject === oldData.cat_allunitinproject) {
          this.setState({ cat_allunitinproject_State: "success" });
        }
        break;
      default:
        return false;
    }
  };

  sumallUnit(unit) {
    const arr = unit || [];
    let sum_unit = 0;
    for (let i = 0; i < arr.length; i++) {
      sum_unit = parseInt(sum_unit) + parseInt(arr[i].cat_allunitinproject);
    }

    this.setState({
      sumUnit: sum_unit,
    });
  }
  isResubmiting = () => {
    return this.state.projectStatus === 2 || this.state.projectStatus === "2";
  };
  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };
  blockEdit(isEdit) {
    if (isEdit) {
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "10000",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }

  render() {
    var self = this;
    const bt_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "0.2rem",
      backgroundColor: "#384D97",
      color: "white",
    };
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      maxWidth: "fit-content",
      borderLeft: "none",
      width: "8px",
      fontSize: "14px",
    };
    const StyleTHeader = {
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
      fontSize: "14px",
    };

    return (
      <GridContainer style={{ padding: "15px" }}>
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>ประเภทบ้านในโครงการ</b>
          </h4>
          {this.state.alert}
          {this.state.deleteAlert}
          <MaterialTable
            style={{
              width: "100%",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td rowSpan="2" style={StyleHeader}>
                      ประเภทบ้าน
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      จำนวนชั้น
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      บ้านหน้ากว้าง
                    </td>
                    <td colSpan="2" style={StyleHeader}>
                      เนื้อที่ดิน
                      <br />
                      (ตร.ว.)
                    </td>
                    <td colSpan="2" style={StyleHeader}>
                      พื้นที่ใช้สอย
                      <br />
                      (ตร.ม.)
                    </td>

                    <td rowSpan="2" style={StyleHeader}>
                      จำนวน
                      <br />
                      ห้องนอน
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      จำนวน
                      <br />
                      ห้องน้ำ
                    </td>
                    <td colSpan="2" style={StyleHeader}>
                      ราคาขาย/ยูนิต
                    </td>

                    <td rowSpan="2" style={StyleHeader}>
                      ส่วนลด
                    </td>
                    <td colSpan="1" style={StyleHeader}>
                      หน่วยในผัง
                      <br />
                      โครงการบ้านทั้งหมด
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      การจัดการ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1" style={StyleHeader}>
                      ต่ำสุด
                    </td>
                    <td colSpan="1" style={StyleHeader}>
                      สูงสุด
                    </td>
                    <td colSpan="1" style={StyleHeader}>
                      ต่ำสุด
                    </td>
                    <td colSpan="1" style={StyleHeader}>
                      สูงสุด
                    </td>
                    <td
                      colSpan="1"
                      style={{ ...StyleHeader, minWidth: "90px" }}
                    >
                      ต่ำสุด
                    </td>
                    <td
                      colSpan="1"
                      style={{ ...StyleHeader, minWidth: "90px" }}
                    >
                      สูงสุด
                    </td>
                    <td colSpan="1" style={StyleHeader}>
                      รวมทั้งหมด: {numeral(this.state.sumUnit).format("0,0")}
                    </td>
                  </tr>
                </thead>
              ),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "แถว",
                labelDisplayedRows: " {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            title=""
            columns={this.state.column}
            data={this.state.cat_detail}
            options={{
              pageSize: this.state.cat_detail
                ? this.state.cat_detail.length
                : 10,
              pageSizeOptions: [
                10,
                20,
                30,
                this.state.cat_detail ? this.state.cat_detail.length : 50,
              ],
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0
                    ? "rgba(0, 0, 0, 0.03)"
                    : "#FFF",
                textAlign: "center",
              }),
              headerStyle: {
                fontSize: "0.9rem",
                fontWeight: "600",
              },
            }}
            icons={{
              Add: () => (
                <div style={bt_style}>
                  <Add style={{ marginRight: "10px" }} />
                  เพิ่มข้อมูล
                </div>
              ),
            }}
            editable={
              !(
                this.state.projectStatus === 1 ||
                this.state.projectStatus === 3 ||
                this.state.memberStatus === 2
              )
                ? {
                    onRowAdd: (newData) => {
                      return new Promise(async (resolve, reject) => {
                        await this.successAlert(
                          "ยืนยันการเพิ่มข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              // not allow same type
                              if (
                                this.state.cat_detail.filter(
                                  (row) =>
                                    row.cat_type + "" ===
                                      newData.cat_type + "" &&
                                    row.cat_floor + "" ===
                                      newData.cat_floor + "" &&
                                    row.cat_width + "" ===
                                      newData.cat_width + ""
                                ).length > 0
                              ) {
                                this.showNotification(
                                  "warning",
                                  "ประเภทบ้าน-จำนวนชั้น-บ้านหน้ากว้างนี้มีในตารางแล้ว"
                                );
                                return reject();
                              }
                              if (newData.cat_type === "5") {
                                newData.cat_floor = "0";
                                newData.cat_width = "0";
                                newData.cat_minuseablearea = "0";
                                newData.cat_maxuseablearea = "0";
                                newData.cat_bedroom = "0";
                                newData.cat_bathroom = "0";
                              }

                              if (self.checkDataInRowBeforeAdd(newData)) {
                                setTimeout(() => {
                                  {
                                    const cat_detail = this.state.cat_detail;
                                    newData.id = cat_detail.length;
                                    cat_detail.push(newData);
                                    this.setState({ cat_detail }, () => {
                                      resolve();
                                    });

                                    self.sumallUnit(cat_detail);
                                    self.resetAll_State();
                                  }
                                  resolve();
                                  // console.log('wipe part 3');
                                  self.props.house_sec3Object([]);
                                  self.props.householdSec3_Object([]);
                                  this.deleteSection();
                                }, 1000);
                              } else {
                                this.showNotification(
                                  "warning",
                                  "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                );
                                reject();
                                self.setState({ alert: null });
                              }
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                    onRowUpdate: (newData, oldData) => {
                      return new Promise(async (resolve, reject) => {
                        await this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              // not allow same type
                              if (
                                this.state.cat_detail.filter(
                                  (row, index) =>
                                    row.cat_type + "" ===
                                      newData.cat_type + "" &&
                                    row.cat_floor + "" ===
                                      newData.cat_floor + "" &&
                                    row.cat_width + "" ===
                                      newData.cat_width + "" &&
                                    index !==
                                      this.state.cat_detail.indexOf(oldData)
                                ).length > 0
                              ) {
                                this.showNotification(
                                  "warning",
                                  "ประเภทบ้าน-จำนวนชั้น-บ้านหน้ากว้างนี้มีในตารางแล้ว"
                                );
                                return reject();
                              }
                              if (newData.cat_type === "5") {
                                newData.cat_floor = "0";
                                newData.cat_width = "0";
                                newData.cat_minuseablearea = "0";
                                newData.cat_maxuseablearea = "0";
                                newData.cat_bedroom = "0";
                                newData.cat_bathroom = "0";
                              }
                              if (self.checkDataInRowBeforeAdd(newData)) {
                                setTimeout(() => {
                                  {
                                    const cat_detail = this.state.cat_detail;
                                    const index = cat_detail.indexOf(oldData);
                                    cat_detail[index] = newData;
                                    this.setState({ cat_detail }, () => {
                                      resolve();
                                    });
                                    self.sumallUnit(cat_detail);
                                    self.resetAll_State();
                                  }
                                  resolve();
                                  // console.log('wipe part 3');
                                  self.props.house_sec3Object([]);
                                  self.props.householdSec3_Object([]);
                                  this.deleteSection();
                                }, 1000);
                              } else {
                                this.showNotification(
                                  "warning",
                                  "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                );
                                reject();
                                self.setState({ alert: null });
                              }
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                    onRowDelete: (oldData) => {
                      return new Promise(async (resolve, reject) => {
                        await this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });

                              setTimeout(() => {
                                {
                                  let cat_detail = this.state.cat_detail;
                                  const index = cat_detail.indexOf(oldData);
                                  cat_detail.splice(index, 1);
                                  this.setState({ cat_detail }, () => {
                                    resolve();
                                  });

                                  self.resetAll_State();
                                }
                                resolve();
                                // console.log('wipe part 3');
                                self.props.house_sec3Object([]);
                                self.props.householdSec3_Object([]);
                              }, 1000);
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                  }
                : {}
            }
          />
        </GridItem>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "0 15px",
            marginTop: "10px",
          }}
        >
          {(this.state.projectStatus !== 3 ||
            this.state.view_psurvey === false) &&
          this.isEditable() &&
          !this.isResubmiting() ? (
            <Button
              color="info"
              size="sm"
              style={{ fontSize: "16px" }}
              onClick={() => this.submit_save()}
              disabled={this.state.btLoading}
            >
              {this.loadingButton(this.state.btLoading)}
              บันทึก
            </Button>
          ) : null}
        </div>
      </GridContainer>
    );
  }
}

HouseStep2.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  house_sec2Object: (payload) => dispatch(house_sec2Object(payload)),
  houseTypeListObject: (payload) => dispatch(houseTypeListObject(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  householdSec2_Object: (payload) => dispatch(householdSec2_Object(payload)),
  householdSec3_Object: (payload) => dispatch(householdSec3_Object(payload)),
  house_sec3Object: (payload) => dispatch(house_sec3Object(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(HouseStep2));
