/* eslint-disable no-console */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";
import { connect } from "react-redux";
import * as config from "configure/configServer.js";
import io from "socket.io-client";
import tokenSession from "actions/tokenSession.js";
import setLoading from "actions/setLoading.js";
import setLanguage from "actions/setLanguage";
import adminModal from "actions/adminModal";
// import { Manager, Target, Popper } from "react-popper";
import WizardHouse from "views/PSurvey/wizard_house.js";
import WizardCondo from "views/PSurvey/wizard_condo.js";
import WizardVilla from "views/PSurvey/wizard_villa.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

import SweetAlert from "react-bootstrap-sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";

// switch language function
import switchIntl from "configure/switchIntl.js";

// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import setProjectid from "actions/survey/setProjectId.js";
import setModalType from "actions/setModalType.js";
import setProjectIDHome from "actions/setProjectIDHome.js";
import setApproved from "actions/setApproved.js";
import surveyType from "actions/survey/setSurveyType.js";
import toggleSurvey from "actions/survey/toggleSurvey.js";
import setLatlon from "actions/survey/setLatlon.js";
import oldproject_data from "actions/survey/oldproject_data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import loading_datatable from "actions/survey/loading_datatable.js";

import GHBankLoading from "assets/img/reicLoading.gif";

class HeaderLinks extends React.Component {
  static propTypes = {
    tokenSess: PropTypes.string.isRequired,
    tokenSession: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    setLoading: PropTypes.object.isRequired,
    setLanguage: PropTypes.string.isRequired,
    adminModal: PropTypes.object.isRequired,
  };
  state = {
    openNotification: false,
    openProfile: false,
    isLoading: false,
    //countNoti: [{nameth:'1234',projectid:'623-1H-10-11812',approved:'1'}],
    countNoti: [],
    loadingScreen: false,
  };
  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };
  componentDidMount() {
    this.props.setLoading(false);
    this.getSecretKey();
  }
  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }
  countNoti(data) {
    let noti_array = this.state.countNoti;
    noti_array.push(data[0]);
    this.setState({
      countNoti: noti_array,
    });
  }
  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };
  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };
  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  handleLogout = () => {
    this.props.adminModal(
      <SweetAlert
        type="warning"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        customClass="modalContent"
        title={switchIntl(this.props.toggleLng).sweetalert.onLogout.title}
        onConfirm={() => this.confirmLogout()}
        onCancel={() => this.props.adminModal(null)}
        confirmBtnCssClass="modalBt"
        cancelBtnCssClass="modalBt modalBtCancel"
        confirmBtnText={
          switchIntl(this.props.toggleLng).sweetalert.onLogout.conBtTxt
        }
        cancelBtnText={
          switchIntl(this.props.toggleLng).sweetalert.onLogout.canBtTxt
        }
        showCancel={true}
      >
        {switchIntl(this.props.toggleLng).sweetalert.onLogout.content}
      </SweetAlert>
    );
  };

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        //console.log(error);
      });
    setTimeout(() => {
      const socket = io.connect(config.requestUrl, {
        transports: ["websocket", "polling"],
        forceNew: true,
      });

      socket.on("connect", () => {
        const that = this;
        //const memID = this.state.member_id;

        socket.on("admin", function(data) {
          //console.log(data)
          that.countNoti(data);
        });
      });
    }, 1000);
  };

  confirmLogout() {
    //console.log("confirmLogout")
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    var iv;
    try {
      iv = CryptoJS.enc.Base64.parse(key);
    } catch {
      this.props.adminModal(
        <SweetAlert
          type="success"
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          onConfirm={() => undefined}
          customClass="modalContent"
          title={
            switchIntl(this.props.toggleLng).sweetalert.confirmLogout.title
          }
          showCancel={false}
          showConfirm={false}
        >
          {switchIntl(this.props.toggleLng).sweetalert.confirmLogout.content}
        </SweetAlert>
      );
      setTimeout(() => {
        this.props.adminModal(null);
      }, 1000);
      setTimeout(() => {
        this.props.setLoading(true);
      }, 1000);
      this.props.tokenSession("");
      localStorage.removeItem("session");
      setTimeout(() => {
        window.location.replace("/auth/login");
      }, 2000);
      return;
    }
    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    //console.log('log out')
    var request = new Request(`${config.API_logout}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson)
        if (responseJson.code === 200) {
          this.props.adminModal(
            <SweetAlert
              type="success"
              style={{
                display: "block",
                color: "black",
                fontSize: "18px",
              }}
              onConfirm={() => undefined}
              customClass="modalContent"
              title={
                switchIntl(this.props.toggleLng).sweetalert.confirmLogout.title
              }
              showCancel={false}
              showConfirm={false}
            >
              {
                switchIntl(this.props.toggleLng).sweetalert.confirmLogout
                  .content
              }
            </SweetAlert>
          );
          setTimeout(() => {
            this.props.adminModal(null);
          }, 1000);
          setTimeout(() => {
            this.props.setLoading(true);
          }, 1000);
          this.props.tokenSession("");
          localStorage.removeItem("session");
          setTimeout(() => {
            window.location.replace("/auth/login");
          }, 2000);
        } else {
          this.props.adminModal(
            <SweetAlert
              type="success"
              style={{
                display: "block",
                color: "black",
                fontSize: "18px",
              }}
              onConfirm={() => undefined}
              customClass="modalContent"
              title={
                switchIntl(this.props.toggleLng).sweetalert.confirmLogout.title
              }
              showCancel={false}
              showConfirm={false}
            >
              {
                switchIntl(this.props.toggleLng).sweetalert.confirmLogout
                  .content
              }
            </SweetAlert>
          );
          setTimeout(() => {
            this.props.adminModal(null);
          }, 1000);
          setTimeout(() => {
            this.props.setLoading(true);
          }, 1000);
          this.props.tokenSession("");
          localStorage.removeItem("session");
          setTimeout(() => {
            window.location.replace("/auth/login");
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  handleProfile = () => {
    this.props.history.push("/user-page");
  };
  goToSurvey = (projectid, approved) => {
    this.setState({ loadingScreen: true });
    //console.log('goto'+projectid)
    let form = "";
    if (projectid.substring(6, 7) === "H") {
      form = "household";
    } else if (projectid.substring(6, 7) === "C") {
      form = "condo";
    } else if (projectid.substring(6, 7) === "V") {
      form = "villa";
    }

    const info = {
      [form + "_projectid"]: projectid,
      [form + "_approved"]: 3,
    };
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    let form_call = ``;
    if (form === "household") {
      form_call = config.API_showdetail_projectid_household;
    } else if (form === "condo") {
      form_call = config.API_showdetail_projectid_condo;
    } else if (form === "villa") {
      form_call = config.API_showdetail_projectid_villa;
    }

    var request = new Request(form_call, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(info),
    });
    //console.log(info)
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ loadingScreen: false });
        //console.log(responseJson)
        const body = {
          ...responseJson,
        };
        if (responseJson.data.length === 0) {
          //alert("No data in this project id");
          this.setState({
            popperOpen: false,
            old_projectidState: "error",
            old_projectid: "",
          });
        } else {
          this.setState({
            household_oldprojid: projectid,
          });
          this.props.setProjectid(projectid);
          if (
            responseJson.data.length > 0 &&
            responseJson.data[0] instanceof Object &&
            "lat" in responseJson.data[0] &&
            "lon" in responseJson.data[0]
          ) {
            let popLocation = {
              lat: parseFloat(responseJson.data[0].lat.toFixed(6)),
              lng: parseFloat(responseJson.data[0].lon.toFixed(6)),
            };
            this.props.setLatlon(popLocation);
          }
          this.props.oldproject_data(responseJson.data[0]);
          this.props.project_datatable(body);
          this.props.surveyType(form);
          this.props.toggleSurvey(true);
          this.props.loading_datatable(false);
        }

        if (
          responseJson.code === 200 &&
          "data" in responseJson &&
          responseJson.data.length > 0
        ) {
          that.setState({ curPsurvey: responseJson });
          if ("bs" in responseJson) {
            that.setState({ bs: responseJson.bs });
          }
          if ("sellroom" in responseJson) {
            that.setState({ sellroom: responseJson.sellroom });
          }
          if ("st" in responseJson) {
            that.setState({ st: responseJson.st });
          }
          if ("cat" in responseJson) {
            that.setState({ cat: responseJson.cat });
          }
          if ("stc" in responseJson) {
            that.setState({ stc: responseJson.stc });
          }

          that.setState({ ...responseJson.data[0] }, () =>
            this.setState({ psurveyDataModal: projectid })
          );
        } else {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว"
              : "Show Project Detail Failed",
            that.props.toggleLng === "TH"
              ? "แสดงข้อมูลโครงการล้มเหลว หรือ ข้อมูลว่างเปล่า"
              : "Show Project Detail Failed",
            { warning: true }
          );
          // that.successAlert(that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",that.props.toggleLng==='TH' ?"โครงการอนุมัติล้มเหลว":"Project Approval Failed",{warning:true});
        }
      })
      .catch((error) => {
        that.setState({ loadingScreen: false });

        that.successAlert(
          that.props.toggleLng === "TH"
            ? "แสดงข้อมูลโครงการล้มเหลว"
            : "Show Project Detail Failed",
          that.props.toggleLng === "TH"
            ? "แสดงข้อมูลโครงการล้มเหลว"
            : "Show Project Detail Failed",
          { warning: true }
        );
        // console.log(error);
      });
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
          // onCancel={() => this.hideA}
          // confirmBtnCssClass={}
        >
          {message}
        </SweetAlert>
      ),
    });
  }
  checkSurveyType = (type) => {
    // console.log(type)
    if (type === "household") {
      return <WizardHouse />;
    } else if (type === "condo") {
      return <WizardCondo />;
    } else if (type === "villa") {
      return <WizardVilla />;
      // return <WizardHouse />;
      // alert('hi')
      // return null;
    } else {
      return null;
    }
  };
  render() {
    const { classes, rtlActive } = this.props;
    const { openNotification, openProfile, countNoti } = this.state;
    // const searchButton =
    //   classes.top +
    //   " " +
    //   classes.searchButton +
    //   " " +
    //   classNames({
    //     [classes.searchRTL]: rtlActive
    //   });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    let optionItems = this.state.countNoti.map((com, index) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={index}
        value={com.projectid}
        style={{
          color: com.approved === 1 ? "green" : "red",
          // backgroundColor: "#fff"
        }}
      >
        <div
          style={{ color: "black" }}
          onClick={() => this.goToSurvey(com.projectid, com.approved)}
        >
          {"ได้รับโครงการใหม่ " + com.projectid + " ชื่อ " + com.nameth}
        </div>
      </MenuItem>
    ));
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });
    return (
      <div className={wrapper}>
        {this.loadingScreen(this.state.loadingScreen)}
        {this.state.alert}
        {(() => {
          if (
            this.state.psurveyDataModal === undefined ||
            this.state.psurveyDataModal === false
              ? false
              : true
          ) {
            return (
              <Dialog
                fullScreen
                open={
                  this.state.psurveyDataModal === undefined ||
                  this.state.psurveyDataModal === false
                    ? false
                    : true
                }
                transition={Transition}
                style={{ width: "100%" }}
                keepMounted
                onClose={() =>
                  this.setState({
                    psurveyDataModal: false,
                    psurvey_approval: "0",
                    cat: false,
                    stc: false,
                    bs: false,
                    sellroom: false,
                    st: false,
                  })
                }
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
              >
                <DialogContent
                  style={{ backgroundColor: "#ffffff" }}
                  id="modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    onClick={() =>
                      this.setState({
                        psurveyDataModal: false,
                        psurvey_approval: "0",
                        cat: false,
                        stc: false,
                        bs: false,
                        sellroom: false,
                        st: false,
                      })
                    }
                    justIcon
                    round
                    color="white"
                    style={{
                      position: "absolute",
                      right: "50px",
                      zIndex: "100",
                    }}
                  >
                    <Close className={classes.icons} />
                  </Button>
                  <br />

                  {
                    //form psurvey
                  }
                  {this.checkSurveyType(this.props.surveytype)}
                  <br />
                  <div>
                    <Button onClick={() => this.submitPsurveyApproval()}>
                      {
                        switchIntl(this.props.toggleLng).project
                          .submitbuttonlabel
                      }
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            );
          }
        })()}

        {/* <CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: rtlActive ? "بحث" : "Search",
            inputProps: {
              "aria-label": rtlActive ? "بحث" : "Search",
              className: classes.searchInput
            }
          }}
        />
        <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button> */}
        {/* <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Dashboard
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "لوحة القيادة" : "Dashboard"}
            </span>
          </Hidden>
        </Button> */}
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={() => this.props.setLanguage("TH")}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: this.props.toggleLng === "TH" ? "600" : "normal",
              }}
            >
              ไทย
            </span>
          </Button>
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={() => this.props.setLanguage("EN")}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: this.props.toggleLng === "EN" ? "600" : "normal",
              }}
            >
              ENG
            </span>
          </Button>
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>{countNoti.length}</span>
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}
              >
                {switchIntl(this.props.toggleLng).basic_word.notification}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openNotification}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                    <MenuList role="menu">{optionItems}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openNotification ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorProfile = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickProfile}
                className={classes.linkText}
              >
                {switchIntl(this.props.toggleLng).basic_word.profile}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleProfile}
                        className={dropdownItem}
                      >
                        <div style={{ color: "black" }}>
                          {switchIntl(this.props.toggleLng).basic_word.profile}
                        </div>
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleLogout}
                        className={dropdownItem}
                      >
                        <div style={{ color: "black" }}>
                          {switchIntl(this.props.toggleLng).basic_word.logout}
                        </div>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLoading: (bool) => dispatch(setLoading(bool)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  adminModal: (islogout) => dispatch(adminModal(islogout)),
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
  surveyType: (payload) => dispatch(surveyType(payload)),
  setModalType: (payload) => dispatch(setModalType(payload)),
  setProjectIDHome: (payload) => dispatch(setProjectIDHome(payload)),
  setApproved: (payload) => dispatch(setApproved(payload)),
  oldproject_data: (payload) => dispatch(oldproject_data(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  loading_datatable: (payload) => dispatch(loading_datatable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks)));
