import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { API_get_project_status, API_gen_secretkey } from "configure/configServer.js";
import CryptoJS from "crypto-js";

//redux
import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";
import tokenSession from "actions/tokenSession.js";
import setProjectStatus from "actions/setProjectStatus.js";

class ProjectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEnabled: 1,
      label: [
        "โครงการปิดการขาย",
        "อยู่ระหว่างขาย",
        "เปิดขายใหม่ในช่วงสำรวจ",
        "โครงการในอนาคต",
        "โครงการชะลอการขาย",
        "โครงการเปิดและปิดการขายในช่วงสำรวจ",
      ],
      status: [],
      tokenData: null,
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.getProjectStatus = this.getProjectStatus.bind(this);
  }

  componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });
          this.getProjectStatus();
        }
      })
      .catch((err) => { });
  };

  handleChangeEnabled(value) {
    this.setState({ selectedEnabled: value });
    this.props.setProjectStatus(value);
  }

  getProjectStatus() {
    let self = this;
    var request = new Request(API_get_project_status, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.code === 200) {
          let status = data.data;
          self.setState({
            status: status,
          });
        }
      });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    let status = this.state.status.filter((props) => {
      return props.cstats_id === 1 || props.cstats_id === 2;
    });

    let optionItems = status.map((props) => (
      <div
        key={props.cstats_id}
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={
                this.props.selectedStatus !== null
                  ? this.props.selectedStatus === props.cstats_id
                  : this.state.selectedEnabled === props.cstats_id
              }
              onChange={() => this.handleChangeEnabled(props.cstats_id)}
              value={props.cstats_id}
              name="project status"
              aria-label={props.cstats_id}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label={props.cstats_name}
        />
      </div>
    ));

    return (
      <div>
        <h4>สถานะโครงการ</h4>
        {optionItems}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setProjectStatus: (payload) => dispatch(setProjectStatus(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProjectStatus));
