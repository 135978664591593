import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";
import io from "socket.io-client";
import Idle from "react-idle";
import Timer from "react-compound-timer";
import * as config from "configure/configServer.js";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";

import timeoutModal from "actions/timeoutModal.js";

// switch language function
import switchIntl from "configure/switchIntl.js";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import loadingScreen from "../views/Components/Loading.js";
import numberNoti from "actions/survey/count_noti.js";

import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    image: require("assets/img/sidebar-2.jpg"),
    color: "white",
    bgColor: "white",
    hasImage: true,
    fixedClasses: "dropdown",
    logo: require("assets/img/logo-white.svg"),
    idleModal: null,
    getKey: undefined,
    noti_number: [],
  };

  static propTypes = {
    loadingSet: PropTypes.string.isRequired,
    toggleLng: PropTypes.string.isRequired,
    logoutModal: PropTypes.object.isRequired,
    timeoutModal: PropTypes.object.isRequired,
    sessionModal: PropTypes.object.isRequired,
  };

  mainPanel = React.createRef();

  handleSession_modal() {
    this.props.timeoutModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => undefined}
        customClass="modalContent"
        title={switchIntl(this.props.toggleLng).sweetalert.timeoutModal.title}
        showCancel={false}
        showConfirm={false}
      >
        {switchIntl(this.props.toggleLng).sweetalert.timeoutModal.content}
      </SweetAlert>
    );
  }

  componentDidMount() {
    var that = this;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);

    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson)
        if (responseJson.code === 200) {
          this.setState({
            getKey: responseJson.data,
          });
          //console.log("pre connect")
          const socket = io.connect(`${config.requestUrl}`, {
            transports: ["websocket", "polling", "flashsocket"],
          });
          // socket.on('disconnect', function () {
          //   //console.log("disconnect")
          //   that.handleSession_modal();
          //       localStorage.removeItem("session");
          //       setTimeout(() => {
          //         window.location.replace("/auth/");
          //       }, 1500);
          // });

          // socket.on('error', function () {
          //   //console.log("error")
          //   that.handleSession_modal();
          //       localStorage.removeItem("session");
          //       setTimeout(() => {
          //         window.location.replace("/auth/");
          //       }, 1500);
          // });
          try {
            socket.on("connect", () => {
              //console.log("connect")
              const key = this.state.getKey;
              if (!key) {
                //console.log("bye")
                that.handleSession_modal();
                localStorage.removeItem("session");
                setTimeout(() => {
                  window.location.replace("/auth/");
                }, 1500);
                return;
              }
              const keyutf = CryptoJS.enc.Utf8.parse(key);
              const iv = CryptoJS.enc.Base64.parse(key);

              var aesStr = localStorage.getItem("session");
              if (!aesStr) {
                //console.log("bye")
                that.handleSession_modal();
                localStorage.removeItem("session");
                setTimeout(() => {
                  window.location.replace("/auth/");
                }, 1500);
                return;
              }
              const dec = CryptoJS.AES.decrypt(
                { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
                keyutf,
                {
                  iv: iv,
                }
              );

              try {
                if (!dec) {
                  throw new Error();
                }
                // console.log();
                const decStr = CryptoJS.enc.Utf8.stringify(dec);
                const jsonDec = JSON.parse(decStr);

                var member_id = jsonDec.member_id;
                var tokenData = jsonDec.token;
              } catch {
                //console.log("bye")
                that.handleSession_modal();
                localStorage.removeItem("session");
                setTimeout(() => {
                  window.location.replace("/auth/");
                }, 1500);
                return;
              }

              this.checkExpireToken(tokenData);

              socket.on(member_id, (data) => {
                if (data !== tokenData) {
                  that.handleSession_modal();
                  localStorage.removeItem("session");
                  setTimeout(() => {
                    window.location.replace("/auth/");
                  }, 1500);
                } else {
                  //
                }

                socket.on("admin", function (data) {
                  //console.log(data)
                  that.showNotification(data);
                });
              });
            });
          } catch {
            //console.log("error")
            that.handleSession_modal();
            localStorage.removeItem("session");
            setTimeout(() => {
              window.location.replace("/auth/");
            }, 1500);
          }
        }
      })
      .catch((error) => {
        // console.log(error);
        that.handleSession_modal();
        localStorage.removeItem("session");
        setTimeout(() => {
          window.location.replace("/auth/");
        }, 1500);
      });
  }
  checkExpireToken(token) {
    //console.log('checkExpireToken')
    var request = new Request(config.API_exptoken, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "token not exp") {
          //
        } else {
          this.expireModal();
        }
      })
      .catch(() => {
        //console.log("error");
      });
  }

  expireModal() {
    this.props.timeoutModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => undefined}
        customClass="modalContent"
        title={switchIntl(this.props.toggleLng).sweetalert.timeoutModal.title}
        showCancel={false}
        showConfirm={false}
      >
        {switchIntl(this.props.toggleLng).sweetalert.timeoutModal.content}
      </SweetAlert>
    );
    localStorage.removeItem("session");
    setTimeout(() => {
      window.location.replace("/auth/");
    }, 2000);
  }
  showNotification(data) {
    let noti_array = this.state.noti_number;
    noti_array.push(data);
    if (data[0].approved === 1) {
      this.setState({
        noti_number: noti_array,
        noti_message:
          "โครงการ " +
          data[0].nameth +
          " เลขแบบสอบถามที่ " +
          data[0].projectid +
          " ผ่านการอนุมัติแล้ว",
        noti_status: "success",
        noti: true,
      });
    } else {
      this.setState({
        noti_number: noti_array,
        noti_message:
          "โครงการ " +
          data[0].nameth +
          " เลขแบบสอบถามที่ " +
          data[0].projectid +
          " ไม่ผ่านการอนุมัติ",
        noti_status: "danger",
        noti: true,
      });
    }

    this.props.numberNoti(this.state.noti_number);

    setTimeout(() => {
      this.setState({
        noti: false,
      });
    }, 2500);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        this.setState({ logo: require("assets/img/logo.svg") });
        break;
      default:
        this.setState({ logo: require("assets/img/logo-white.svg") });
        break;
    }
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute = () => {
    return window.location.pathname !== "/full-screen-maps";
  };
  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return this.props.toggleLng === "TH"
            ? routes[i].thName
            : routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  checkIdle(idle) {
    if (idle === true) {
      this.setState({
        idleModal: (
          <SweetAlert
            type="warning"
            style={{
              display: "block",
              color: "black",
              fontSize: "18px",
            }}
            customClass="modalContent"
            title={switchIntl(this.props.toggleLng).sweetalert.idelModal.title}
            onConfirm={() => this.setState({ idleModal: null })}
            confirmBtnCssClass="modalBt"
            confirmBtnText={
              switchIntl(this.props.toggleLng).sweetalert.idelModal.conBtTxt
            }
            showCancel={false}
          >
            {switchIntl(this.props.toggleLng).sweetalert.idelModal.content}
            <Timer
              initialTime={30000}
              direction="backward"
              checkpoints={[{ time: 0, callback: () => this.idelLogout() }]}
            >
              {() => (
                <React.Fragment>
                  <Timer.Seconds style={{ color: "green" }} />{" "}
                  {switchIntl(this.props.toggleLng).sweetalert.idelModal.unit}
                </React.Fragment>
              )}
            </Timer>
          </SweetAlert>
        ),
      });
    }
  }
  idelLogout() {
    //console.log('log out')
    const key = this.state.getKey;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    var iv = 0;
    try {
      iv = CryptoJS.enc.Base64.parse(key);
    } catch {
      this.setState({
        idleModal: (
          <SweetAlert
            type="error"
            style={{
              display: "block",
              color: "black",
              fontSize: "18px",
            }}
            onConfirm={() => undefined}
            customClass="modalContent"
            title={
              switchIntl(this.props.toggleLng).sweetalert.timeoutModal.title
            }
            showCancel={false}
            showConfirm={false}
          >
            {switchIntl(this.props.toggleLng).sweetalert.timeoutModal.content}
          </SweetAlert>
        ),
      });
      localStorage.removeItem("session");
      setTimeout(() => {
        window.location.replace("/auth/");
      }, 1500);
      return;
    }
    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    var tokenData = jsonDec.token;

    var request = new Request(`${config.API_logout}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson)
        if (responseJson.code === 200) {
          this.setState({
            idleModal: (
              <SweetAlert
                type="error"
                style={{
                  display: "block",
                  color: "black",
                  fontSize: "18px",
                }}
                onConfirm={() => undefined}
                customClass="modalContent"
                title={
                  switchIntl(this.props.toggleLng).sweetalert.timeoutModal.title
                }
                showCancel={false}
                showConfirm={false}
              >
                {
                  switchIntl(this.props.toggleLng).sweetalert.timeoutModal
                    .content
                }
              </SweetAlert>
            ),
          });
          localStorage.removeItem("session");
          setTimeout(() => {
            window.location.replace("/auth/");
          }, 1500);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <Idle
        timeout={3600 * 1000}
        onChange={({ idle }) => this.checkIdle(idle)}
        // eslint-disable-next-line no-unused-vars
        render={({ idle }) => (
          <div className={classes.wrapper}>
            {loadingScreen(this.props.loadingSet)}
            {this.props.logoutModal}
            {this.props.sessionModal}
            {this.state.idleModal}
            <Sidebar
              routes={routes}
              logoText={"Creative Tim"}
              logo={this.state.logo}
              image={this.state.image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color={this.state.color}
              bgColor={this.state.bgColor}
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref={this.mainPanel}>
              <AdminNavbar
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                brandText={this.getActiveRoute(routes)}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {this.getRoutes(routes)}
                      <Redirect from="" to="/dashboard" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="" to="/dashboard" />
                  </Switch>
                </div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </div>
        )}
      />
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  timeoutModal: (payload) => dispatch(timeoutModal(payload)),
  numberNoti: (payload) => dispatch(numberNoti(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(Dashboard));
