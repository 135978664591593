import React from "react";
import PropTypes from "prop-types";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ClipLoader, PulseLoader } from "react-spinners";
import CryptoJS from "crypto-js";
import * as config from "configure/configServer.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import BasicInfo from "views/PSurvey/Condo/sec1_part1/basicInformation.js";
import LocationInfo from "views/PSurvey/Condo/sec1_part1/locationInformation.js";
import PublishInfo from "views/PSurvey/Condo/sec1_part2/publishInformation.js";
import OwnerInfo from "views/PSurvey/Condo/sec1_part3/ownerInformation.js";
import ImageInfo from "views/PSurvey/Condo/sec1_part4/imageInformation.js";

// redux
import { connect } from "react-redux";
import surveyInfo from "actions/survey/surveyInfo.js";

import pressSave from "actions/survey/condo/toggleSave.js";
import condo_sec1_part1Object from "actions/survey/condo/condo_sec1_part1Data.js";
import condoSec1_Object from "actions/survey/condo/condo_sec1Obj.js";
import surveyModal from "actions/survey/surveyModal.js";
import setProjectid from "actions/survey/setProjectId.js";
import condo_initial from "actions/survey/condo/condo_initial.js";
import previous_projectid from "actions/survey/previous_porjid.js";
import setLatlon from "actions/survey/setLatlon.js";
import oldproject_data from "actions/survey/oldproject_data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import googlemapSave from "actions/survey/googleImage.js";
import imageuploading from "actions/uploading_image.js";
// import show_label from "actions/show_label.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";

class CondoStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,
      popperOpen: false,
      popperLoading: false,
      renderOldProject: [],
      projectid: "",
      btLoading: false,
      user_id: 0,
      token: "",
      current_proj: "",
      old_projectid: "",
      old_projectidState: "",
      condo_oldprojid: "",
      exist_projectid: "",
      projectId_state: "",

      ref_code: "",
      ref_code_state: "",
      refcode_subid: "",

      projectStatus: "",
      open: false,
      projectid_ex: "",
      view_psurvey: false,
    };
    this.handleSurveyType = this.handleSurveyType.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  static propTypes = {
    surveyCondition: PropTypes.object.isRequired,
    surveyInfo: PropTypes.object.isRequired,
    condo_sec1_part1Object: PropTypes.object.isRequired,
    surveyModal: PropTypes.object.isRequired,
    project_id: PropTypes.string.isRequired,
    housesec1part1object: PropTypes.object.isRequired,
    housesec1part1locobject: PropTypes.object.isRequired,
    housesec1part2object: PropTypes.object.isRequired,
    condosec1part3object: PropTypes.object.isRequired,
    condosec1part4object: PropTypes.object.isRequired,
    condoSec1_Object: PropTypes.object.isRequired,
    householdSec1Object: PropTypes.object.isRequired,
    googlemap_64: PropTypes.string.isRequired,
  };

  componentDidMount() {
    // this.captureData();
    this.getSecretKey();

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      // console.log("condition found here");
      this.props.project_datatable(undefined);
    } else {
      // console.log("test", this.props.proj_dataTable);
      this.get_projectData(this.props.proj_dataTable);
    }
  }

  componentDidUpdate(previousProps) {
    // console.log(this.props.condo_initial_data.condo_projectid, this.props);
    // console.log("componentDidUpdatesec1")
    if (
      JSON.stringify(previousProps.project_id) !==
      JSON.stringify(this.props.project_id)
    ) {
      this.setState({
        // eslint-disable-next-line react/prop-types
        projectid: "",
        projectId_state: "",
      });
      if (
        this.state.ref_code_state !== "success" &&
        this.state.ref_code !== "N_A" &&
        this.props.project_id
      ) {
        this.setState({
          ref_code:
            this.props.project_id.substring(0, 11) + this.state.refcode_subid,
        });
        // inject ref_code
        // console.log("inject refcode");
        // console.log(this.state.ref_code);
        this.props.condo_sec1_part1Object({
          ...this.props.condosec1part1object,
          refcode:
            this.props.project_id.substring(0, 11) + this.state.refcode_subid,
        });
        // if (this.state.refcode_subid !== "") {
        //   this.verifyRefCode(
        //     this.props.project_id.substring(0, 11) + this.state.refcode_subid
        //   );
        // }
      }
    }
  }
  // show_label = (value) => {
  //   this.props.show_label({
  //     refcode: value.condo_refcode,
  //     project_nameth: value.condo_nameth,
  //     project_nameen: value.condo_nameeng,
  //     project_status: value.condo_projectstatus,
  //   });
  //   // console.log(this.props.show_label)
  // };
  async checkFullBodyNoImage() {
    const part1_state = this.sec1Part1.validateState("submit"); //done
    const part1loc_state = this.sec1Part1Loc.validateState("submit"); //done
    const part2_state = this.sec1Part2.validateState("submit");
    const part3_state = this.sec1Part3.validateState("submit");
    const part4_state = this.sec1Part4.validateState("submit");
    // console.log({
    //   part1_state,
    //   part1loc_state,
    //   part2_state,
    //   part3_state,
    //   part4_state,
    // });
    if (
      this.check_projectIdExist(this.props.project_id) &&
      this.state.projectId_state !== "error" &&
      (this.state.ref_code_state === "success" ||
        this.state.ref_code === "N_A") &&
      part1_state &&
      part1loc_state &&
      part2_state &&
      part3_state &&
      part4_state
    ) {
      // console.log("true");
      const body = await this.call_merge_sec1();
      this.props.condoSec1_Object(body);
      return true;
    } else {
      // console.log("false");
      return false;
    }
  }

  get_projectData = (value) => {
    // console.log(value);
    const status = value.status;

    const edit_data = value.data[0];

    let lat = 0;
    let lng = 0;
    if (!value.data[0].lat || !value.data[0].lon) {
      lat = 0;
      lng = 0;
      // console.log("catch lat lon");
      alert("แบบสอบถามนี้ ค่าพิกัดเสียหาย");
    } else {
      try {
        lat = value.data[0].lat.toFixed(6);
        lng = value.data[0].lon.toFixed(6);
      } catch {
        // console.log("catch lat lon");
        alert("แบบสอบถามนี้ ค่าพิกัดเสียหาย");
      }
    }
    let popLocation = {
      lat: lat,
      lng: lng,
    };
    this.props.setLatlon(popLocation);
    this.props.googlemapSave({
      condo_googlemapimg: edit_data.condo_googlemapimg,
    });
    if (
      "condo_oldprojid" in edit_data &&
      edit_data.condo_oldprojid &&
      edit_data.condo_oldprojid !== "" &&
      edit_data.condo_oldprojid !== "N_A"
    ) {
      this.props.surveyInfo("old");
      this.setState({
        old_projectid: edit_data.condo_oldprojid,
        old_projectidState: "success",
      });
    }
    if ("rj_detail" in edit_data) {
      this.setState({ rj_detail: edit_data.rj_detail });
    }
    // console.log("status")
    // console.log(status)
    this.setState({
      projectStatus: status,
      view_psurvey: value.view_psurvey,
      ref_code:
        edit_data.condo_refcode && edit_data.condo_refcode !== "undefined"
          ? edit_data.condo_refcode
          : "N_A",
    });
    if (
      "condo_refcode" in edit_data &&
      edit_data.condo_refcode !== null &&
      edit_data.condo_refcode !== "undefined"
    ) {
      this.setState({
        ref_code_state: "success",
      });
    }
  };

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            user_id: jsonDec.member_id,
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  sendState() {
    return this.state;
  }

  handleSurveyType(event) {
    this.props.surveyInfo(event.target.value);
  }

  verifyText(value) {
    var textRex = /^[A-Za-zก-๙ 0-9]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyDigit(value) {
    var textRex = /^[0-9 \- C V H Q]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  async verifyRefCode(value) {
    if (value.length >= 10 && value.indexOf(" ") === -1) {
      this.setState({ ref_code_des: " กำลังตรวจสอบ" });
      var body = {
        form: "condo",
        refcode: value,
      };
      // console.log(body)
      this.setState({ popperLoading: true });
      var request = new Request(config.API_checkrefcode, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });

      return await fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.code === 200) {
            this.setState({ ref_code_state: "success" });
            this.setState({ ref_code_des: " นี้สามารถใช้ได้" });
          } else {
            this.setState({ ref_code_des: " นี้มีในระบบแล้ว" });
            this.setState({ ref_code_state: "error" });
          }
        })
        .catch((error) => {
          this.setState({ ref_code_des: " ตรวจสอบล้มเหลว" });
          this.setState({ ref_code_state: "error" });
        });
    } else {
      this.setState({ ref_code_des: " ตรวจสอบล้มเหลว" });
      this.setState({ ref_code_state: "error" });
    }
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "text":
        if (this.verifyText(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "digit":
        if (this.verifyDigit(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "ref_code":
        if (
          this.verifyDigit(event.target.value) &&
          event.target.value.length >= stateNameEqualTo
        ) {
          this.verifyRefCode(event.target.value);
        } else {
          this.setState({ [stateName + "_state"]: "error" });
          this.setState({ ref_code_des: null });
        }
        break;
      case "float":
        if (this.isFloat(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  checkProperties(obj) {
    const hasValue = Object.values(obj).includes("");
    return hasValue;
  }

  async checkValue() {
    await this.captureData();

    if (this.props.householdSec1Object === undefined) {
      return true;
    } else {
      const hasValue = Object.values(this.props.householdSec1Object).includes(
        ""
      );
      return hasValue;
    }
  }

  async isValidated() {
    // console.log("isValidated");
    const body = await this.call_merge_sec1();
    // console.log(body);
    var opentype = "save";
    if (opentype === "add") {
      try {
        // console.log(body)
        let this_exhouseimg = JSON.parse(body.condo_exhouseimg[0]);
        // console.log(this_exhouseimg)
        if (
          body.condo_currentconstructimg[0] === "" ||
          body.condo_projectplanimg[0] === "" ||
          body.condo_saleofficeimg[0] === "" ||
          body.condo_filesurvey === "N_A" ||
          body.condo_filebrochure === "N_A" ||
          body.condo_filequotation === "N_A" ||
          this_exhouseimg.house.length === 0 ||
          this_exhouseimg.twinhouse.length === 0 ||
          this_exhouseimg.townhouse.length === 0 ||
          this_exhouseimg.commercialbuilding.length === 0
        ) {
          throw new Error();
        } else {
          return true;
        }
      } catch {
        this.surveyModal("error");
        this.check_projectIdExist(body.condo_projectid);
        this.props.imageuploading(false);
        return false;
      }
    }

    // console.log("isValidating");
    this.props.imageuploading(true);
    // console.log("is this correct?");
    const state = await this.check_errorState("submit");
    // console.log(state);
    const isEdit_table = this.props.proj_dataTable;
    // console.log(isEdit_table);
    if (isEdit_table === undefined) {
      switch (state) {
        case true:
          if (
            body.condo_address === "N_A" ||
            body.condo_dateconstruct === "01/01/1900" ||
            body.condo_datefinish === "01/01/1900" ||
            body.condo_datefirstsell === "01/01/1900" ||
            body.condo_explorers === "N_A" ||
            !body.condo_explorers ||
            body.condo_forelder === "N_A" ||
            body.condo_mixeduse === "N_A" ||
            body.condo_frontprojectplaceimg[0] === "" ||
            body.condo_maxprice === 0 ||
            body.condo_minprice === 0 ||
            body.condo_moo === "N_A" ||
            body.condo_nameeng === "N_A" ||
            body.condo_nameth === "N_A" ||
            body.condo_postalcode === "N_A" ||
            body.condo_projectid === undefined
          ) {
            this.surveyModal("error");
            this.check_projectIdExist(body.condo_projectid);
            this.props.imageuploading(false);
            return false;
          } else {
            // console.log(body);
            this.props.condoSec1_Object(body);
            this.props.imageuploading(false);
            return true;
          }
        default:
          this.surveyModal("error");
          this.check_projectIdExist(body.condo_projectid);
          this.props.imageuploading(false);
          return false;
      }
    } else {
      switch (isEdit_table.status) {
        case 1:
        case 3:
          this.props.imageuploading(false);
          return true;
        default:
          // console.log("last cond check");
          // console.log(isEdit_table.status);
          if (this.state.view_psurvey === false) {
            if (
              body.condo_address === "N_A" ||
              body.condo_dateconstruct === "01/01/1900" ||
              body.condo_datefinish === "01/01/1900" ||
              body.condo_datefirstsell === "01/01/1900" ||
              body.condo_forelder === "N_A" ||
              body.condo_mixeduse === "N_A" ||
              body.condo_frontprojectplaceimg[0] === "" ||
              body.condo_maxprice === 0 ||
              body.condo_minprice === 0 ||
              body.condo_moo === "N_A" ||
              body.condo_nameeng === "N_A" ||
              body.condo_nameth === "N_A" ||
              body.condo_postalcode === "N_A" ||
              body.condo_projectid === undefined
            ) {
              // console.log("last check unpass");
              this.surveyModal("error");
              this.check_projectIdExist(body.condo_projectid);
              this.props.imageuploading(false);
              return false;
            } else {
              // console.log(body);
              this.props.condoSec1_Object(body);
              this.props.imageuploading(false);
              return true;
            }
          } else {
            return true;
          }
      }
    }
  }
  check_projectIdExist(projectID) {
    if (this.state.ref_code.length < 14) {
      this.setState({
        ref_code_state: "error",
      });
    }
    if (projectID === undefined) {
      this.setState({
        projectId_state: "error",
      });
      return false;
    } else {
      this.setState({
        projectId_state: "success",
      });
      return true;
    }
  }

  async check_errorState(state) {
    return true;
  }

  merge_state(state1, state2, state3, state4) {
    // console.log("ส่วนที่ 4: " + state4)
    if (state1 === true && state2 === true && state3 === true) {
      return true;
    } else {
      return false;
    }
  }

  setDate = (date) => {
    try {
      const valueOfInput = date.format("DD/MM/YYYY");
      this.setState({
        salesDate: valueOfInput,
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  surveyModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "บันทึกสำเร็จ"
            : validity === "refcode"
            ? "รหัส refcode มีปัญหา"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอก รายละเอียดทั่วไปของโครงการ ให้ครบถ้วนก่อนทำการบันทึก (ข้อมูลทั้งหมด ยกเว้นรูปส่วนล่างสุด)"
          : "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอกข้อมูล ส่วนที่ 1 ทั้งหมดให้ครบถ้วนก่อนไปส่วนถัดไป"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 2500);
  }
  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "บันทึกสำเร็จ"
            : validity === "refcode"
            ? "รหัส refcode มีปัญหา"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }
  alertConnection() {
    this.props.surveyModal(
      <SweetAlert
        type="warning"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title="การเชื่อมต่อมีปัญหา ไม่สามารถเชื่อมต่อข้อมูลได้"
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 3000);
  }

  async savebutton() {
    // console.log("savebutton");
    this.setState({
      btLoading: true,
    });

    if (this.check_projectIdExist(this.props.project_id)) {
      // console.log("save ได้ค่า ครบ");
      const body = await this.call_merge_sec1();
      this.check_projectIdExist(body.condo_projectid);
      // console.log('save ได้ค่า ครบ');
      return this.setState(
        {
          btLoading: false,
        },
        () => this.submit_save(body)
      );
    } else {
      // console.log("save ไม่ได้ค่า ไม่ครบ");
      return this.setState(
        {
          btLoading: false,
        },
        () => this.surveyModal("save")
      );
    }
  }

  async call_merge_sec1() {
    let usrID = { condo_userid: this.state.user_id };
    let oldID = { condo_oldprojid: this.state.old_projectid };
    let exID = { ex_id: this.state.old_projectid };
    // console.log();
    this.sec1Part1.validateRegister();
    this.sec1Part2.validateRegister();
    this.sec1Part1Loc.validateRegister();
    this.sec1Part3.validateRegister();
    this.sec1Part4.validateRegister();

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.merge_data(
            this.props.condosec1part1object,
            this.props.condosec1part2object,
            this.props.condosec1part1locobject,
            this.props.googlemap_64,
            {
              condo_projectid: this.props.project_id,
              condo_refcode: this.state.ref_code,
            },
            usrID,
            oldID,
            exID,
            this.props.condosec1part3object,
            this.props.condosec1part4object
          )
        );
      }, 1000);
    });
  }

  captureData() {
    this.sec1Part1.validateRegister();
    this.sec1Part2.validateRegister();
    this.sec1Part1Loc.validateRegister();
    this.sec1Part3.validateRegister();
    this.sec1Part4.validateRegister();

    let body = this.props.project_id;

    if (body !== undefined) {
      if (this.props.pre_proj === undefined) {
        // generated project id
      } else {
        body = {
          current_id: this.props.pre_proj,
          form: "condo",
          pro_id: this.props.project_id.pro_id,
          zone_id: this.props.project_id.zone_id,
        };
      }
      var request = new Request(config.API_genprojid, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });
      // console.log(body)
      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.code === 200) {
            this.setState({
              // eslint-disable-next-line react/prop-types
              projectid: responseJson.data,
            });
            this.props.setProjectid(responseJson.data);
            this.sec1Part1Loc.mapComponent.getImagePath(responseJson.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          // console.log(error);
        });
    } else {
      // this.surveyModal("save");
      // this.setState({
      //   btLoading: false
      // });
    }
  }

  merge_data(obj1, obj2, obj3, obj4, obj5, obj6, obj7, obj8, obj9, obj10) {
    const body = {
      ...obj1,
      ...obj2,
      ...obj3,
      ...obj4,
      ...obj5,
      ...obj6,
      ...obj7,
      ...obj8,
      ...obj9,
      ...obj10,
    };
    return body;
  }

  handleClose() {
    this.setState({ popperOpen: false });
  }
  // array2obj = (array) => {
  //   if (array === undefined || array === null || array.length === 0) {
  //     return null;
  //   }
  //   var maxKey = 0;
  //   var indxmaxKey = 0;

  //   for (let i = 0; i < array.length; i++) {
  //     if (Object.keys(array[i]).length > maxKey) {
  //       indxmaxKey = i;
  //       maxKey = Object.keys(array[i]).length;
  //     }
  //   }

  //   var objOfArray = {};

  //   Object.keys(array[indxmaxKey]).forEach((eKey) => {
  //     objOfArray[eKey] = array.map(function (obj) {
  //       return obj[eKey];
  //     });
  //   });

  //   return objOfArray;
  // };
  submit_save = (body) => {
    // console.log("A", this.props);
    // console.log("B", this.state.survey_constructedDate);
    // console.log("C", this.props.condoSec2Object);
    // // console.log("D", this.props.condosec1part1object);
    // console.log("E", this.props.condosec1part2object);
    // // console.log("F", this.props.condosec1part1locobject);
    // console.log("EE", this.props.condosec1part2object.condo_dateconstruct);
    // console.log("FF", this.props.condosec1part2object.condo_datefinish);
    this.setState({
      btLoading: false,
    });
    // if (
    //   this.props.condoSec2Object &&
    //   this.props.condoSec2Object instanceof Object &&
    //   Object.values(this.props.condoSec2Object).length > 1 &&
    //   Object.values(this.props.condoSec2Object)[1].length > 0
    // ) {
    //   console.log("C", this.props.condoSec2Object);
    //   console.log("EE", this.props.condosec1part2object.condo_dateconstruct);
    //   console.log("FF", this.props.condosec1part2object.condo_datefinish);
    // }
    // console.log(this.props.condoSec2Object.bs_notbuild);
    const token = this.state.token;
    // console.log(this.props.condo_initial_data);
    const target = Object.assign({}, this.props.condo_initial_data);
    const source = body;
    // console.log(this.props.condoSec2Object)
    // console.log(this.props.condosec3object)
    // console.log(this.props.condosec4object)
    // console.log(source);
    // console.log(target)
    // console.log(this.array2obj(this.props.BuildingAndUnit_sec2))
    // console.log(this.props.proj_dataTable)
    const log_condo_sec_1 = { event: "Insert or update condo section 1" };
    const mergebody = Object.assign(
      target,
      source,
      this.props.condoSec2Object &&
        this.props.condoSec2Object instanceof Object &&
        Object.values(this.props.condoSec2Object).length > 1 &&
        Object.values(this.props.condoSec2Object)[1].length > 0
        ? this.props.condoSec2Object
        : {},
      this.props.condosec3object &&
        this.props.condosec3object instanceof Object &&
        Object.values(this.props.condosec3object).length > 0 &&
        Object.values(this.props.condosec3object)[0].length > 0
        ? this.props.condosec3object
        : {},
      this.props.condosec4object &&
        this.props.condosec4object instanceof Object &&
        Object.values(this.props.condosec4object).length > 0 &&
        Object.values(this.props.condosec4object)[0].length > 0
        ? this.props.condosec4object
        : {},
      log_condo_sec_1
    );

    // console.log("save with this obj")
    // console.log(mergebody);
    // if (this.props.proj_dataTable) {
    //   // console.log("check change");
    //   // console.log(mergebody);
    //   // console.log(this.props.proj_dataTable);
    //   var checkUnchanged = Object.keys(
    //     this.props.proj_dataTable.data[0]
    //   ).filter((field) => {
    //     return this.props.proj_dataTable.data[0][field] !== mergebody[field];
    //   });
    //   checkUnchanged = checkUnchanged.filter((field) => {
    //     if (field === "condo_userid" || field === "condo_googlemapimg") {
    //       return false;
    //     }
    //     if (
    //       field === "c_fax" &&
    //       (mergebody[field] === "-" ||
    //         mergebody[field] === "" ||
    //         !mergebody[field])
    //     ) {
    //       return false;
    //     }
    //     if (
    //       field === "condo_district" ||
    //       field === "condo_subdistrict" ||
    //       field === "condo_province" ||
    //       field === "condo_presaleamoutbuilding"
    //     ) {
    //       return false;
    //     }
    //     if (
    //       field === "c_district" ||
    //       field === "c_subdistrict" ||
    //       field === "c_province"
    //     ) {
    //       return false;
    //     }
    //     if (field === "arrayChk") {
    //       return false;
    //     }
    //     if (field.indexOf("price") > -1) {
    //       return (
    //         parseInt(this.props.proj_dataTable.data[0][field]) !==
    //         parseInt(mergebody[field])
    //       );
    //     }
    //     if (field.indexOf("img") > -1 || field.indexOf("file") > -1) {
    //       return (
    //         JSON.stringify(this.props.proj_dataTable.data[0][field]) !==
    //         JSON.stringify(mergebody[field]) &&
    //         "[" +
    //         JSON.stringify(this.props.proj_dataTable.data[0][field]) +
    //         "]" !==
    //         JSON.stringify(mergebody[field]) &&
    //         !(
    //           this.props.proj_dataTable.data[0][field] === "" &&
    //           mergebody[field] === "N_A"
    //         )
    //       );
    //     }
    //     if (field === "condo_typearea") {
    //       return !(
    //         this.props.proj_dataTable.data[0][field] === "" &&
    //         mergebody[field] === "N_A"
    //       );
    //     }
    //     if (field === "t_value" || field === "d_value" || field === "p_value") {
    //       return false;
    //     }

    //     if (field.indexOf("date") > -1) {
    //       return (
    //         this.ddmm2mmdd(this.props.proj_dataTable.data[0][field]) !==
    //         this.ddmm2mmdd(mergebody[field])
    //       );
    //     }
    //     return true;
    //   });
    //   // console.log(checkUnchanged);

    //   if (checkUnchanged.length === 0) {
    //     return this.unsaveModal();
    //   }
    // }
    var request = new Request(config.API_save_condo, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
      body: JSON.stringify(mergebody),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        this.setState({
          btLoading: false,
        });
        switch (responseJson.code) {
          case 200:
            // this.show_label(body)
            // console.log(this.props.show_Label)
            this.alertModal("success");
            break;
          case 409:
            // this.show_label(body)
            // console.log(this.props.show_Label)
            this.alertModal("refcode");
            break;
          default:
            this.alertModal("warning");
            break;
        }
      })
      .catch((error) => {
        this.alertConnection();
        this.setState({
          btLoading: false,
        });
      });
  };

  // unsaveModal() {
  //   this.props.surveyModal(
  //     <SweetAlert
  //       type="warning"
  //       style={{
  //         display: "block",
  //         color: "black",
  //         fontSize: "18px",
  //       }}
  //       onConfirm={() => null}
  //       onCancel={() => this.props.surveyModal(null)}
  //       customClass="modalContent"
  //       title={"ไม่บันทึกเอกสาร"}
  //       showCancel={false}
  //       showConfirm={false}
  //       closeOnClickOutside={true}
  //     >
  //       {"เนื่องจากไม่พบการแก้ไข"}
  //     </SweetAlert>
  //   );
  //   setTimeout(() => {
  //     this.props.surveyModal(null);
  //   }, 2500);
  // }
  // ddmm2mmdd = (ddmm) => {
  //   if (ddmm.length > 10) {
  //     return (
  //       ddmm.substring(8, 10) +
  //       "/" +
  //       ddmm.substring(5, 7) +
  //       "/" +
  //       ddmm.substring(0, 4)
  //     );
  //   } else {
  //     if (ddmm.length > 5) {
  //       return (
  //         ddmm.substring(3, 5) + "/" + ddmm.substring(0, 2) + ddmm.substring(5)
  //       );
  //     } else {
  //       return ddmm;
  //     }
  //   }
  // };
  get_oldproj(event) {
    let value = event.target.value;
    this.setState({ old_projectid: value, old_projectidState: "error" });

    var body = {
      form: "condo",
      nameth: value,
    };

    if (value.length >= 3) {
      // console.log(this.state.token)
      // console.log(body)
      this.setState({ popperLoading: true });
      var request = new Request(config.API_search_oldproject, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          let data = responseJson.data.filter(
            (proj) => proj.condo_projectid && proj.condo_projectid.length > 4
          );
          if (data.length >= 1) {
            this.setState({
              popperOpen: true,
              renderOldProject: data,
              popperLoading: false,
            });
          } else {
            this.setState({
              popperOpen: false,
              popperLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            popperOpen: false,
            renderOldProject: [],
            old_projectidState: "error",
            popperLoading: false,
          });
        });
    } else {
      this.setState({
        popperOpen: false,
        renderOldProject: [],
        old_projectidState: "error",
        popperLoading: false,
      });
    }
  }
  // end of auto complete org field

  loadingPro(isLoading) {
    if (isLoading) {
      return <PulseLoader size={8} color={"gray"} loading={true} />;
    } else {
      return null;
    }
  }
  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };
  handleSelect(value) {
    this.setState({
      popperOpen: false,
      old_projectidState: "success",
      old_projectid: value.condo_projectid,
      refcode_subid: value.condo_refcode.split("-")[3],
    });
    // console.log(this.props.project_id);
    let body = {
      condo_projectid: value.condo_projectid,
      condo_approved: 3,
    };
    // console.log(body);
    var request = new Request(config.API_showdetail_projectid_condo, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        const data = responseJson.data;
        // console.log('data')
        switch (responseJson.code) {
          case 200:
            if (data.length === 0) {
              // alert("No data in this project id");
              this.setState({
                popperOpen: false,
                old_projectidState: "error",
                old_projectid: "",
                open: true,
              });
            } else {
              if (data[0].lat === 0 || data[0].lon === 0) {
                this.setState({
                  popperOpen: false,
                  old_projectidState: "error",
                  old_projectid: "",
                  open: true,
                });
              } else {
                let period;
                let year;
                if (value.condo_projectid.substring(2, 4) === "H1") {
                  period = "H2";
                  year = value.condo_projectid.substring(0, 2);
                } else if (value.condo_projectid.substring(2, 4) === "H2") {
                  period = "H1";
                  year = (
                    parseInt(value.condo_projectid.substring(0, 2)) + 1
                  ).toString();
                } else if (value.condo_projectid.substring(2, 4) === "Q1") {
                  period = "Q2";
                  year = value.condo_projectid.substring(0, 2);
                } else if (value.condo_projectid.substring(2, 4) === "Q2") {
                  period = "Q3";
                  year = value.condo_projectid.substring(0, 2);
                } else if (value.condo_projectid.substring(2, 4) === "Q3") {
                  period = "Q4";
                  year = value.condo_projectid.substring(0, 2);
                } else if (value.condo_projectid.substring(2, 4) === "Q4") {
                  period = "Q1";
                  year = (
                    parseInt(value.condo_projectid.substring(0, 2)) + 1
                  ).toString();
                }
                // console.log(value.condo_projectid);
                this.setState({
                  old_projectid: value.condo_projectid,
                  ref_code:
                    year +
                    period +
                    value.condo_projectid.substring(4, 11) +
                    value.condo_refcode.split("-")[3],
                });
                this.verifyRefCode(this.state.ref_code);
                this.props.oldproject_data({
                  data: [data[0]],
                  unity: responseJson.unity ? responseJson.unity : [],
                });
                // console.log(data);
                if (
                  data[0] instanceof Object &&
                  "lat" in data[0] &&
                  "lon" in data[0]
                ) {
                  let popLocation = {
                    lat: parseFloat(data[0].lat.toFixed(6)),
                    lng: parseFloat(data[0].lon.toFixed(6)),
                  };
                  this.props.setLatlon(popLocation);
                }
              }
            }
            break;
          case 400:
            this.setState({
              popperOpen: false,
              old_projectidState: "error",
              old_projectid: "",
              open: true,
            });
            break;
          default:
        }
      })
      .catch((error) => {
        // error
      });
  }
  successAlert(title, message, status) {
    this.props.surveyModal(
      <SweetAlert
        {...status}
        className="SweetAlert"
        title={<div>{title}</div>}
        customClass="modalContent"
        onConfirm={() => this.props.surveyModal(null)}
        confirmBtnCssClass={"modalBt"}
      >
        {message.length > 200 ? (
          <div style={{ overflowY: "scroll", height: "100px" }}>
            <br />
            {message}
          </div>
        ) : (
          message
        )}
      </SweetAlert>
    );
  }
  isResubmiting = () => {
    // console.log(this.state.projectStatus)
    return this.state.projectStatus === 2 || this.state.projectStatus === "2";
  };

  blockEdit(isEdit) {
    if (isEdit) {
      // console.log('disable all')
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "50",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }
  render() {
    const { classes } = this.props;

    let optionItems = this.state.renderOldProject.map((com, index) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={index}
        value={com.condo_projectid}
        style={{ backgroundColor: "#fff" }}
        onClick={() => this.handleSelect(com)}
      >
        {com.condo_refcode + " " + com.condo_nameth}
      </MenuItem>
    ));

    return (
      <GridContainer style={{ padding: "15px" }}>
        {this.blockEdit(!this.isEditable())}

        <Snackbar
          place="tc"
          color="warning"
          message="ข้อมูลโครงการเก่าไม่ครบถ้วน ทำให้ไม่สามารถดึงข้อมูลโครงการเก่าได้"
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        {/* Section_1 Part 1 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดทั่วไปของโครงการ</b>
          </h4>
          <div className="top_bar" style={{ display: "flex" }}>
            <div
              className="form_radio"
              style={{ width: "100%", display: "flex" }}
            >
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  disabled={
                    this.props.project_id !== undefined &&
                    this.props.project_id !== ""
                  }
                  control={
                    <Radio
                      checked={this.props.surveyCondition === "new"}
                      onChange={this.handleSurveyType}
                      value="new"
                      name="radio button enabled"
                      aria-label="new"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="แบบสอบถามใหม่"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  disabled={
                    this.props.project_id !== undefined &&
                    this.props.project_id !== ""
                  }
                  control={
                    <Radio
                      checked={this.props.surveyCondition === "old"}
                      onChange={this.handleSurveyType}
                      value="old"
                      name="radio button enabled"
                      aria-label="old"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="แบบสอบถามเก่า"
                />
              </div>
            </div>
            <div className="ngum" style={{ width: "100%" }}>
              <FormControlLabel
                style={{ float: "right" }}
                control={
                  <CustomInput
                    success={this.state.ref_code_state === "success"}
                    error={this.state.ref_code_state === "error"}
                    labelText={"ref code." + (this.state.ref_code_des || "")}
                    id="ref_code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "เติมอย่างน้อย 11 หลัก",
                      value: this.state.ref_code,
                      onChange: (event) =>
                        this.change(event, "ref_code", "ref_code", 14),
                    }}
                  />
                }
              />
            </div>
          </div>
          <GridContainer style={{ marginBottom: "15px", marginTop: "10px" }}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormLabel
                className={classes.labelHorizontal}
                style={{
                  float: "none",
                  padding: "0px",
                  textAlign: "center",
                  fontSize: "18px",
                  color: "gray",
                }}
              >
                *คำชี้แจง
              </FormLabel>
              {this.props.proj_dataTable === undefined ? (
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    float: "none",
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  กรุณาปักหมุดบนแผนที่เพื่อทำการสร้าง เลขที่แบบสอบถาม
                </FormLabel>
              ) : (
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    float: "none",
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  ไม่สามารถแก้ไขเลขที่แบบสอบถามได้กรณีหากทำการบันทึกแล้ว
                </FormLabel>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  {this.state.rj_detail ? (
                    <Button
                      color="danger"
                      onClick={() =>
                        this.successAlert(
                          "เหตุผลที่ไม่ผ่าน",
                          this.state.rj_detail
                        )
                      }
                    >
                      {"เหตุผลที่ไม่ผ่าน"}
                    </Button>
                  ) : null}
                  <CustomInput
                    success={this.state.projectId_state === "success"}
                    error={this.state.projectId_state === "error"}
                    labelText="เลขที่แบบสอบถาม"
                    id="survey_id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      disabled: true,
                      value:
                        this.props.project_id === undefined
                          ? ""
                          : this.props.project_id,
                    }}
                  />
                </GridItem>
                {this.props.surveyCondition === "new" ? null : (
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <CustomInput
                      success={this.state.old_projectidState === "success"}
                      error={this.state.old_projectidState === "error"}
                      labelText="ค้นหาชื่อแบบสอบถามเก่า"
                      id="old_projid"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "กรอกอย่างน้อย 4 ตัวอักษร",
                        value: this.state.old_projectid,
                        onChange: (event) => this.get_oldproj(event),
                        ref: (node) => {
                          this.anchorEl = node;
                        },
                        endAdornment:
                          this.state.old_projectidState === "error" ? (
                            <InputAdornment position="end">
                              {this.state.popperLoading ? (
                                <div style={{ width: "50px" }}>
                                  {this.loadingPro(this.state.popperLoading)}
                                </div>
                              ) : null}
                              <p
                                style={{
                                  width: "105px",
                                  margin: "0",
                                  textAlign: "end",
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                ไม่พบแบบสอบถาม
                              </p>
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                    <Popper
                      open={this.state.popperOpen}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                      placement="bottom-end"
                      className={
                        "MuiPaper-root MuiMenu-paper MuiPaper-elevation8 MuiPopover-paper MuiPaper-rounded"
                      }
                      style={{
                        position: "absolute",
                        zIndex: 99999,
                        maxHeight: "200px",
                        minWidth: "38%",
                        overflow: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper className={classes.selectMenu}>
                          {optionItems}
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <LocationInfo onRef={(ref) => (this.sec1Part1Loc = ref)} />
          {/* Break */}
          <FormLabel
            className={classes.labelHorizontal}
            style={{
              float: "none",
              padding: "0px",
              paddingTop: "15px",
              textAlign: "center",
            }}
          >
            ข้อมูลทั่วไป: ระบุข้อมูลต่างๆของโครงการ
          </FormLabel>
          <BasicInfo onRef={(ref) => (this.sec1Part1 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>

        {/* Section_1 Part 2 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดการขายของโครงการ</b>
          </h4>
          <PublishInfo onRef={(ref) => (this.sec1Part2 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
        {/* Section_1 Part 3 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดผู้ประกอบการ</b>
          </h4>
          <OwnerInfo onRef={(ref) => (this.sec1Part3 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
        {/* Section_1 Part 4 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ImageInfo onRef={(ref) => (this.sec1Part4 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
      </GridContainer>
    );
  }
}

CondoStep1.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  condo_sec1_part1Object: (payload) =>
    dispatch(condo_sec1_part1Object(payload)),
  pressSave: (payload) => dispatch(pressSave(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  condoSec1_Object: (payload) => dispatch(condoSec1_Object(payload)),
  condo_initial: (payload) => dispatch(condo_initial(payload)),
  previous_projectid: (payload) => dispatch(previous_projectid(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  oldproject_data: (payload) => dispatch(oldproject_data(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  googlemapSave: (payload) => dispatch(googlemapSave(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
  // show_label: (payload) => dispatch(show_label(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(CondoStep1));
