import React from "react";
import PropTypes from "prop-types";
// core components
import Wizard from "components/Wizard/WizardSrp.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Step1 from "views/Project/Home/edit/Section1.js";
import Step2 from "views/Project/Home/edit/Section2.js";

import SweetAlert from "react-bootstrap-sweetalert";
import CryptoJS from "crypto-js";
//redux
import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";
import tokenSession from "actions/tokenSession.js";
import setHouseSection1 from "actions/setHouseSection1.js";
import setHouseSection2 from "actions/setHouseSection2.js";

import setNoOfProjects from "actions/setNoOfProjects.js";
import setProjectIDHome from "actions/setProjectIDHome.js";
import setShowModal from "actions/setShowModal.js";
import setHomeType from "actions/setHomeType.js";
import setLatlon from "actions/setLatlon.js";
import setProv from "actions/setProvAmphoeTambon.js";
import setProjectStatus from "actions/setProjectStatus.js";

import { API_editSRP, API_gen_secretkey, API_addSRP } from "configure/configServer.js";
import createModal from "actions/createModal.js";
// switch language function
import switchIntl from "configure/switchIntl.js";
class WizardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      title: "",
    };
  }

  componentDidMount() {
    if (this.props.assetType.includes("h")) {
      this.setState({ title: "โครงการบ้านจัดสรร" });
    } else if (this.props.assetType.includes("c")) {
      this.setState({ title: "โครงการอาคารชุด" });
    } else if (this.props.assetType.includes("v")) {
      this.setState({ title: "โครงการบ้านพักตากอากาศ" });
    }

    this.getToken();
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  sendData() {
    this.hideAlert();
    let self = this;
    let data_section1 = this.props.houseInfoSection1;
    let data_section2 = this.props.houseInfoSection2;
    //console.log("all data ===");
    //console.log(data_section1);
    //console.log(data_section2);
    let all_data = Object.assign({}, data_section1, data_section2);
    //console.log(JSON.stringify(all_data));

    let URL = API_editSRP;

    if (this.props.approved === 4) {
      URL = API_addSRP;
    } else {
      URL = API_editSRP;
    }

    var request = new Request(URL, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
      body: JSON.stringify(all_data),
    });
    fetch(request)
      .then(function (response) {
        response.json().then(function (data) {
          //console.log("res..")
          //console.log(data);

          switch (data.code) {
            case 200:
              //success
              self.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => {
                      window.location.replace("/project");
                    }}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    เปลี่ยนแปลงข้อมูลสำเร็จ
                  </SweetAlert>
                ),
              });
              break;
            default:
              //failed
              self.setState({
                alert: (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    ไม่สามารถบันทึกข้อมูลได้ในขณะนี้ กรุณาตรวจสอบข้อมูลอีกครั้ง
                  </SweetAlert>
                ),
              });
              break;
          }
        });
      })
      .catch(function (err) {
        //console.log(err);
      });
  }

  clearState = () => {
    let hometype_default = {
      home: 1,
      land: 0,
      shophouse: 0,
      townhouse: 0,
      twinhouse: 0,
    };
    this.props.setHomeType(hometype_default);
    this.props.setLatlon({
      lat: 0,
      lng: 0,
    });
    this.props.setProv({
      prov_id: 0,
      amp_id: 0,
      tam_id: 0,
      prov_name: "",
      amp_name: "",
      tam_name: "",
    });
    this.props.setProjectStatus(1);
    //console.log("clear state");
  };

  confirmSubmit = () => {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "-100px" }}
          title={""}
          onConfirm={() => this.sendData()}
          onCancel={() => this.hideAlert()}
          confirmBtnText={
            switchIntl(this.props.toggleLng).basic_word.send_project
          }
          cancelBtnText={switchIntl(this.props.toggleLng).basic_word.cancel}
          showCancel
        >
          {" "}
          {switchIntl(this.props.toggleLng).project.home_new_project.msg}
        </SweetAlert>
      ),
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  render() {
    // const { classes } = this.props;
    let self = this;
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12} sm={12}>
          <Wizard
            color="info"
            validate
            steps={[
              {
                stepName: "ส่วนที่ 1",
                stepComponent: Step1,
                stepId: "about",
                stepProps: {
                  assetType: this.props.assetType,
                },
              },
              {
                stepName: "ส่วนที่ 2",
                stepComponent: Step2,
                stepId: "account",
                stepProps: {
                  assetType: this.props.assetType,
                  approved: this.props.approved,
                },
              },
            ]}
            title={this.state.title}
            subtitle=""
            previousButtonText="ย้อนกลับ"
            nextButtonText="ต่อไป"
            finishButtonText={"ส่งข้อมูลโครงการ"}
            showFinishButton={
              this.props.approved === 4 || this.props.approved === 2
                ? true
                : false
            }
            finishButtonClick={(e) => {
              let data_section1 = this.props.houseInfoSection1;
              let data_section2 = this.props.houseInfoSection2;
              if (data_section1 && data_section2) {
                self.confirmSubmit();
              } else {
                self.setState({
                  alert: (
                    <SweetAlert
                      warning
                      style={{ display: "block", marginTop: "-100px" }}
                      title={""}
                      onConfirm={() => {
                        self.hideAlert();
                      }}
                      confirmBtnText={
                        switchIntl(self.props.toggleLng).basic_word.ok
                      }
                    >
                      {" "}
                      ข้อมูลบางส่วนไม่ถูกต้อง กรุณาตรวจสอบข้อมูลก่อนส่ง
                    </SweetAlert>
                  ),
                });
              }

              //ask user want to send or save?
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

WizardView.propTypes = {
  classes: PropTypes.object,
  assetType: PropTypes.string,
};
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setHouseSection1: (payload) => dispatch(setHouseSection1(payload)),
  setHouseSection2: (payload) => dispatch(setHouseSection2(payload)),
  createModal: (payload) => dispatch(createModal(payload)),
  setNoOfProjects: (payload) => dispatch(setNoOfProjects(payload)),
  setProjectIDHome: (payload) => dispatch(setProjectIDHome(payload)),
  setShowModal: (payload) => dispatch(setShowModal(payload)),
  setHomeType: (payload) => dispatch(setHomeType(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  setProjectStatus: (payload) => dispatch(setProjectStatus(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardView);
